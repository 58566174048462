import { useState, useEffect, useContext, useCallback } from "react";
import {
  MotifTable,
  MotifTableHeaderRenderer,
  MotifTableCellRenderer,
} from "@ey-xd/motif-react";
import SkeletonTable from "../../components/layout/SkeletonTable";
import SearchForTables from "../../components/layout/SearchForTables.jsx";
import LabelsContext from "../../context/LabelsContext.js";

import { fetchLandingsData } from "../../services/api.js";

import "../../assets/css/pages/all-organization.scss";
import { useNavigate } from "react-router-dom";
import { statusCreation } from "../../globals.js";
let gridApi;

const AllLanding = () => {
  const [landingsData, setLandingsData] = useState([]);
  const [filteredLandingsData, setFilteredLandingsData] = useState([]);

  const [pageSize] = useState("10");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setLoading] = useState(true);
  const labels = useContext(LabelsContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.style.overflow = "auto";
    const fetchData = async () => {
      try {
        const data = await fetchLandingsData(true);
        setLandingsData(data);
      } catch (error) {
        console.error("Error fetching the landings data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleGridReady = (params) => {
    ({ api: gridApi } = params);
  };

  const columnDefs = [
    {
      headerName: labels.table_title,
      field: "title",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.activity_category,
      field: "categoryName",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_table_stage,
      field: "status",
      sortable: true,
      filter: true,
      flex: 1,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const resolvedData = await Promise.all(
        landingsData.map((event) => ({
          title: event.title,
          categoryName: event.categoryName,
          description: event.description,
          gallery: event.gallery,
          image: event.image,
          isDraft: event.isDraft,
          landingId: event.landingId,
          logo: event.logo,
          organizationName: event.organizationName || "",
          orgUrl: event.orgUrl,
          url: event.video?.url,
          urlLink: event.url,
          country: event.country,
          status: statusCreation(event),
        }))
      );
      //const onPageSizeChanged = (newPageSize) => {
      //setPageSize(newPageSize);
      //gridApi.paginationSetPageSize(newPageSize);
      //};
      const sortedActionsData = [...resolvedData].sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0;
      });
      const filteredLandingsData = sortedActionsData.filter(
        (landing) =>
          landing.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
          landing.categoryName
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
      );
      setFilteredLandingsData(filteredLandingsData);
    };

    fetchData();
  }, [landingsData, searchValue]);

  const onRowSelected = useCallback(
    (landing) => {
      navigate("/organizations/edit-landing/", {
        state: { landingData: landing.node.data },
      });
      return;
    },
    [navigate]
  );
  return (
    <>
      <section className="all-organizations-page motif-col-lg-12">
        <div className="organizations-info">
          <span className="title motif-h2">{labels.console_landing_title}</span>
          <div>
            <span className="number-total-organizations">
              {landingsData.length}
            </span>
            <span className="total-organizations">
              {labels.console_total_landings}
            </span>
          </div>
        </div>
        <SearchForTables
          onSearchChange={(value) => setSearchValue(value)}
          pageType="landings"
          showAddButton={true}
          showSwitchButton={false}
        />
        {isLoading ? (
          <SkeletonTable />
        ) : (
          <section className="organizations-table">
            <MotifTable
              defaultColDef={{
                headerCheckboxSelection: false,
                checkboxSelection: false,
              }}
              firstColumnBorderRight
              columnDefs={columnDefs}
              rowData={filteredLandingsData}
              onGridReady={handleGridReady}
              suppressCellFocus
              onSortChanged={() => gridApi.redrawRows()}
              components={{
                agColumnHeader: MotifTableHeaderRenderer,
                motifTableCellRenderer: MotifTableCellRenderer,
              }}
              paginationPageSize={pageSize}
              pagination
              rowSelection={"single"}
              onRowSelected={onRowSelected}
              suppressNoRowsOverlay={true}
            />
          </section>
        )}
      </section>
    </>
  );
};
export default AllLanding;
