import { useState } from "react";
import {
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifIcon,
  MotifErrorMessage,
  MotifFormField,
} from "@ey-xd/motif-react";
import { actionIcDescription24px } from "@ey-xd/motif-react/assets/icons";
/**
 * Returns a unique list of files
 *
 * @param {array} prevQueue - previus list of files
 * @param {array} newFiles - new list of files
 */
const addToQueueUnique = (prevQueue, newFiles) => {
  const ids = new Set(prevQueue.map((prevFile) => prevFile.path));
  const merged = [
    ...prevQueue,
    ...newFiles.filter((newFile) => !ids.has(newFile.path)),
  ];
  return merged;
};
/**
 * Updates the queue with the new files dropped in
 *
 * @param {array} newFiles - new files to be added to the queue
 * @param {func} setQueue - function that updates the queue
 */
const handleDrop = (newFiles, setFileQueue, setQueue3) => {
  const filteredFiles = newFiles.filter(
    (file) => file.type === "image/png" || file.type === "image/jpeg"
  );
  const updatedFiles = filteredFiles.map((file) => ({
    ...file,
    name: file.name,
    path: file.path,
    size: file.size,
    error: file.error,
    uploaded: "image",
    previewUrl: URL.createObjectURL(file),
  }));
  setFileQueue((prevQueue) => addToQueueUnique(prevQueue, updatedFiles));
  setQueue3((prevQueue) => addToQueueUnique(prevQueue, updatedFiles)); // También actualizamos queue3 para mantener la lógica existente
};

/**
 * Removes a single file from the queue
 *
 * @param {array} queue - current list of files to be uploaded
 * @param {func} setQueue - function that updates the queue
 * @param {Object} removedFile - the file to be removed from the queue
 */
const removeFile = (queue, setQueue, removedFile) => {
  const nextQueue = queue.filter((file) => file.path !== removedFile.path);
  setQueue(nextQueue);
};
/**
 * Renders each item in the queue as a MotifChip
 *
 * @param {*} queue - current list of files to be uploaded
 * @param {*} setQueue - function that updates the queue
 */
const renderQueue = (queue, setQueue) =>
  queue.map((file) => (
    <div key={file.path}>
      <MotifFileUploaderItem
        fileIcon={<MotifIcon src={actionIcDescription24px} />}
        fileName={file.name}
        fileSize={file.size}
        error={file.error && true}
        onRemove={() => removeFile(queue, setQueue, file)}
        removable
      />
      {file.error && <MotifErrorMessage>{file.error}</MotifErrorMessage>}
    </div>
  ));
const nameLengthValidator = (file) => {
  if (file.name.length > 25) {
    return {
      code: "name-too-large",
      message: `Name is larger than ${25} characters`,
    };
  } else if (file.type !== "image/png" && file.type !== "image/jpeg") {
    return {
      code: "invalid-file-type",
      message: "Invalid file type. Only PNG and JPEG files are allowed",
    };
  } else if (file.size > 2097152) {
    return {
      code: "file-too-large",
      message: `File is larger than 2MB`,
    };
  }
  return null;
};
const UploadFile = ({ setFileQueue, error, errorMessage, isDisabled }) => {
  const [queue3, setQueue3] = useState([]);
  return (
    <>
      <MotifFormField>
        <MotifFileUploader
          onDrop={(event) => handleDrop(event, setFileQueue, setQueue3)}
          validator={nameLengthValidator}
          label="Drop files here or click to upload"
          disabled={isDisabled}
          accept="image/png, image/jpeg"
          maxSize={2097152}
        >
          {renderQueue(queue3, setQueue3)}
        </MotifFileUploader>
        {error && <MotifErrorMessage>{errorMessage}</MotifErrorMessage>}
      </MotifFormField>
    </>
  );
};
export default UploadFile;
