import { useContext, useEffect, useState, useMemo } from "react";

import { MotifTable, MotifTableHeaderRenderer } from "@ey-xd/motif-react";

import SkeletonTable from "../../components/layout/SkeletonTable";
import LabelsContext from "../../context/LabelsContext";

import { getVolunteersReport } from "../../services/api/apiReports";

import "../../assets/css/pages/volunteers-reports.scss";

const VolunteersReports = () => {
  const [volunteersData, setVolunteersData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const labels = useContext(LabelsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const data = await getVolunteersReport();
        const filteredData = data.volunteerReports.filter(
          (volunteer) => volunteer.projectsSubscribed >= 1
        );
        const sortedData = filteredData.sort(
          (a, b) => b.totalHours - a.totalHours
        );

        setVolunteersData(sortedData);
      } catch (error) {
        console.error("Error fetching volunteers report data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const columnDefs = [
    {
      headerName: labels.console_users,
      field: "userName",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_users,
    },
    {
      headerName: labels.console_email,
      field: "email",
      flex: 2,
      suppressMovable: true,
      headerTooltip: labels.console_email,
    },
    {
      headerName: labels.console_service_line,
      field: "serviceLine",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_service_line,
    },
    {
      headerName: labels.console_office_location,
      field: "officeLocation",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_office_location,
    },
    {
      headerName: labels.console_job_title,
      field: "jobTitle",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_job_title,
    },
    {
      headerName: labels.console_projects_subscribed,
      field: "projectsSubscribed",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_projects_subscribed,
    },
    {
      headerName: labels.console_projects_completed,
      field: "projectsCompleted",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_projects_completed,
    },
    {
      headerName: labels.console_total_hours,
      field: "totalHours",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_total_hours,
    },
    {
      headerName: labels.console_user_sign_up_date,
      field: "signUpDate",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_user_sign_up_date,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      initialWidth: 200,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  return (
    <>
      <section className="organization-reports">
        {
          <header className="header">
            <h1 className="motif-h1">{labels.console_volunteers_reports}</h1>
          </header>
        }
        {isLoading ? (
          <SkeletonTable />
        ) : (
          <section>
            <MotifTable
              columnDefs={columnDefs}
              rowData={volunteersData}
              defaultColDef={defaultColDef}
              components={{
                agColumnHeader: MotifTableHeaderRenderer,
              }}
              suppressRowClickSelection
              suppressCellFocus
              pagination
              className="reports-table"
              paginationPageSize="20"
            />
          </section>
        )}
      </section>
    </>
  );
};

export default VolunteersReports;
