import { useContext, useState, useEffect } from "react";

import {
  MotifModal,
  MotifModalBody,
  MotifModalHeader,
  MotifIconButton,
  MotifIcon,
  MotifButton,
  MotifToast,
} from "@ey-xd/motif-react";

import TextInput from "./TextInput";
import { navigationIcClose24px } from "@ey-xd/motif-react/assets/icons";
import LabelsContext from "../../../context/LabelsContext";

import "../../../assets/css/components/UserModalInfo.scss";
import { postUserComment, fetchActions } from "../../../services/api";

const UserModalInfo = ({ show, userInfo, onClose, actions }) => {
  const labels = useContext(LabelsContext);
  const [userComments, setUserComments] = useState(userInfo.comments || "");
  const [projectTitles, setProjectTitles] = useState([]);
  const [successToastVisible, setSuccessToastVisible] = useState(false);

  const handleCommentsChange = (newComments) => {
    setUserComments(newComments);
  };
  const onClickSave = async () => {
    try {
      await postUserComment({
        userId: userInfo.userId,
        comments: userComments,
      });
      setSuccessToastVisible(true);
    } catch (error) {
      console.error("Error posting user comments: ", error);
    }
  };

  useEffect(() => {
    const getActionTitles = async () => {
      if (show && userInfo.projectsInvolved?.length > 0) {
        try {
          const allActions = await fetchActions(true);
          const titles = userInfo.projectsInvolved.map((projectId) => {
            const found = allActions.find(
              (action) => action.title === projectId
            );
            return found ? found.title : "Título no encontrado";
          });

          setProjectTitles(titles);
        } catch (error) {
          console.error("Error fetching actions: ", error);
        }
      }
    };

    getActionTitles();
  }, [userInfo, show]);
  const duration = "3000";
  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccessToastVisible(false);
    }, duration);
    return () => clearTimeout(timer);
  });
  return (
    <>
      <MotifModal show={show} onClose={onClose} className="user-modal">
        {successToastVisible && (
          <MotifToast
            variant="success"
            duration={duration}
            onClose={() => setSuccessToastVisible(false)}
            className="success-toast"
          >
            {labels.console_user_comment_saved_successfully}
          </MotifToast>
        )}
        <MotifModalHeader
          className="motif-modal-header-user"
          closeModalButton={
            <MotifIconButton
              type="button"
              onClick={onClose}
              className="motif-modal--header-icon-button close-user-modal"
              title="Click here to close modal"
            >
              <MotifIcon src={navigationIcClose24px} />
            </MotifIconButton>
          }
        >
          <img
            src={
              userInfo.profileImage ||
              "https://www.freeiconspng.com/thumbs/profile-icon-png/profile-icon-9.png"
            }
            style={{ borderRadius: "50%" }}
            className="user-image"
            alt=""
          />
          <span className="motif-h2 volunteer-title">
            {userInfo.name} {userInfo.surname}
          </span>
        </MotifModalHeader>
        <MotifModalBody>
          <div className="accordion-title">
            {labels.console_user_employee_info}
          </div>
          <div className="accordion-user-modal">
            <div className="user-info-row">
              <span className="user-field">{labels.console_email}:</span>
              <span className="user-info">{userInfo.mail || "-"}</span>
            </div>
            <div className="user-info-row">
              <span className="user-field">{labels.console_user_rank}:</span>
              <span className="user-info">{userInfo.jobTitle || "-"}</span>
            </div>
            <div className="user-info-row">
              <span className="user-field">{labels.console_user_region}:</span>
              <span className="user-info">{userInfo.country || "-"}</span>
            </div>
            <div className="user-info-row">
              <span className="user-field">{labels.console_sl}:</span>
              <span className="user-info">{userInfo.serviceLine || "-"}</span>
            </div>
            <div className="user-info-row">
              <span className="user-field">{labels.console_user_office}:</span>
              <span className="user-info">{userInfo.office || "-"}</span>
            </div>
          </div>
          <div className="accordion-title">
            {labels.console_user_personal_info}
          </div>
          <div className="accordion-user-modal">
            <div className="user-info-row">
              <span className="user-field">
                {labels.console_user_sign_up_date}:
              </span>
              <span className="user-info">{userInfo.signUpDate || "-"}</span>
            </div>
            <div className="user-info-row">
              <span className="user-field">
                {labels.console_user_projects_fy}:
              </span>
              <span className="user-info">{userInfo.projectsCurrentFY}</span>
            </div>
          </div>
          <div className="accordion-title">{labels.console_user_projects}</div>
          <div className="accordion-user-modal">
            <div className="user-info-row">
              <span className="user-field">
                {labels.console_user_projects_involved}:
              </span>
              <span className="user-info">{projectTitles.length}</span>
            </div>
            <div className="user-info-row">
              <span className="user-field">
                {labels.console_projects_ended}:
              </span>
              <span className="user-info">
                {userInfo.projectsCompleted || "-"}
              </span>
            </div>
            <div className="user-info-row">
              <span className="user-field">
                {" "}
                {labels.console_dedicated_hours}:
              </span>
              <span className="user-info">{userInfo.totalHours || "-"}</span>
            </div>
          </div>
          <div className="accordion-title">{labels.console_comments}</div>
          <TextInput
            value={userComments}
            onChange={(val) => handleCommentsChange(val)}
            disabled={false}
          />
          <MotifButton
            onClick={onClickSave}
            className="activity-buttons-primary user-modal-button"
          >
            {labels.console_save}
          </MotifButton>
        </MotifModalBody>
      </MotifModal>
    </>
  );
};

export default UserModalInfo;
