import React from 'react';

import { MotifIcon } from '@ey-xd/motif-react';
import DonutChartSL from "./DonutChartSL";


import "../../assets/css/components/MediumReportBox.scss";

const MediumBoxSL = ({ icon, title, donutHoursData, donutEconomicData, isLoading, total }) => {

  return (
    <div className="medium-box">
      <div className="top-content">
        <div className="icon-box">
          <span>
            <MotifIcon src={icon} />
          </span>
        </div>
        <div>
          <div className="title-box">
            <span>{title}</span>
          </div>
        </div>
      </div>
      <div className="middle-content-chart">
        <DonutChartSL isLoading={isLoading} donutHoursData={donutHoursData} donutEconomicData={donutEconomicData} />
      </div>
      <div className="bottom-content">
        <div className="right-side">
          <div className="legend-section">
            <div className="title-box">
              
            </div>
          </div>
        </div>
        <div className="left-side">
          <div className="legend-section">
            <div className="title-box">
              Total: {total}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediumBoxSL;