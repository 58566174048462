import { useNavigate } from "react-router-dom";
import "../assets/css/components/UserInfoCard.scss";
import { UserInfoContext } from "../context/UserInfo/UserInfoProvider";
import { useContext } from "react";

const UserInfoCard = ({
  name,
  surname,
  jobTitle,
  mail,
  image,
  serviceLine,
  office,
  country,
  userId,
  role,
}) => {
  const navigate = useNavigate();
  const { updateUserInfo } = useContext(UserInfoContext);

  const onClick = () => {
    const selectedUserInfo = {
      name,
      surname,
      jobTitle,
      mail,
      office,
      userId,
      country,
      serviceLine,
      role,
    };
    updateUserInfo(selectedUserInfo);
    navigate("/profile/new-user");
  };
  return (
    <div className="user-card" onClick={onClick}>
      <img
        src={
          image ||
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
        }
        alt={mail}
        className="user-card-image"
      />
      <span className="user-card-name">
        {name} {surname}
      </span>
      <span>{jobTitle}</span>
      <span>{mail}</span>
    </div>
  );
};

export default UserInfoCard;
