import {
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifButton,
} from "@ey-xd/motif-react";

import "../assets/css/components/Modal.scss";

const Modal = ({
  show,
  onClose,
  title,
  body,
  primaryButtonText = "Confirm",
  secondaryButtonText = "Cancel",
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  disabled,
}) => {
  const closeButtonProps = {
    title: "Close modal",
    "aria-label": "Close modal",
  };

  return (
    <>
      <MotifModal show={show} onClose={onClose} size="medium">
        <MotifModalHeader closeButtonProps={closeButtonProps}>
          {title}
        </MotifModalHeader>
        <MotifModalBody>{body}</MotifModalBody>
        <MotifModalFooter className="custom-footer">
          {secondaryButtonText && (
            <MotifButton
              size="medium"
              variant="secondary"
              type="button"
              onClick={onSecondaryButtonClick || onClose}
            >
              {secondaryButtonText}
            </MotifButton>
          )}
          <MotifButton
            size="medium"
            type="button"
            disabled={disabled}
            onClick={onPrimaryButtonClick || onClose}
          >
            {primaryButtonText}
          </MotifButton>
        </MotifModalFooter>
      </MotifModal>
    </>
  );
};

export default Modal;
