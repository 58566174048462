import SecondaryMenu from "../../components/layout/SecondaryMenu";
import SectionFields from "../../components/form/molecules/SectionFields";
import Modal from "../../components/Modal";

import { MotifButton } from "@ey-xd/motif-react";

import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../../assets/css/pages/new-activity.scss";
import { handleFileUpload } from "../../services/UploadImageBlob";
import {
  fetchCategoriesData,
  fetchCountriesData,
  fetchOrganizationsData,
  postLandingsData,
} from "../../services/api";
import {
  galleryUploadedFilter,
  imageUploadedFilter,
  nanoid,
  deleteEmptyFields,
} from "../../globals";

import LabelsContext from "../../context/LabelsContext";
import InvalidModal from "../../components/InvalidModal";

const NewLanding = () => {
  const [showModal, setShowModal] = useState(false);
  const [categoryOptions, setCategories] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [organizationsName, setOrganizations] = useState([]);
  const [showInvalidModal, setShowInvalidModal] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [actionType, setActionType] = useState("");
  const labels = useContext(LabelsContext);

  const [formData, setFormData] = useState({
    categoryName: null,
    description: "",
    organizationName: "",
    url: "",
    gallery: [],
    titleLanding: "",
    image: {},
    country: null,
    urlLink: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrganizationsData(false).then((data) => {
      const organizations = Array.isArray(data) ? data : [];
      setOrganizations(organizations);
    });
    fetchCategoriesData().then((data) => {
      const categories = Array.isArray(data.categories)
        ? data.categories.map((category) => ({
            value: category.id,
            label: category.name,
          }))
        : [];
      setCategories(categories);
    });
    fetchCountriesData().then((data) => {
      const countries = Array.isArray(data)
        ? data.map((country) => ({
            value: country.countryName,
            label: country.countryName,
          }))
        : [];
      setCountryOptions(countries);
    });
  }, []);
  //const handleFieldChange = (field, value) => {
  //setFormData((prev) => ({ ...prev, [field]: value }));
  //};
  const [fileQueue, setFileQueue] = useState([]);
  const [fileQueueGallery, setFileQueueGallery] = useState([]);

  const handleFormSubmit = async (drafted) => {
    setisFormSubmited(true);
    if (isFormValid()) {
      setIsDisabledButton(true);
      const landingId = nanoid();
      const submitData = {
        ...formData,
        landingId,
        image: imageUploadedFilter(fileQueue, landingId, "landing"),
        gallery: galleryUploadedFilter(fileQueueGallery, landingId),
        video: formData.url
          ? { url: formData.url, mimeType: "image/jpeg" }
          : null,
        url: formData.urlLink,
        isDraft: drafted,
        title: formData.titleLanding,
      };
      handleFileUpload(fileQueue, submitData.landingId, "landing");
      handleFileUpload(fileQueueGallery, submitData.landingId, "landing");
      await postLandingsData(deleteEmptyFields(submitData));
      navigate("/organizations/all-landings");
      setIsDisabledButton(false);
    } else {
      setShowInvalidModal(true);
      setIsDisabledButton(false);
    }
  };

  const isFormValid = () => {
    const requiredFields = ["image", "description", "titleLanding", "country"];
    const newMissingFields = [];
    requiredFields.forEach((field) => {
      if (!formData[field] || (field === 'description' && formData[field] === '<p><br></p>')) {
        newMissingFields.push(field);
      }
    });
    setMissingFields(newMissingFields);
    return newMissingFields.length === 0;
  };

  const [isFormSubmited, setisFormSubmited] = useState(false);
  const navigate = useNavigate();

  const openWarningModal = () => {
    setActionType("save");
    setShowModal(true);
  };

  const closeWarningModal = () => {
    setShowModal(false);
  };
  const closeInvalidModal = () => {
    setShowModal(false);
    setShowInvalidModal(false);
  };
  const handleCancelClick = () => {
    setActionType("cancel");
    setShowModal(true);
  };

  const handleDiscardChanges = () => {
    window.scrollTo(0, 0);
    setShowModal(false);
    navigate("/");
  };

  const handleNavigateHome = () => {
    navigate("/organizations/all-landings");
  };

  const formFieldConfig = {
    organizationName: { required: false },
    image: { required: true },
  };

  return (
    <>
      <SecondaryMenu
        title={labels.console_create_new_landing}
        onNavigate={handleNavigateHome}
        editing={true}
        sendFormDraft={() => handleFormSubmit(true)}
        pageType="landings"
      />
      <section className="motif-col-lg-12 section-page">
        <SectionFields
          formData={formData}
          setFormData={setFormData}
          isFormSubmited={isFormSubmited}
          setFileQueue={setFileQueue}
          setFileQueueGallery={setFileQueueGallery}
          organizationsName={organizationsName}
          categoryOptions={categoryOptions}
          countryOptions={countryOptions}
          imageLabel={labels.console_image_logo}
          descriptionLabel={labels.console_description}
          formFieldConfig={formFieldConfig}
          fieldVisibility={{
            categoryName: true,
            shortDescription: true,
            description: true,
            organizationName: true,
            titleLanding: true,
            gallery: true,
            image: true,
            url: true,
            urlLink: true,
            country: true,
          }}
        />

        <div className="activity-buttons">
          <MotifButton
            variant="secondary"
            onClick={handleCancelClick}
            className="activity-buttons-secondary"
          >
            {labels.console_cancel}
          </MotifButton>
          <MotifButton
            onClick={openWarningModal}
            className="activity-buttons-primary"
          >
            {labels.console_save}
          </MotifButton>
        </div>
      </section>
      <section className="motif-col-lg-5"></section>

      <Modal
        show={showModal}
        onClose={closeWarningModal}
        title={
          actionType === "save"
            ? labels.console_changes_saved
            : labels.console_unsavedChanges
        }
        body={
          actionType === "save"
            ? labels.console_confirm_publish_landing
            : labels.console_leavePage
        }
        primaryButtonText={
          actionType === "save"
            ? labels.console_publish
            : labels.console_keepEditing
        }
        onPrimaryButtonClick={
          actionType === "save"
            ? () => handleFormSubmit(false)
            : closeWarningModal
        }
        secondaryButtonText={
          actionType === "save"
            ? labels.console_cancel
            : labels.console_discardChanges
        }
        onSecondaryButtonClick={handleDiscardChanges}
        disabled={isDisabledButton}
      />
      <InvalidModal
        show={showInvalidModal}
        onClose={closeInvalidModal}
        title={labels.console_title_invalidForm}
        body={labels.console_body_invalidForm}
        primaryButtonText={labels.console_close}
        onPrimaryButtonClick={closeInvalidModal}
        invalidFieldsMessage={missingFields.join(", ")}
      />
    </>
  );
};

export default NewLanding;
