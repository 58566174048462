import { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  MotifTable,
  MotifTableHeaderRenderer,
  MotifTableCellRenderer,
  MotifButton,
} from "@ey-xd/motif-react";

import SearchForTables from "../../components/layout/SearchForTables.jsx";
import SkeletonTable from "../../components/layout/SkeletonTable";

import LabelsContext from "../../context/LabelsContext.js";

import { fetchActions, fetchVolunteersActionList } from "../../services/api.js";
import { organizationToId, status, getUsersJoined } from "../../globals.js";

import "../../assets/css/pages/all-activities.scss";

let gridApi;

const AllActivities = () => {
  const [actionsData, setActionsData] = useState([]);
  const [filteredActionsData, setFilteredActionsData] = useState([]);
  const [pageSize] = useState("10");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();

  const labels = useContext(LabelsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "auto";
    const fetchData = async () => {
      try {
        const data = await fetchActions(true);
        setActionsData(data);
        data.length === 0 && setLoading(false);
      } catch (error) {
        console.error("Error fetching the actions data", error);
      }
    };
    fetchData();
  }, []);

  const handleGridReady = (params) => {
    ({ api: gridApi } = params);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resolvedData = await Promise.all(
          actionsData.map(async (action) => ({
            title: action.title,
            organizationId: await organizationToId(action.organizationId),
            organizationIdCode: action.organizationId,
            startDate: action.startDate.slice(0, 10),
            categoryId: action.categoryId,
            status: status(action, action.startHour, action.endHour),
            actionId: action.actionId,
            active: action.active,
            subTitle: action.subTitle || "",
            url: action.video?.url,
            collectiveIdList: action.collectiveIdList,
            skills: action.skills,
            description: action.description,
            endDate: action.endDate,
            startHour: action.startHour,
            endHour: action.endHour,
            timeZone: action.timeZone,
            totalVolunteerHours: action.totalVolunteerHours,
            points: action.points,
            deadLineDate: action.deadLineDate,
            fiscalYear: action.fiscalYear,
            minParticipants: action.minParticipants,
            directBeneficiaries: action.directBeneficiaries,
            donationToEntity: action.donationToEntity,
            directCost: action.directCost,
            cost: action.cost,
            initiative: action.initiative,
            engagementCode: action.engagementCode || "",
            maxParticipants: action.maxParticipants,
            attendance: action.attendance,
            location: {
              coordinates: {
                latitude: action.location?.coordinates?.latitude || "",
                longitude: action.location?.coordinates?.longitude || "",
              },
              address: {
                city: action.location?.address?.city || "",
                country: action.location?.address?.country || "",
                street: action.location?.address?.street || "",
              },
              geographicArea: action.location?.geographicArea,
              region: action.location?.region,
              description: action.location?.descriptionLocation,
            },
            contact: {
              name: action.contact?.name,
              email: action.contact?.email,
            },
            comments: action.comments,
            image: action.image,
            indirectBeneficiaries: action?.indirectBeneficiaries,
            thirdParty: action.thirdParty,
            usersJoin: await getUsersJoined(action.actionId),
          }))
        );

        const sortedActionsData = resolvedData.sort((a, b) => {
          const titleA = a.title.toLowerCase();
          const titleB = b.title.toLowerCase();
          if (titleA < titleB) return -1;
          if (titleA > titleB) return 1;
          return 0;
        });

        const filteredActionsData = sortedActionsData.filter(
          (action) =>
            action.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
            action.status?.toLowerCase().includes(searchValue.toLowerCase()) ||
            action.startDate
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            action.location?.address?.city
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            action.contact?.name
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            action.categoryId?.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredActionsData(filteredActionsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        filteredActionsData.length > 0 && setLoading(false);
      }
    };

    fetchData();
  }, [actionsData, searchValue, filteredActionsData.length]);

  const onRowSelected = useCallback(
    async (event) => {
      const clickedElement = event.event.target;
      if (clickedElement.classList.contains("users-list")) {
        const users = await fetchVolunteersActionList(event.data.actionId);
        navigate("/actions/volunteers-list", {
          state: { usersData: users, actionData: event.data },
        });
      } else {
        let action = event.data;
        let startDate = action.startDate;
        delete action.startDate;
        action.startDate = startDate.concat(" 00:00:00");
        navigate("/actions/edit-action/", {
          state: { actionData: action },
        });
      }
    },
    [navigate]
  );
  const dateComparator = (date1, date2) => {
    const formatDateToISO = (dateString) => {
      const [day, month, year] = dateString.split("/");
      return `${year}-${month}-${day}T00:00:00.000Z`;
    };

    const formattedDate1 = date1 && formatDateToISO(date1);
    const formattedDate2 = date2 && formatDateToISO(date2);

    const date1Number = formattedDate1 && Date.parse(formattedDate1);
    const date2Number = formattedDate2 && Date.parse(formattedDate2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  };

  const columnDefs = [
    {
      headerName: labels.console_title,
      field: "title",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_table_organization,
      field: "organizationId",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_category,
      field: "categoryId",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_location,
      field: "location.address.city",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_joined_users,
      field: "usersJoin",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_table_stage,
      field: "status",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_start_date,
      field: "startDate",
      sortable: true,
      comparator: dateComparator,
      sortingOrder: ["asc", "desc"],
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_contactName,
      field: "contact.name",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_joined_users,
      cellRendererFramework: function (params) {
        return (
          <MotifButton
            onClick={(e) => e.stopPropagation()}
            className="activity-buttons-primary users-list"
          >
            {labels.console_users}
          </MotifButton>
        );
      },
      field: "iconButton",
    },
  ];

  return (
    <>
      <section className="all-activities-page motif-col-lg-12">
        <div className="activities-info">
          <span className="title motif-h2">{labels.console_actions}</span>
          <div>
            <span className="number-total-activities">
              {actionsData.length}
            </span>
            <span className="total-activities">
              {labels.console_total_actions}
            </span>
          </div>
        </div>
        <SearchForTables
          onSearchChange={(value) => setSearchValue(value)}
          pageType="activities"
          showAddButton={true}
          showSwitchButton={false}
        />
        {isLoading ? (
          <SkeletonTable />
        ) : (
          actionsData &&
          actionsData.length > 0 && (
            <div className="activities-table">
              <MotifTable
                defaultColDef={{
                  headerCheckboxSelection: false,
                  checkboxSelection: false,
                }}
                firstColumnBorderRight
                columnDefs={columnDefs}
                rowData={filteredActionsData}
                onGridReady={handleGridReady}
                suppressCellFocus
                onSortChanged={() => gridApi && gridApi.redrawRows()}
                components={{
                  agColumnHeader: MotifTableHeaderRenderer,
                  motifTableCellRenderer: MotifTableCellRenderer,
                }}
                paginationPageSize={pageSize}
                pagination
                rowSelection={"single"}
                onRowSelected={onRowSelected}
                onRowClicked={onRowSelected}
                suppressNoRowsOverlay={true}
              />
            </div>
          )
        )}
      </section>
    </>
  );
};
export default AllActivities;
