import { useState, useEffect, useContext } from "react";

import SmallReportBox from "../../components/reports/SmallReportBox";
import SmallReportBoxInverted from "../../components/reports/SmallReportBoxInverted";
import MediumReportBoxSL from "../../components/reports/MediumReportBoxSL";

import BarBox from "../../components/reports/BarBox";

import { MotifProgressBar, MotifIcon } from "@ey-xd/motif-react";

import {
  actionIcTrendingUp24px,
  socialIcPersonAdd24px,
  socialIcPeople24px,
  socialIcPeopleOutline24px,
  socialIcMood24px,
  actionIcHourglassEmpty24px,
  imageIcExposurePlus124px,
} from "@ey-xd/motif-react/assets/icons";

import LabelsContext from "../../context/LabelsContext";
import { getGoldUsers, getInfoBySL } from "../../services/api/apiReports";
import { fetchAllUsers } from "../../services/api.js";

import "../../assets/css/pages/general-reports.scss";

const UsersReports = () => {
  const labels = useContext(LabelsContext);
  const [allGiveyrs, setAllGiveyrs] = useState([]);
  const [goldUsersData, setGoldUsersData] = useState([]);
  const [infoBySl, setInfoBySl] = useState({});
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [percentageOfGoldGivers, setPercentageOfGoldGivers] = useState(0);

  const [percentageOfActiveUsers, setPercentageOfActiveUsers] = useState(0);

  const [verticalBarGiveyrsData, setVerticalBarGiveyrsData] = useState({
    labels: [],
    datasets: [],
  });

  const [verticalBarActiveUserData, setVerticalBarActiveUserData] = useState({
    labels: [],
    datasets: [],
  });

  const [donutHoursData, setDonutHoursData] = useState({
    labels: [],
    datasets: [],
  });

  const [donutEconomicData, setDonutEconomicData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const allGiveyrsResponse = await fetchAllUsers();
        const goldUsersDataResponse = await getGoldUsers();
        const infoBySlResponse = await getInfoBySL();

        setAllGiveyrs(allGiveyrsResponse.users || []);
        setGoldUsersData(goldUsersDataResponse || []);
        setInfoBySl(infoBySlResponse || {});

        if (allGiveyrs.length > 0 && goldUsersData.length > 0) {
          const percentageOfGoldGivers =
            (goldUsersData.length / allGiveyrs.length) * 100;
          const roundedPercentageOfGoldGivers =
            Math.round(percentageOfGoldGivers * 100) / 100;
          setPercentageOfGoldGivers(roundedPercentageOfGoldGivers);
        }

        const totalActiveUsers = Object.values(
          infoBySlResponse.activeUserCountBySL || {}
        ).reduce((sum, current) => sum + current, 0);
        const totalUsers = Object.values(
          infoBySlResponse.userCountBySL || {}
        ).reduce((sum, current) => sum + current, 0);

        const percentageOfActiveUsers = (totalActiveUsers / totalUsers) * 100;
        const roundedPercentageOfActiveUsers =
          Math.round(percentageOfActiveUsers * 100) / 100;

        const userCountData = infoBySlResponse.userCountBySL || {};
        const labels = Object.keys(userCountData);
        const data = Object.values(userCountData);

        const activeUserCountData = infoBySlResponse.activeUserCountBySL || {};
        const activeLabels = Object.keys(activeUserCountData);
        const activeData = Object.values(activeUserCountData);

        const hoursCountData = infoBySlResponse.hoursCountBySL || {};
        const hoursLabels = Object.keys(hoursCountData);
        const hoursData = Object.values(hoursCountData);
        const totalHours = hoursData.reduce((sum, current) => sum + current, 0);

        const economicValueData = infoBySlResponse.economicValueBySL || {};
        const economicValueLabels = Object.keys(economicValueData);
        const economicValues = Object.values(economicValueData);

        const verticalBarUserData = {
          labels,
          datasets: [
            {
              label: labels.console_users,
              data,
              backgroundColor: [
                "#FEE600",
                "#373744",
                "#4D96FF",
                "#E26D5A",
                "#BAF2D8",
              ],
            },
          ],
        };

        const verticalBarActiveUserData = {
          labels: activeLabels,
          datasets: [
            {
              label: labels.console_users,
              data: activeData,
              backgroundColor: [
                "#FEE600",
                "#373744",
                "#4D96FF",
                "#E26D5A",
                "#BAF2D8",
              ],
            },
          ],
        };

        const donutHoursData = {
          labels: hoursLabels,
          datasets: [
            {
              label: labels.console_hours_sl,
              data: hoursData,
              backgroundColor: [
                "#FEE600",
                "#373744",
                "#4D96FF",
                "#E26D5A",
                "#BAF2D8",
              ],
            },
          ],
        };

        const donutEconomicData = {
          labels: economicValueLabels,
          datasets: [
            {
              label: labels.console_economic_value_sl,
              data: economicValues,
              backgroundColor: [
                "#FEE600",
                "#373744",
                "#4D96FF",
                "#E26D5A",
                "#BAF2D8",
              ],
            },
          ],
        };

        setPercentageOfActiveUsers(roundedPercentageOfActiveUsers);
        setTotalUsers(totalUsers);
        setTotalHours(totalHours);
        setDonutHoursData(donutHoursData);
        setDonutEconomicData(donutEconomicData);
        setVerticalBarActiveUserData(verticalBarActiveUserData);
        setTotalActiveUsers(totalActiveUsers);
        setVerticalBarGiveyrsData(verticalBarUserData);
      } catch (error) {
        console.error("Error fetching the events data", error);
      }
    };
    fetchData();
  }, [allGiveyrs.length, goldUsersData.length]);

  console.log("all users", allGiveyrs);
  console.log("infoSL", infoBySl);

  console.log(goldUsersData, "goldUsersData");
  console.log(percentageOfGoldGivers, "percentageOfGoldGivers");
  console.log(donutEconomicData, "donutEconomicData");
  console.log(donutHoursData, "donutHoursData");

  return (
    <>
      <section className="general-reports">
        <header className="header">
          <h1 className="motif-h1">{labels.console_reports_sl}</h1>
        </header>
        <section className="grid-small-cards">
          <SmallReportBox
            icon={actionIcTrendingUp24px}
            title={labels.console_giveyrs_enrolled}
            content={
              allGiveyrs.length && allGiveyrs.length ? (
                allGiveyrs.length.toLocaleString()
              ) : (
                <MotifProgressBar isIndeterminate circle hideLabel />
              )
            }
          />
          <SmallReportBox
            icon={socialIcMood24px}
            title={labels.console_percentage_giveyrs}
            content={
              `${percentageOfActiveUsers}%` ? (
                `${percentageOfActiveUsers.toLocaleString()}%`
              ) : (
                <MotifProgressBar isIndeterminate circle hideLabel />
              )
            }
          />
          <SmallReportBox
            icon={socialIcMood24px}
            title={labels.console_active_users}
            content={
              totalActiveUsers ? (
                totalActiveUsers.toLocaleString()
              ) : (
                <MotifProgressBar isIndeterminate circle hideLabel />
              )
            }
          />
        </section>
        <section className="grid-medium-cards">
          {totalUsers > 0 ? (
            <BarBox
              icon={socialIcPeople24px}
              title={labels.console_giveyrs_by_sl}
              verticalBarGiveyrsData={verticalBarGiveyrsData}
              total={totalUsers}
            />
          ) : (
            <div className="medium-box">
              <div className="top-content">
                <div className="icon-box">
                  <span>
                    <MotifIcon src={socialIcPeople24px} />
                  </span>
                </div>
                <div>
                  <div className="title-box">
                    <span>{labels.console_giveyrs_by_sl}</span>
                  </div>
                </div>
              </div>
              <div className="middle-content-chart">
                {labels.console_no_data}
              </div>
            </div>
          )}

          {totalActiveUsers > 0 ? (
            <BarBox
              icon={socialIcPeopleOutline24px}
              title={labels.console_active_by_sl}
              verticalBarActiveUserData={verticalBarActiveUserData}
              total={totalActiveUsers}
            />
          ) : (
            <div className="medium-box">
              <div className="top-content">
                <div className="icon-box">
                  <span>
                    <MotifIcon src={socialIcPeopleOutline24px} />
                  </span>
                </div>
                <div>
                  <div className="title-box">
                    <span>{labels.console_active_by_sl}</span>
                  </div>
                </div>
              </div>
              <div className="middle-content-chart">
                {labels.console_no_data}
              </div>
            </div>
          )}
        </section>
        <section className="grid-small-cards">
          <SmallReportBoxInverted
            icon={socialIcPersonAdd24px}
            title={labels.console_total_gold}
            content={
              goldUsersData.length !== null && goldUsersData !== undefined ? (
                goldUsersData.length.toLocaleString()
              ) : (
                <MotifProgressBar isIndeterminate circle hideLabel />
              )
            }
          />
          <SmallReportBoxInverted
            icon={imageIcExposurePlus124px}
            title={labels.console_gold_givers_enrolled}
            content={
              `${percentageOfGoldGivers}%` ? (
                `${percentageOfGoldGivers.toLocaleString()}%`
              ) : (
                <MotifProgressBar isIndeterminate circle hideLabel />
              )
            }
          />
          <SmallReportBoxInverted
            icon={actionIcHourglassEmpty24px}
            title={labels.console_dedicated_hours}
            content={
              totalHours !== null && totalHours !== undefined ? (
                totalHours.toLocaleString()
              ) : (
                <MotifProgressBar isIndeterminate circle hideLabel />
              )
            }
          />
        </section>
        <section className="flex-container">
          <section className="grid-medium-cards">
            {totalHours > 0 ? (
              <MediumReportBoxSL
                icon={socialIcPeople24px}
                title={labels.console_dedicated_hours}
                donutHoursData={donutHoursData}
                total={totalHours}
              />
            ) : (
              <div className="medium-box">
                <div className="top-content">
                  <div className="icon-box">
                    <span>
                      <MotifIcon src={socialIcPeople24px} />
                    </span>
                  </div>
                  <div>
                    <div className="title-box">
                      <span>{labels.console_dedicated_hours}</span>
                    </div>
                  </div>
                </div>
                <div className="middle-content-chart">
                  {labels.console_no_data}
                </div>
              </div>
            )}
          </section>
        </section>
      </section>
    </>
  );
};

export default UsersReports;
