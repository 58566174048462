import { useState, useEffect, useContext } from "react";
import { Carousel } from "react-responsive-carousel";

import LastActivity from "./LastActivityCard";
import { fetchActions } from "../services/api";

import LabelsContext from "../context/LabelsContext";

import "../assets/css/components/LastActivities.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { formattedDate, organizationToId, status } from "../globals";
import SkeletonActivity from "./SkeletonActivity";

const LastActivities = () => {
  const labels = useContext(LabelsContext);
  const [eventsData, setEventsData] = useState(null);
  const [processedChunkedData, setProcessedChunkedData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchActions(false);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const filteredData = data.filter((action) => {
          const actionEndDate = formattedDate(action.endDate);
          return action.active && actionEndDate >= today;
        });
        setEventsData(filteredData);
      } catch (error) {
        console.error("Error fetching the events data", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (eventsData) {
      const formatDateForComparison = (dateString) => {
        const [date, time] = dateString.split(" ");
        const [day, month, year] = date.split("/");
        return new Date(`${year}-${month}-${day} ${time}`);
      };

      const sortArrayByStartDate = (array) => {
        return array.sort((a, b) => {
          const dateA = formatDateForComparison(a.startDate);
          const dateB = formatDateForComparison(b.startDate);
          return dateA - dateB;
        });
      };
      const sortedEventsData = sortArrayByStartDate(eventsData);
      const chunks = [];
      for (let i = 0; i < sortedEventsData.length; i += 3) {
        chunks.push(sortedEventsData.slice(i, i + 3));
      }
      const fetchDataAction = async () => {
        const processedData = await Promise.all(
          chunks.map(async (chunk) => {
            return Promise.all(
              chunk.map(async (event) => ({
                title: event.title,
                organizationId: await organizationToId(event.organizationId),
                organizationIdCode: event.organizationId,
                startDate: event.startDate.slice(0, 10),
                categoryId: event.categoryId,
                status: status(event),
                actionId: event.actionId,
                active: event.active,
                subTitle: event.subTitle,
                url: event.video?.url,
                collectiveIdList: event.collectiveIdList,
                skills: event.skills,
                description: event.description,
                endDate: event.endDate,
                startHour: event.startHour,
                endHour: event.endHour,
                timeZone: event.timeZone,
                totalVolunteerHours: event.totalVolunteerHours,
                points: event.points,
                deadLineDate: event.deadLineDate,
                fiscalYear: event.fiscalYear,
                minParticipants: event.minParticipants,
                directBeneficiaries: event.directBeneficiaries,
                donationToEntity: event.donationToEntity,
                directCost: event.directCost,
                cost: event.cost,
                initiative: event.initiative,
                engagementCode: event.engagementCode,
                maxParticipants: event.maxParticipants,
                attendance: event.attendance,
                comments: event.comments,
                image: event.image,
                indirectBeneficiaries: event.indirectBeneficiaries,
                location: {
                  coordinates: {
                    latitude: event.location?.coordinates?.latitude || "",
                    longitude: event.location?.coordinates?.longitude || "",
                  },
                  address: {
                    city: event.location?.address?.city || "",
                    country: event.location?.address?.country || "",
                    street: event.location?.address?.street || "",
                  },
                  geographicArea: event.location?.geographicArea,
                  region: event.location?.region,
                  description: event.location?.descriptionLocation,
                },
                contact: {
                  name: event.contact?.name,
                  email: event.contact?.email,
                }, 
                thirdParty: event.thirdParty
              }))
            );
          })
        );
        setProcessedChunkedData(processedData);
      };
      fetchDataAction();
    }
  }, [eventsData]);
  if (!eventsData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="last-activities">
      <h2 className=" motif-h2">
        {eventsData.length ? labels.console_last_actions : ""}
      </h2>
      <div className="last-activities-carrousel">
        <Carousel showStatus={false} infiniteLoop showIndicators={false}>
          {processedChunkedData.length
            ? processedChunkedData.map((chunk, index) => (
                <div key={index} className="carrousel-page">
                  {chunk.map((event) => (
                    <LastActivity key={event.eventId} event={event} />
                  ))}
                </div>
              ))
            : eventsData.map((event, index) => (
                <div key={index} className="carrousel-page">
                  <SkeletonActivity key={index} />
                  <SkeletonActivity key={index + 1} />
                  <SkeletonActivity key={index + 2} />
                </div>
              ))}
        </Carousel>
      </div>
    </div>
  );
};

export default LastActivities;
