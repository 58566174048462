import "../assets/css/components/LastActivity.scss";

import { MotifIcon, MotifButton } from "@ey-xd/motif-react";
import { actionIcPermContactCalendar24px } from "@ey-xd/motif-react/assets/icons";
import { communicationIcLocationOn24px } from "@ey-xd/motif-react/assets/icons";
import { hardwareIcLaptop24px } from "@ey-xd/motif-react/assets/icons";
import { actionIcWatchLater24px } from "@ey-xd/motif-react/assets/icons";

import LabelsContext from "../context/LabelsContext";

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { formattedDate } from "../globals";

const LastActivity = ({ event }) => {
  const labels = useContext(LabelsContext);
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    const eventStartDate = event.startDate.concat(" 00:00:00");
    event.startDate = eventStartDate;
    navigate("/actions/edit-action/", {
      state: { actionData: event },
    });
  };

  if (!labels) {
    return null;
  }
  return (
    <div className="last-activity">
      <div className="top">
        <img
          src={
            event.image && event.image.url
              ? event.image.url
              : "https://www.abi.org.uk/globalassets/images/content/subjects/voluntary-organisations/voluntaryorganisations620x300.jpg"
          }
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              "https://www.abi.org.uk/globalassets/images/content/subjects/voluntary-organisations/voluntaryorganisations620x300.jpg";
          }}
          className="activity-image-card"
          // src="https://www.abi.org.uk/globalassets/images/content/subjects/voluntary-organisations/voluntaryorganisations620x300.jpg?width=1600&height=1100&mode=max"
        />
      </div>
      <div className="content">
        <div className="title motif-h3">{event.title}</div>
        <div className="activity-info">
          <ul>
            <li className="event">
              <MotifIcon className="icon" src={actionIcWatchLater24px} />
              <p className="">{labels.console_totalVolunteerHours}:</p>
              <p>{event.totalVolunteerHours}h</p>
            </li>
            <li className="date">
              <MotifIcon
                className="icon"
                src={actionIcPermContactCalendar24px}
              />
              <p>
                {formattedDate(
                  event.startDate.concat(" 00:00:00")
                ).toLocaleDateString()}
              </p>
            </li>
            <li className="location">
              <MotifIcon className="icon" src={communicationIcLocationOn24px} />
              <p>{event.location.address.city}</p>
            </li>
            <li className="roles">
              <MotifIcon className="icon" src={hardwareIcLaptop24px} />
              <p>{labels.console_virtual_roles}</p>
            </li>
          </ul>
        </div>
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: event.description }}
        ></p>
        <MotifButton
          size="medium"
          type="button"
          className="details-button"
          onClick={handleDetailsClick}
        >
          {labels.console_home_details}
        </MotifButton>
      </div>
    </div>
  );
};

export default LastActivity;
