import "./assets/css/index.scss";
import React from "react";
import ReactDOM from "react-dom";

import { App } from "./App";

import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const tenantId = process.env.REACT_APP_TENANT_ID;

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_LOGIN_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${tenantId}`,
  },
};
const pca = new PublicClientApplication(configuration);
await pca.initialize();
await pca.handleRedirectPromise()

ReactDOM.render(
  <BrowserRouter>
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
