import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import NumericInput from "./NumericInput";
import { useContext } from "react";
import LabelsContext from "../../../context/LabelsContext";

const Location = ({
  formData,
  setFormData,
  isFormSubmited,
  isDisabled,
  countryOptions,
  cityOptions,
  onCountryChange,
  formFieldConfig,
  fieldVisibility = {
    geographicArea: true,
    region: true,
    country: true,
    city: true,
    street: true,
    latitude: true,
    longitude: true,
    donationLocation: false,
    description: true,
    locationDescription: true,
  },
}) => {
  // Inside the Location component
  const handleChange = (fieldName, value) => {
    if (fieldName === "country") {
      // Find the label corresponding to the selected country code
      const selectedCountry = countryOptions.find(
        (option) => option.value === value
      );
      if (selectedCountry || value === "") {
        const countryName = selectedCountry?.label || ""; // Retrieve the country name
        setFormData((prevData) => ({
          ...prevData,
          country: countryName, // Update formData.country with the country name
          city: null,
        }));

        onCountryChange(selectedCountry?.key); // Notify the parent component about the country change
      }
    } else {
      // Handle other field changes
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };

  // Handle input change
  const handleInputChange = (fieldName, newValue) => {
    if (fieldName === "latitude") {
      if (
        newValue === "" ||
        (!isNaN(newValue) && newValue >= -90 && newValue <= 90)
      ) {
        setFormData((prevData) => ({
          ...prevData,
          [fieldName]: newValue,
        }));
      }
    } else if (fieldName === "longitude") {
      if (
        newValue === "" ||
        (!isNaN(newValue) && newValue >= -180 && newValue <= 180)
      ) {
        setFormData((prevData) => ({
          ...prevData,
          [fieldName]: newValue,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: newValue,
      }));
    }
  };
  const geographicAreaOptions = [
    { value: "EMEIA", label: "EMEIA" },
    { value: "ASIA_PACIFIC", label: "Asia Pacific" },
    { value: "AMERICAS", label: "Americas" },
  ];
  const labels = useContext(LabelsContext);
  const regionOptions = [
    { value: "AFRICA", label: "Africa" },
    { value: "CESA", label: "CESA" },
    { value: "EUWEST", label: "EU West" },
    { value: "INDIA", label: "India" },
    { value: "LUXEMBOURG", label: "Luxembourg" },
    { value: "MENA", label: "MENA" },
    { value: "NORDICS", label: "Nordics" },
    { value: "UK_IRELAND", label: "UK & Ireland" },
  ];

  return (
    <>
      {fieldVisibility.geographicArea && (
        <SelectInput
          label={labels.console_geo_area}
          value={formData.geographicArea}
          onChange={(val) => handleChange("geographicArea", val)}
          options={geographicAreaOptions}
          required={true}
          error={isFormSubmited && !formData.geographicArea}
          errorMessage="Oops, please select a geographic area!"
          disabled={isDisabled}
          country={false}
          filter
        />
      )}

      {fieldVisibility.region && (
        <SelectInput
          label={labels.console_user_region}
          value={formData.region}
          onChange={(val) => handleChange("region", val)}
          options={regionOptions}
          required={true}
          error={isFormSubmited && !formData.region}
          errorMessage="Oops, please select a region!"
          disabled={isDisabled}
          country={false}
          filter
        />
      )}

      {fieldVisibility.country && (
        <SelectInput
          label={labels.console_country}
          value={formData.country}
          onChange={(val) => {
            handleChange("country", val);
          }}
          options={countryOptions}
          required={formFieldConfig.country.required}
          error={
            formFieldConfig.country.required &&
            isFormSubmited &&
            !formData.country
          }
          errorMessage="Oops, please select a country!"
          disabled={isDisabled}
          country={true}
          filter
        />
      )}

      {fieldVisibility.city && (
        <SelectInput
          label={labels.console_city}
          value={formData.city}
          onChange={(val) => handleChange("city", val)}
          options={cityOptions}
          required={formFieldConfig.city.required}
          error={
            formFieldConfig.city.required && isFormSubmited && !formData.city
          }
          errorMessage="Oops, please select a city!"
          disabled={isDisabled}
          country={true}
          filter
        />
      )}

      {fieldVisibility.street && (
        <TextInput
          label={labels.console_street}
          placeholder="Enter street"
          value={formData.street}
          onChange={(val) => handleChange("street", val)}
          required={formFieldConfig.street.required}
          error={
            formFieldConfig.street.required &&
            isFormSubmited &&
            !formData.street
          }
          errorMessage="Oops, please enter an street!"
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.donationLocation && (
        <TextInput
          label={labels.console_donation_location_location}
          placeholder="Enter donation location"
          value={formData.donationLocation}
          onChange={(val) => handleChange("donationLocation", val)}
          required={false}
          error={isFormSubmited && !formData.donationLocation}
          errorMessage="Oops, please enter the location!"
        />
      )}

      {fieldVisibility.latitude && (
        <NumericInput
          label={labels.console_latitude}
          placeholder="Enter latitude between (-90 to 90)"
          value={formData.latitude}
          required={formFieldConfig.latitude.required}
          onChange={(val) => handleInputChange("latitude", val)}
          error={
            formFieldConfig.latitude.required &&
            !formData.latitude &&
            isFormSubmited
          }
          errorMessage="Oops, latitude is required!"
          isDisabled={isDisabled}
          validateNonNegative={false}
        />
      )}

      {fieldVisibility.longitude && (
        <NumericInput
          label={labels.console_longitude}
          placeholder="Enter longitude between (-180 to 180)"
          value={formData.longitude}
          required={formFieldConfig.longitude.required}
          onChange={(val) => handleInputChange("longitude", val)}
          error={
            formFieldConfig.longitude.required &&
            !formData.longitude &&
            isFormSubmited
          }
          errorMessage="Oops, longitude is required!"
          isDisabled={isDisabled}
          validateNonNegative={false}
        />
      )}

      {fieldVisibility.locationDescription && (
        <TextInput
          label={labels.console_location_description}
          value={formData.locationDescription}
          onChange={(val) => handleChange("locationDescription", val)}
        />
      )}

      {fieldVisibility.shortDescription && (
        <TextInput
          label={labels.console_shortDescription}
          value={formData.shortDescription}
          onChange={(val) => handleChange("shortDescription", val)}
          error={!formData.shortDescription && isFormSubmited}
          errorMessage="Oops, description is required!"
          required={false}
        />
      )}
    </>
  );
};

export default Location;
