import React from 'react';
import { MotifIcon } from '@ey-xd/motif-react';


import "../../assets/css/components/SmallReportBox.scss";


const SmallReportBox = ({ icon, title, content, footer }) => {
  return (
    <div className="small-box">
      <div className="top-content">
        <div className="icon-box">
          <span>
            <MotifIcon src={icon} />
          </span>
        </div>
        <div className="title-box">
          <span className='title-wrap'>
            {title}
          </span>
        </div>
      </div>
      <div className="middle-content total">
        {content}
      </div>
    </div>
  );
};

export default SmallReportBox;

