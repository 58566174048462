import SecondaryMenu from "../../components/layout/SecondaryMenu";
import Modal from "../../components/Modal";

import { MotifButton } from "@ey-xd/motif-react";

import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "../../assets/css/pages/new-activity.scss";
import { fetchCountriesData, postQuestion } from "../../services/api";

import LabelsContext from "../../context/LabelsContext";
import Questions from "../../components/form/molecules/QuestionFields";
import InvalidModal from "../../components/InvalidModal";

const Question = () => {
  const [showModal, setShowModal] = useState(false);
  const [showInvalidModal, setShowInvalidModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);

  const labels = useContext(LabelsContext);
  const location = useLocation();
  const questionData = location.state?.questionData || null;
  const [formData, setFormData] = useState({
    answers: questionData.answersData,
    icon: questionData.icon,
    questionText: questionData.questionText,
    questionType: questionData.type,
    id: questionData.id,
    country: questionData.country,
  });

  //const handleFieldChange = (field, value) => {
  //setFormData((prev) => ({ ...prev, [field]: value }));
  //};

  const handleFormSubmit = async () => {
    setisFormSubmited(true);
    if (isFormValid()) {
      setIsDisabledButton(true);
      const id = formData.id;
      const submitData = {
        ...formData,
        id,
      };
      await postQuestion(submitData);
      navigate("/sustainability/all-questions");
      setIsDisabledButton(false);
    } else {
      setShowInvalidModal(true);
      setIsDisabledButton(false);
    }
  };

  const isFormValid = () => {
    return Object.values(formData).every((field) => Boolean(field));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCountriesData().then((data) => {
      const countries = Array.isArray(data)
        ? data.map((country) => ({
            value: country.countryName,
            label: country.countryName,
            key: country.countryKey,
          }))
        : [];
      setCountryOptions(countries);
    });
  }, []);
  const [isFormSubmited, setisFormSubmited] = useState(false);
  const navigate = useNavigate();

  const openWarningModal = () => {
    setShowModal(true);
  };

  const closeWarningModal = () => {
    setShowModal(false);
  };
  const closeInvalidModal = () => {
    setShowInvalidModal(false);
  };
  const handleDiscardChanges = () => {
    document.body.style.overflow = "auto";
    setShowModal(false);
    navigate("/");
  };

  const handleNavigateHome = () => {
    navigate("/sustainability/all-questions");
  };
  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
    setIsEditing(!isEditing);
  };
  return (
    <>
      <SecondaryMenu
        title={labels.console_create_questions}
        onNavigate={handleNavigateHome}
        editing={isEditing}
        onEditClick={handleEditClick}
        pageType="questions"
      />
      <section className="motif-col-lg-12 section-page">
        <Questions
          formData={formData}
          setFormData={setFormData}
          isFormSubmited={isFormSubmited}
          labels={labels}
          countryOptions={countryOptions}
          fieldVisibility={{
            questionText: true,
            answers: true,
            icon: true,
            country: true,
          }}
          isDisabled={isDisabled}
        />
        <div className="activity-buttons">
          <MotifButton
            variant="secondary"
            onClick={openWarningModal}
            className="activity-buttons-secondary"
          >
            {labels.console_cancel}
          </MotifButton>
          <MotifButton
            onClick={handleFormSubmit}
            className="activity-buttons-primary"
            disabled={isDisabled}
          >
            {labels.console_save}
          </MotifButton>
        </div>
      </section>
      <section className="motif-col-lg-5"></section>

      <Modal
        show={showModal}
        onClose={closeWarningModal}
        title={labels.console_unsavedChanges}
        body={labels.console_leavePage}
        primaryButtonText={labels.console_keepEditing}
        onPrimaryButtonClick={closeWarningModal}
        secondaryButtonText={labels.console_discardChanges}
        onSecondaryButtonClick={handleDiscardChanges}
        isDisabled={isDisabledButton}
      />
      <InvalidModal
        show={showInvalidModal}
        onClose={closeInvalidModal}
        title={labels.console_title_invalidForm}
        body={labels.console_body_invalidForm}
        primaryButtonText={labels.console_close}
        onPrimaryButtonClick={closeInvalidModal}
      />
    </>
  );
};

export default Question;
