import {
  MotifFormField,
  MotifLabel,
  MotifSelect,
  MotifOption,
  MotifErrorMessage
} from "@ey-xd/motif-react";

import "../../../assets/css/components/MultipleSelect.scss";

const MultipleSelect = ({
  label,
  value,
  required = false,
  onChange,
  options,
  error = false,
  errorMessage,
  disabled
}) => {
  
  return (
    <>
      {label && (
        <label className="input-label">
          {label} {required && <span className="input-label-required">*</span>}
        </label>
      )}
      <MotifFormField error={error}>
        <MotifLabel>{value?.join(", ")}</MotifLabel>
        <MotifSelect
          value={value}
          onChange={(val) => onChange(val)}
          multiple
          className="multiple-select"
          disabled={disabled}
        >
          {options.map((option) => (
            <MotifOption key={option.value} value={option.value} className="dropdown">
              {option.label}
            </MotifOption>
          ))}
        </MotifSelect>
        {error && <MotifErrorMessage>{errorMessage}</MotifErrorMessage>}
      </MotifFormField>
    </>
  );
};

export default MultipleSelect;