import "../../../assets/css/components/QuestionFields.scss";

import { MotifButton, MotifCheckbox } from "@ey-xd/motif-react";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";

const Questions = ({
  formData,
  setFormData,
  labels,
  fieldVisibility = {
    questionText: true,
    answers: true,
    icon: true,
  },
  isDisabled,
  countryOptions,
}) => {
  const handleChange = (fieldName, value, index) => {
    if (fieldName === "answers") {
      const newAnswers = [...formData.answers];
      newAnswers[index] = {
        ...newAnswers[index],
        answerText: value,
      };

      setFormData({
        ...formData,
        answers: newAnswers,
      });
    } else {
      setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
    }
  };
  const handleCheckboxChange = (index) => {
    const newAnswers = [...formData.answers];
    newAnswers[index] = {
      ...newAnswers[index],
      correctAnswer: !newAnswers[index].correctAnswer,
    };
    setFormData({
      ...formData,
      answers: newAnswers,
    });
  };
  const addAnswer = () => {
    setFormData({
      ...formData,
      answers: [
        ...formData.answers,
        {
          answerText: "",
          correctAnswer: false,
        },
      ],
    });
  };
  const icons = [
    { value: "icon_quiz", label: "Generic Quizz" },
    { value: "icon_carbonfootprint", label: "Generic Sustainability" },
    { value: "icon_recycling", label: "Recycling bin" },
    { value: "icon_tuna", label: "Can" },
    { value: "icon_headphones", label: "Electronic component" },
    { value: "icon_lighter", label: "Lighter" },
    { value: "icon_medicin", label: "Medicine" },
    { value: "icon_bottle", label: "Plastic bottle" },
    { value: "icon_laptop", label: "Laptop" },
  ];
  return (
    <>
      {fieldVisibility.questionText && (
        <TextInput
          label={labels.console_question}
          value={formData.questionText}
          onChange={(val) => handleChange("questionText", val)}
          disabled={isDisabled}
        />
      )}
      {fieldVisibility.icon && (
        <SelectInput
          label={labels.console_icon}
          value={formData.icon}
          onChange={(val) => handleChange("icon", val)}
          options={icons}
          disabled={isDisabled}
        />
      )}
      {fieldVisibility.country && (
        <SelectInput
          label={labels.console_country}
          value={formData.country}
          onChange={(val) => handleChange("country", val)}
          options={countryOptions}
          required
          disabled={isDisabled}
          country={true}
        />
      )}
      {fieldVisibility.answers &&
        formData.answers.map((answer, index) => (
          <div key={index} className="answers">
            <TextInput
              label={`${labels.console_answer} ${index + 1}`}
              value={answer.answerText}
              onChange={(val) => handleChange("answers", val, index)}
              correctAnswer={answer.correctAnswer}
              disabled={isDisabled}
            />
            <MotifCheckbox
              id={`checkbox-${index}`}
              value={`checkbox-${index}`}
              name={`checkbox-${index}`}
              checked={answer.correctAnswer}
              onChange={() => handleCheckboxChange(index)}
              disabled={isDisabled}
            >
              {labels.console_correct_answer}
            </MotifCheckbox>
          </div>
        ))}
      <MotifButton
        type="button"
        variant="secondary"
        className="add-answer-button"
        onClick={addAnswer}
        disabled={isDisabled}
      >
        {labels.console_add_answer}
      </MotifButton>
    </>
  );
};

export default Questions;
