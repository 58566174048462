import { useState } from "react";
import UserInfoContext from "./UserInfoContext";

const UserInfoProvider = ({ children }) => {
  const defaultUserInfo = {
    name: "",
    surname: "",
    jobTitle: "",
    sl: null,
    region: null,
    office: "",
    email: "",
    interest: null,
    periocidad: null,
    format: null,
    hoursDedicated: "",
    impactLives: "",
    giveyrsPoints: "",
  };
  const [userInfo, setUserInfo] = useState(defaultUserInfo);

  const updateUserInfo = (newUserInfo) => {
    setUserInfo(newUserInfo);
  };

  return (
    <UserInfoContext.Provider value={{ userInfo, updateUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
};

export { UserInfoContext, UserInfoProvider };
