import "../../assets/css/components/secondary-menu.scss";

import { useContext, useState } from "react";
import { MotifIcon } from "@ey-xd/motif-react";
import { Link } from "react-router-dom";
import Modal from "../Modal";

import { navigationIcChevronLeft24px } from "@ey-xd/motif-react/assets/icons";
import LabelsContext from "../../context/LabelsContext";

const SecondaryMenuUsers = ({ title, onNavigate = () => {} }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const openModal = (config) => {
    setModalContent(config);
    setShowModal(true);
  };
  const closeModal = () => {
    document.body.style.overflow = "auto";
    setShowModal(false);
  };
  const labels = useContext(LabelsContext);
  return (
    <>
      <header className="new-activity-header motif-col-lg-12">
        <div className="left-side">
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault();
              openModal({
                title: labels.console_unsavedChanges,
                body: labels.console_leavePage,
                primaryButtonText: labels.console_keepEditing,
                secondaryButtonText: labels.console_discardChanges,
                primaryButtonVariant: "primary",
                secondaryButtonVariant: "secondary",
                onPrimaryButtonClick: () => {
                  closeModal();
                },
                onSecondaryButtonClick: () => {
                  closeModal();
                  onNavigate("/");
                },
              });
            }}
          >
            <div className="backIcon">
              <MotifIcon src={navigationIcChevronLeft24px} />
            </div>
            <p className="backText">{labels.console_back}</p>
          </Link>
          <span className="motif-h1 title-page">{title}</span>
        </div>

        <Modal show={showModal} onClose={closeModal} {...modalContent} />
      </header>
    </>
  );
};

export default SecondaryMenuUsers;
