import React from 'react';

import { MotifIcon } from '@ey-xd/motif-react';
import VerticalBar from "./VerticalBar";

import "../../assets/css/components/BarBox.scss";


const BarBox = ({ icon, title, verticalBarActiveUserData, verticalBarGiveyrsData, total }) => {

  return (
    <div className="bar-box medium-box">
      <div className="top-content">
        <div className="icon-box">
          <span>
            <MotifIcon src={icon} />
          </span>
        </div>
        <div>
          <div className="title-box">
            <span>{title}</span>
          </div>
        </div>
      </div>
      <div className="middle-content-chart">
        <VerticalBar verticalBarActiveUserData={verticalBarActiveUserData} verticalBarGiveyrsData={verticalBarGiveyrsData} />
      </div>
      <div className="bottom-content">
        <div className='left-side'>

        </div>
        <div className="right-side">
          <div className="legend-section">
            <p>Total: <span className='total'>{total}</span></p> 
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarBox;