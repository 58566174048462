import { useState, useEffect, useContext, useCallback } from "react";
import {
  MotifTable,
  MotifTableHeaderRenderer,
  MotifTableCellRenderer,
} from "@ey-xd/motif-react";
import SearchForTables from "../../components/layout/SearchForTables.jsx";
import SkeletonTable from "../../components/layout/SkeletonTable";
import LabelsContext from "../../context/LabelsContext.js";

import { fetchDonationsData } from "../../services/api.js";

import "../../assets/css/pages/all-donations.scss";
import { useNavigate } from "react-router-dom";
import { organizationToId, status } from "../../globals.js";
let gridApi;

const AllDonations = () => {
  const [eventsData, setEventsData] = useState([]);
  const [filteredActionsData, setFilteredActionsData] = useState([]);
  const [pageSize] = useState("10");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setLoading] = useState(true);

  const labels = useContext(LabelsContext);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "auto";
    const fetchData = async () => {
      try {
        const data = await fetchDonationsData(true);
        setEventsData(data);
        data.length === 0 && setLoading(false);
      } catch (error) {
        console.error("Error fetching the events data", error);
      }
    };
    fetchData();
  }, []);

  const handleGridReady = (params) => {
    ({ api: gridApi } = params);
  };

  const columnDefs = [
    { headerName: labels.title, field: "title", sortable: true, filter: true },
    {
      headerName: labels.console_table_organization,
      field: "organizationId",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_donation_type,
      field: "donationType",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Attendance",
      field: "attendance",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_table_stage,
      field: "status",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Creation date",
      field: "startDate",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_donation_contact_name,
      field: "contact.name",
      sortable: true,
      filter: true,
      flex: 1,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resolvedData = await Promise.all(
          eventsData.map(async (event) => ({
            title: event.title,
            organizationId: await organizationToId(event.organizationId),
            organizationIdCode: event.organizationId,
            attendance: event.attendance,
            donationType: event.donationType,
            location: {
              coordinates: {
                latitude: event.location?.coordinates?.latitude || "",
                longitude: event.location?.coordinates?.longitude || "",
              },
              address: {
                city: event.location?.address?.city || "",
                country: event.location?.address?.country || "",
                street: event.location?.address?.street || "",
              },
              description: event.location?.description,
            },
            contact: {
              name: event.contact?.name,
              email: event.contact?.email,
            },
            startDate: event.startDate.slice(0, 10),
            endDate: event.endDate,
            description: event.description,
            aimedAmount: event.aimedAmount,
            comments: event.comments,
            cost: event.cost,
            deadLineDate: event.deadLineDate,
            donationId: event.donationId,
            fiscalYear: event.fiscalYear,
            image: event.image,
            isDraft: event.isDraft,
            minimumAmount: event.minimumAmount,
            totalAmountToBeDonated: event.totalAmountToBeDonated,
            minimumAmountToBeRecolected: event.minimumAmountToBeRecolected,
            expensesAssociatedWithEvent: event.expensesAssociatedWithEvent,
            subTitle: event.subTitle || "",
            url: event.video?.url,
            countryValid: event.countryValid,
            status: status(event),
            active: event.active,
          }))
        );
        //const onPageSizeChanged = (newPageSize) => {
        //setPageSize(newPageSize);
        //gridApi.paginationSetPageSize(newPageSize);
        //};
        const sortedActionsData = [...resolvedData].sort((a, b) => {
          const titleA = a.title.toLowerCase();
          const titleB = b.title.toLowerCase();
          if (titleA < titleB) return -1;
          if (titleA > titleB) return 1;
          return 0;
        });
        const filteredEventsData = sortedActionsData.filter(
          (event) =>
            event.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
            event.organizationId
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            event.attendance
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            event.donationType
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            event.contact?.name
              ?.toLowerCase()
              .includes(searchValue.toLowerCase())
        );
        // Update state with filtered data
        setFilteredActionsData(filteredEventsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        filteredActionsData.length > 0 && setLoading(false);
      }
    };

    fetchData();
  }, [eventsData, searchValue, filteredActionsData.length]);

  const onRowSelected = useCallback(
    (donation) => {
      let donationRowData = donation.node.data;
      let startDate = donationRowData.startDate;
      delete donationRowData.startDate;
      donationRowData.startDate = startDate.concat(" 00:00:00");
      navigate("/donations/edit-donation/", {
        state: { donationData: donationRowData },
      });
      return;
    },
    [navigate]
  );
  return (
    <>
      <section className="all-donations-page motif-col-lg-12">
        <div className="donations-info">
          <span className="title motif-h2">{labels.console_donations}</span>
          <div>
            <span className="number-total-donations">{eventsData.length}</span>
            <span className="total-donations">
              {labels.console_total_donations}
            </span>
          </div>
        </div>
        <SearchForTables
          onSearchChange={(value) => setSearchValue(value)}
          pageType="donations"
          showAddButton={true}
          showSwitchButton={false}
        />
        {isLoading ? (
          <SkeletonTable />
        ) : (
          <section className="donations-table">
            <MotifTable
              defaultColDef={{
                headerCheckboxSelection: false,
                checkboxSelection: false,
              }}
              firstColumnBorderRight
              columnDefs={columnDefs}
              rowData={filteredActionsData}
              onGridReady={handleGridReady}
              suppressCellFocus
              onSortChanged={() => gridApi.redrawRows()}
              components={{
                agColumnHeader: MotifTableHeaderRenderer,
                motifTableCellRenderer: MotifTableCellRenderer,
              }}
              paginationPageSize={pageSize}
              pagination
              rowSelection={"single"}
              onRowSelected={onRowSelected}
              suppressNoRowsOverlay={true}
            />
          </section>
        )}
      </section>
    </>
  );
};
export default AllDonations;
