import { useState, useEffect, useContext, useCallback } from "react";
import {
  MotifTable,
  MotifTableHeaderRenderer,
  MotifTableCellRenderer,
} from "@ey-xd/motif-react";

import SearchForTables from "../../components/layout/SearchForTables.jsx";
import SkeletonTable from '../../components/layout/SkeletonTable';
import LabelsContext from "../../context/LabelsContext.js";
import { useNavigate } from "react-router-dom";

import { fetchOrganizationsData } from "../../services/api.js";

import "../../assets/css/pages/all-organization.scss";
import { statusCreation } from "../../globals.js";

let gridApi;

const AllOrganization = () => {
  const [organizationsData, setOrganizationsData] = useState([]);
  const [filteredOrganizationsData, setFilteredOrganizationsData] = useState(
    []
  );
  const [pageSize] = useState("10");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setLoading] = useState(true)
  const navigate = useNavigate();

  const labels = useContext(LabelsContext);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.style.overflow = "auto";
    const fetchData = async () => {
      try {
        const data = await fetchOrganizationsData(true);
        setOrganizationsData(data);
      } catch (error) {
        console.error("Error fetching the organizations data", error);
      } finally {
        setLoading(false)
      }
    };
    fetchData();
  }, []);

  const handleGridReady = (params) => {
    ({ api: gridApi } = params);
  };

  const columnDefs = [
    {
      headerName: labels.table_organization_name,
      field: "name",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.table_cif,
      field: "cif",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.console_patrono,
      field: "inhouse",
      sortable: true,
      filter: true,
      flex: 1,
      cellRendererFramework: ({ value }) => value === null ? "" : value ? labels.console_yes : labels.console_no
    },
    {
      headerName: labels.console_table_stage,
      field: "status",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.contactName,
      field: "contact.name",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.contactEmail,
      field: "contact.email",
      sortable: true,
      filter: true,
      flex: 1,
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      const resolvedData = await Promise.all(
        organizationsData.map((event) => ({
          cif: event.cif,
          name: event.name,
          inhouse: event.inhouse,
          id: event.id,
          location: {
            coordinates: {
              latitude: event.location?.coordinates?.latitude || "",
              longitude: event.location?.coordinates?.longitude || "",
            },
            address: {
              city: event.location?.address?.city || "",
              country: event.location?.address?.country || "",
              street: event.location?.address?.street || ""
            }
          },
          contact: {
            name: event.contact?.name,
            email: event.contact?.email,
            phone: event.contact?.phone || "",
          },
          collective: event.collective || "",
          strategicAxis: event.strategicAxis || [],
          scope: event.scope || [],
          bridgeApproved: event.bridgeApproved,
          imageCover: event.imageCover || [],
          logo: event.logo || {},
          status: statusCreation(event),
          description: event.description || "",
          comments: event.comments
        }))
      );

      //const onPageSizeChanged = (newPageSize) => {
      // setPageSize(newPageSize);
      //gridApi.paginationSetPageSize(newPageSize);
      //};
      const sortedActionsData = [...resolvedData].sort((a, b) => {
        const titleA = a.name.toLowerCase();
        const titleB = b.name.toLowerCase();
        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0;
      });
      const filteredOrganizationsData = sortedActionsData?.filter(
        (organization) =>
          organization.cif?.toLowerCase().includes(searchValue.toLowerCase()) ||
          organization.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          organization.contact?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          organization.contact?.email?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredOrganizationsData(filteredOrganizationsData);
    };
    fetchData();
  }, [organizationsData, searchValue]);
  const onRowSelected = useCallback(
    (organization) => {
      navigate("/organizations/edit-organization/", {
        state: { organizationData: organization.node.data },
      });
      return;
    },
    [navigate]
  );

  return (
    <>
      <section className="all-organizations-page motif-col-lg-12">
        <div className="organizations-info">
          <span className="title motif-h2">
            {labels.console_table_organization}
          </span>
          <div>
            <span className="number-total-organizations">
              {organizationsData.length}
            </span>
            <span className="total-organizations">
              {labels.console_total_organization}
            </span>
          </div>
        </div>
        <SearchForTables
          onSearchChange={(value) => setSearchValue(value)}
          pageType="organizations"
          showAddButton={true}
          showSwitchButton={false}
        />
        {isLoading ? <SkeletonTable /> :
          <section className="organizations-table">
            <MotifTable
              defaultColDef={{
                headerCheckboxSelection: false,
                checkboxSelection: false,
              }}
              firstColumnBorderRight
              columnDefs={columnDefs}
              rowData={filteredOrganizationsData}
              onGridReady={handleGridReady}
              suppressCellFocus
              onSortChanged={() => gridApi.redrawRows()}
              components={{
                agColumnHeader: MotifTableHeaderRenderer,
                motifTableCellRenderer: MotifTableCellRenderer,
              }}
              paginationPageSize={pageSize}
              pagination
              rowSelection={"single"}
              onRowSelected={onRowSelected}
              suppressNoRowsOverlay={true}
            />
          </section>
        }
      </section>
    </>
  );
};
export default AllOrganization;
