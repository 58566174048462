import SecondaryMenu from "../../components/layout/SecondaryMenu";
import Modal from "../../components/Modal";

import { MotifButton } from "@ey-xd/motif-react";

import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../../assets/css/pages/new-activity.scss";
import { postNotification, fetchActions, fetchDonationsData } from "../../services/api";

import LabelsContext from "../../context/LabelsContext";
import SectionFields from "../../components/form/molecules/SectionFields";
import InvalidModal from "../../components/InvalidModal";
import { formattedDate } from "../../globals";

const PushNotifications = () => {
  const [showModal, setShowModal] = useState(false);
  const [showInvalidModal, setShowInvalidModal] = useState(false);
  const labels = useContext(LabelsContext);
  const [actionsOptions, setActionsOptions] = useState([]);
  const [donationsOptions, setDonationsOptions] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    actionId: null,
    donationId: null,
    isReminder: false
  });

  useEffect(() => {
    const fetchData = () => {
      try {
        fetchActions(true).then((data) => {
          const options = data.filter(action => new Date(formattedDate(action.startDate).setHours(0,0,0,0)) >= new Date().setHours(0,0,0,0)).map((action) => ({
                label: action.title,
                value: action.actionId,
              }))
          setActionsOptions(options);
        });
        fetchDonationsData(true).then((data) => {
          const options = data.filter(donation => new Date(formattedDate(donation.startDate).setHours(0,0,0,0)) >= new Date().setHours(0,0,0,0)).map((donation) => ({
                label: donation.title,
                value: donation.donationId
              }))
          setDonationsOptions(options);
        });
      } catch (error) {
        console.error("Error fetching the actions/donations data", error);
      }
    };
    fetchData();
  }, []);

  const handleFormSubmit = async () => {
    setisFormSubmited(true);
    if (isFormValid()) {
      const submitData = {
        ...formData
      };
      await postNotification(submitData);
      navigate("/");
    } else {
      setShowInvalidModal(true);
    }
  };

  const isFormValid = () => {
    const requiredFields = [
      "title",
      "description"
    ];

    const areRequiredFieldsValid = requiredFields.every((field) =>
      Boolean(formData[field])
    );

    return areRequiredFieldsValid;
  };

  const [isFormSubmited, setisFormSubmited] = useState(false);
  const navigate = useNavigate();

  const openWarningModal = () => {
    setShowModal(true);
  };

  const closeWarningModal = () => {
    setShowModal(false);
  };
  const closeInvalidModal = () => {
    setShowInvalidModal(false);
  };
  const handleDiscardChanges = () => {
    document.body.style.overflow = "auto";
    setShowModal(false);
    navigate("/");
  };

  const handleNavigateHome = () => {
    navigate("/");
  };

  return (
    <>
      <SecondaryMenu
        title='Push Notifications'
        onNavigate={handleNavigateHome}
        editing={true}
        pageType=""
        hideEditButton={true}
      />
      <section className="motif-col-lg-12 section-page">
        <SectionFields
          formData={formData}
          setFormData={setFormData}
          isFormSubmited={isFormSubmited}
          actionsOptions={actionsOptions}
          donationsOptions={donationsOptions}
          descriptionLabel={labels.console_description}
          fieldVisibility={{
            title: true,
            descriptionNotif: true,
            actionsNotif: true,
            donationsNotif: true,
            isReminder: true
          }}
        />
        <div className="activity-buttons">
          <MotifButton
            variant="secondary"
            onClick={openWarningModal}
            className="activity-buttons-secondary"
          >
            {labels.console_cancel}
          </MotifButton>
          <MotifButton
            onClick={handleFormSubmit}
            className="activity-buttons-primary"
          >
            {labels.console_save}
          </MotifButton>
        </div>
      </section>
      <section className="motif-col-lg-5"></section>

      <Modal
        show={showModal}
        onClose={closeWarningModal}
        title={labels.console_unsavedChanges}
        body={labels.console_leavePage}
        primaryButtonText={labels.console_keepEditing}
        onPrimaryButtonClick={closeWarningModal}
        secondaryButtonText={labels.console_discardChanges}
        onSecondaryButtonClick={handleDiscardChanges}
      />
      <InvalidModal
        show={showInvalidModal}
        onClose={closeInvalidModal}
        title={labels.console_title_invalidForm}
        body={labels.console_body_invalidForm}
        primaryButtonText={labels.console_close}
        onPrimaryButtonClick={closeInvalidModal}
      />
    </>
  );
};

export default PushNotifications;
