import {
  MotifRichTextEditor,
  MotifErrorMessage,
  MotifFormField,
} from "@ey-xd/motif-react";
const editorModules = {
  toolbar: [
    [{ size: [] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};
const RichTextEditorField = ({
  label,
  disabled,
  value,
  onChange,
  error,
  errorMessage,
  required,
}) => {
  return (
    <div>
      {label && (
        <label className="input-label">
          {label} {required && <span className="input-label-required">*</span>}
        </label>
      )}
      <MotifFormField>
        <MotifRichTextEditor
          readOnly={disabled}
          value={value}
          onChange={onChange}
          modules={editorModules}
        />
        {error && <MotifErrorMessage>{errorMessage}</MotifErrorMessage>}
      </MotifFormField>
    </div>
  );
};

export default RichTextEditorField;
