import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifButton,
  MotifChip,
  MotifChipGroup,
  MotifLabel,
} from "@ey-xd/motif-react";

import Modal from "../../components/Modal";
import ImagePreview from "../../components/form/molecules/ImagePreview";

import UserFields from "../../components/form/molecules/UserFields";
import UserProfileFields from "../../components/form/molecules/UserProfileFields";
import UserInfoContext from "../../context/UserInfo/UserInfoContext";

import LabelsContext from "../../context/LabelsContext";

import "../../assets/css/pages/new-donation.scss";
import { postRolePromote } from "../../services/api";
import SecondaryMenuUsers from "../../components/layout/SecondaryMenuUsers";

const NewUser = () => {
  const [showModal, setShowModal] = useState(false);
  const { defaultUserInfo, userInfo, updateUserInfo } =
    useContext(UserInfoContext);
  const [formData, setFormData] = useState(userInfo);
  const [actionType, setActionType] = useState("");
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const labels = useContext(LabelsContext);
  const navigate = useNavigate();

  //const handleFieldChange = (field, value) => {
  // setFormData((prev) => ({ ...prev, [field]: value }));
  //};
  const handleFormSubmit = async () => {
    setisFormSubmited(true);
    if (isFormValid()) {
      setIsDisabledButton(true);
      await postRolePromote(formData.role, formData.userId);
      navigate("/profile/all-users");
      setIsDisabledButton(false);
      updateUserInfo(formData);
    } else {
      setIsDisabledButton(false);
    }
  };
  const resetFormData = () => {
    setFormData(defaultUserInfo);
  };

  const isFormValid = () => {
    return Object.values(formData).every((field) => Boolean(field));
  };

  const [isFormSubmited, setisFormSubmited] = useState(false);

  const openWarningModal = () => {
    setActionType("save");
    setShowModal(true);
  };
  const handleNavigateHome = () => {
    resetFormData();
    navigate("/profile/all-users");
  };
  const closeWarningModal = () => {
    setShowModal(false);
  };

  const handleDiscardChanges = () => {
    setShowModal(false);
    handleNavigateHome();
  };

  const handleCancelClick = () => {
    setActionType("cancel");
    setShowModal(true);
  };
  const handleChipClick = (role) => {
    setFormData((prev) => ({ ...prev, role }));
  };
  const [fileQueue, setFileQueue] = useState([]);
  return (
    <>
      <SecondaryMenuUsers
        title={labels.console_new_user}
        onNavigate={handleNavigateHome}
      />
      <section className="motif-col-lg-12 section-page">
        <UserFields
          formData={formData}
          setFormData={setFormData}
          isFormSubmited={isFormSubmited}
          setFileQueue={setFileQueue}
          labels={labels}
        />
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_user_profile}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <UserProfileFields
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              labels={labels}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <MotifLabel>{labels.console_user_select_option}</MotifLabel>
        <MotifChipGroup>
          <MotifChip
            onClick={
              formData.role !== "SUPER_ADMIN"
                ? () => handleChipClick("SUPER_ADMIN")
                : null
            }
            disabled={formData.role === "SUPER_ADMIN"}
          >
            {labels.console_user_superadmin}
          </MotifChip>
          <MotifChip
            onClick={
              formData.role !== "ADMIN" ? () => handleChipClick("ADMIN") : null
            }
            disabled={formData.role === "ADMIN"}
          >
            {labels.console_user_admin}
          </MotifChip>
          <MotifChip
            onClick={
              formData.role !== "REGULAR"
                ? () => handleChipClick("REGULAR")
                : null
            }
            disabled={formData.role === "REGULAR"}
          >
            {labels.console_user_regular}
          </MotifChip>
          <MotifChip
            onClick={
              formData.role !== "CONTRIBUTOR"
                ? () => handleChipClick("CONTRIBUTOR")
                : null
            }
            disabled={formData.role === "CONTRIBUTOR"}
          >
            {labels.console_user_contributor}
          </MotifChip>
        </MotifChipGroup>

        <div className="activity-buttons">
          <MotifButton
            variant="secondary"
            onClick={handleCancelClick}
            className="activity-buttons-secondary"
          >
            {labels.console_cancel}
          </MotifButton>
          <MotifButton
            onClick={openWarningModal}
            className="activity-buttons-primary"
          >
            {labels.console_save}
          </MotifButton>
        </div>
      </section>
      <section className="motif-col-lg-5">
        <ImagePreview imageQueue={fileQueue} />
      </section>

      <Modal
        show={showModal}
        onClose={closeWarningModal}
        title={
          actionType === "save"
            ? labels.console_changes_saved
            : labels.console_unsavedChanges
        }
        body={
          actionType === "save"
            ? labels.console_confirm_publish_role
            : labels.console_leavePage
        }
        primaryButtonText={
          actionType === "save"
            ? labels.console_publish
            : labels.console_keepEditing
        }
        onPrimaryButtonClick={
          actionType === "save"
            ? () => handleFormSubmit(false)
            : closeWarningModal
        }
        secondaryButtonText={
          actionType === "save"
            ? labels.console_cancel
            : labels.console_discardChanges
        }
        onSecondaryButtonClick={
          actionType === "save" ? closeWarningModal : handleDiscardChanges
        }
        disabled={isDisabledButton}
      />
    </>
  );
};

export default NewUser;
