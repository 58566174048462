import WelcomeCard from "../components/WelcomeCard";
import HomeInformation from "../components/HomeInformation";
import LastActivities from "../components/LastActivities";
import QuickActions from "../components/QuickActions";

import "../assets/css/pages/home.scss";

export function Home() {
  return (
    <>
      <div className="motif-container">
        <div className="motif-row">
          <div className="info motif-col-lg-12">
            <div className="motif-container">
              <div className="motif-row header-wrapper">
                <WelcomeCard />
                <HomeInformation />
              </div>
            </div>
          </div>
          <div className="activities-block motif-col-lg-12">
            <LastActivities />
            <QuickActions />
          </div>
        </div>
      </div>
    </>
  );
}
