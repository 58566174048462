import "../../assets/css/components/table-search.scss";

import { useState, useContext } from "react";
import {
  MotifSearch,
  MotifToggleSwitch,
  MotifFormField,
  MotifButton,
} from "@ey-xd/motif-react";

import { Link } from "react-router-dom";

import LabelsContext from "../../context/LabelsContext";

const SearchForTables = ({
  onSearchChange,
  pageType,
  showAddButton,
  checked,
  onToggleSwitchChange,
  showSwitchButton,
}) => {
  const labels = useContext(LabelsContext);

  const [searchInputValue, setSearchInputValue] = useState("");

  let buttonLabel;
  let searchPlaceholder;
  let viewCheckboxLabel;
  switch (pageType) {
    case "activities":
      buttonLabel = labels.console_create_action;
      searchPlaceholder = labels.console_search_activity;
      viewCheckboxLabel = labels.console_view_my_activities;
      break;
    case "organizations":
      buttonLabel = labels.console_add_organization;
      searchPlaceholder = labels.console_search_organizations;
      viewCheckboxLabel = labels.console_view_my_organizations;
      break;
    case "donations":
      buttonLabel = labels.console_create_donations;
      searchPlaceholder = labels.console_search_donations;
      viewCheckboxLabel = labels.console_view_my_donations;
      break;
    case "landings":
      buttonLabel = labels.console_add_landing;
      searchPlaceholder = labels.console_search_landings;
      viewCheckboxLabel = labels.console_view_my_landings;
      break;
    case "volunteers":
      buttonLabel = labels.console_add_volunteer;
      searchPlaceholder = labels.console_search_volunteers;
      viewCheckboxLabel = labels.console_view_inactive_volunteers;
      break;
    case "probonos":
      buttonLabel = labels.console_add_probono;
      searchPlaceholder = labels.console_search_probonos;
      viewCheckboxLabel = labels.console_view_my_probonos;
      break;
    case "questions":
      buttonLabel = labels.console_add_question;
      searchPlaceholder = labels.console_search_questions;
      viewCheckboxLabel = labels.console_view_my_questions;
      break;
    default:
      buttonLabel = labels.console_home_create_activity;
      searchPlaceholder = labels.console_search_activity;
      viewCheckboxLabel = labels.console_view_my_activities;
  }

  if (!labels) {
    return null;
  }

  const pageTypeToRoute = {
    activities: "/actions/new-action",
    probonos: "/actions/pro-bonos",
    donations: "/donations/new-donation",
    organizations: "/organizations/new-organization",
    landings: "/organizations/new-landing",
    questions: "/sustainability/new-question",
  };

  return (
    <div className="table-search-component">
      <div className="table-search-interactions">
        <MotifFormField className="motif-form-search">
          <MotifSearch
            value={searchInputValue}
            id="states-search"
            onChange={(event) => {
              setSearchInputValue(event.target.value);
              onSearchChange(event.target.value);
            }}
            onSelect={(val) => setSearchInputValue(val)}
            aria-label="Search"
            placeholder={searchPlaceholder}
            className="motif-form-search__input"
          />
        </MotifFormField>
        {showSwitchButton && (
          <MotifFormField className="motif-form-switch">
            <MotifToggleSwitch
              checked={checked}
              onChange={onToggleSwitchChange}
              id="toggle1"
              onLabel={viewCheckboxLabel}
              offLabel=""
            />
          </MotifFormField>
        )}
        {showAddButton && (
          <Link to={pageTypeToRoute[pageType] || "/"} className="anchor-link">
            <MotifButton>{buttonLabel}</MotifButton>
          </Link>
        )}
      </div>
    </div>
  );
};

export default SearchForTables;
