import { useContext } from "react";
import LabelsContext from "../context/LabelsContext";
import { useMsal } from "@azure/msal-react";
import "../assets/css/components/Welcome.scss";

const WelcomeCard = () => {
  const labels = useContext(LabelsContext);
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts()[0];
  return (
    <article className="wcard motif-col-lg-4">
      <h1 className="wcard__title motif-h1">
        {labels.console_home_welcome} {accounts?.name}
      </h1>
    </article>
  );
};

export default WelcomeCard;
