import { useContext } from "react";
import RichTextEditorField from "./RichTextEditorField";
import LabelsContext from "../../../context/LabelsContext";

const Comments = ({
  formData,
  setFormData,
  fieldVisibility = {
    comments: true,
  },
  isDisabled,
}) => {
  const handleChange = (fieldName, value) => {
    setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
  };
  const labels = useContext(LabelsContext);

  return (
    <>
      {fieldVisibility.comments && (
        <RichTextEditorField
          label={labels.console_comments}
          value={formData.comments}
          onChange={(val) => handleChange("comments", val)}
          disabled={isDisabled}
        />
      )}
    </>
  );
};

export default Comments;
