import React, { useEffect, useState, useContext } from "react";
import ActivityCount from "./ActivityCount";
import {
  fetchActions,
  fetchOrganizationsData,
  fetchLandingsData,
  fetchProbonosData,
  fetchDonationsData,
} from "../services/api";
import LabelsContext from "../context/LabelsContext";

import "../assets/css/components/HomeInformation.scss";

const HomeInformation = () => {
  const [draftCount, setDraftCount] = useState(0);
  const [completeCount, setCompleteCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const labels = useContext(LabelsContext);

  useEffect(() => {
    const fetchData = async () => {
      const actions = await fetchActions(true);
      const organizations = await fetchOrganizationsData(true);
      const landings = await fetchLandingsData(true);
      const probonos = await fetchProbonosData(true);
      const donations = await fetchDonationsData(true);

      const allItems = [
        ...actions,
        ...organizations,
        ...landings,
        ...probonos,
        ...donations,
      ];

      const draftItems = allItems.filter((item) => item.isDraft);
      const completeItems = allItems.filter((item) => !item.isDraft);

      setDraftCount(draftItems.length);
      setCompleteCount(completeItems.length);
      setTotalCount(allItems.length);
    };

    fetchData();
  }, []);

  return (
    <section className="activity-count motif-col-lg-8">
      <ActivityCount
        numberActivities={draftCount}
        name={labels.console_home_draft}
      />
      <ActivityCount
        numberActivities={completeCount}
        name={labels.console_home_complete}
      />
      <ActivityCount
        numberActivities={totalCount}
        name={labels.console_home_total}
      />
    </section>
  );
};

export default HomeInformation;
