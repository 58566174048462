import "../../../assets/css/components/image-preview.scss";

const ImagePreview = ({ imageQueue }) => {
  return (
    <img
      src={imageQueue.previewUrl || imageQueue.url}
      alt={imageQueue.name}
      key={imageQueue.path}
      className="image-preview"
    />
  );
};

export default ImagePreview;
