import {
  MotifFormField,
  MotifLabel,
  MotifSelect,
  MotifOption,
  MotifErrorMessage,
} from "@ey-xd/motif-react";

import "../../../assets/css/components/SelectInput.scss";

const SelectInput = ({
  label,
  value,
  onChange,
  options,
  required = false,
  error = false,
  errorMessage,
  disabled,
  country,
  filter
}) => {
  return (
    <div>
      {label && (
        <label className="input-label">
          {label} {required && <span className="input-label-required">*</span>}
        </label>
      )}
      <MotifFormField>
        {!country ? (
          <MotifLabel id="select-label">{disabled ? value : label}</MotifLabel>
        ) : (
          <MotifLabel id="select-label">{!value ? label : value}</MotifLabel>
        )}
        <MotifSelect
          value={value}
          onChange={(val) => onChange(val)}
          className="input-dropdown"
          disabled={disabled}
          filter={filter}
        >
          {options.map((option, index) => (
            <MotifOption key={index} value={option.value} className="dropdown">
              {option.label}
            </MotifOption>
          ))}
        </MotifSelect>
        {error && <MotifErrorMessage>{errorMessage}</MotifErrorMessage>}
      </MotifFormField>
    </div>
  );
};

export default SelectInput;
