import { useState, useEffect, useContext, useCallback } from "react";
import {
  MotifTable,
  MotifTableHeaderRenderer,
  MotifTableCellRenderer,
} from "@ey-xd/motif-react";

import SearchForTables from "../../components/layout/SearchForTables.jsx";
import LabelsContext from "../../context/LabelsContext.js";

import { fetchQuestionsData } from "../../services/api.js";

import "../../assets/css/pages/all-organization.scss";
import { useNavigate } from "react-router-dom";
let gridApi;

const AllQuestion = () => {
  const [questionsData, setQuestionsData] = useState([]);
  const [filteredQuestionsData, setFilteredQuestionsData] = useState([]);
  const [pageSize] = useState("10");
  const [searchValue, setSearchValue] = useState("");

  const labels = useContext(LabelsContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "auto";
    const fetchData = async () => {
      try {
        const data = await fetchQuestionsData();
        setQuestionsData(data.questions);
      } catch (error) {
        console.error("Error fetching the landings data", error);
      }
    };
    fetchData();
  }, []);

  const handleGridReady = (params) => {
    ({ api: gridApi } = params);
  };

  const columnDefs = [
    {
      headerName: labels.table_title,
      field: "questionText",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.activity_category,
      field: "type",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.activity_category,
      field: "answers",
      sortable: true,
      filter: true,
      flex: 1,
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      const resolvedData = await Promise.all(
        questionsData.map((question) => ({
          questionText: question.questionText,
          type: question.questionType,
          answers: question.answers.length,
          answersData: question.answers,
          icon: question.icon,
          country: question.country,
          id: question.id,
        }))
      );

      //const onPageSizeChanged = (newPageSize) => {
      //setPageSize(newPageSize);
      //gridApi.paginationSetPageSize(newPageSize);
      //};
      const sortedActionsData = [...resolvedData].sort((a, b) => {
        const titleA = a.questionText.toLowerCase();
        const titleB = b.questionText.toLowerCase();
        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0;
      });
      const filteredQuestionsData = sortedActionsData.filter(
        (question) =>
          question.question
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          question.type?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredQuestionsData(filteredQuestionsData);
    };
    fetchData();
  }, [questionsData, searchValue]);
  const onRowSelected = useCallback(
    (question) => {
      navigate("/sustainability/edit-question/", {
        state: { questionData: question.node.data },
      });
      return;
    },
    [navigate]
  );
  return (
    <>
      <section className="all-organizations-page motif-col-lg-12">
        <div className="organizations-info">
          <span className="title motif-h2">
            {labels.console_question_title}
          </span>
          <div>
            <span className="number-total-organizations">
              {questionsData.length}
            </span>
            <span className="total-organizations">
              {labels.console_total_questions}
            </span>
          </div>
        </div>
        <SearchForTables
          onSearchChange={(value) => setSearchValue(value)}
          pageType="questions"
          showAddButton={true}
          showSwitchButton={false}
        />
        <section className="organizations-table">
          <MotifTable
            defaultColDef={{
              headerCheckboxSelection: false,
              checkboxSelection: false,
            }}
            firstColumnBorderRight
            columnDefs={columnDefs}
            rowData={filteredQuestionsData}
            onGridReady={handleGridReady}
            suppressCellFocus
            onSortChanged={() => gridApi.redrawRows()}
            components={{
              agColumnHeader: MotifTableHeaderRenderer,
              motifTableCellRenderer: MotifTableCellRenderer,
            }}
            paginationPageSize={pageSize}
            pagination
            rowSelection={"single"}
            onRowSelected={onRowSelected}
            suppressNoRowsOverlay={true}
          />
        </section>
      </section>
    </>
  );
};
export default AllQuestion;
