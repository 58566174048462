import { useState, useEffect, useContext, useMemo } from "react";

import SmallReportBox from "../../components/reports/SmallReportBox";
import MediumReportBox from "../../components/reports/MediumReportBox";

import {
  MotifAvatar,
  MotifIcon,
  MotifTable,
  MotifProgressBar,
  MotifContentSwitcher,
  MotifButton,
} from "@ey-xd/motif-react";

import {
  actionIcStars24px,
  actionIcTrendingUp24px,
  socialIcPersonAdd24px,
  communicationIcBusiness24px,
  actionIcHourglassEmpty24px,
  editorIcAttachMoney24px,
  actionIcEuroSymbol24px,
} from "@ey-xd/motif-react/assets/icons";

import LabelsContext from "../../context/LabelsContext";
import {
  getGoldUsers,
  getProjects,
  getGeneralReport,
} from "../../services/api/apiReports";
import SkeletonTable from "../../components/layout/SkeletonTable";

import "../../assets/css/pages/general-reports.scss";

const GiveyrsReports = () => {
  const [goldUsers, setGoldUsers] = useState([]);
  const [projects, setProjects] = useState([]);

  const [activeIndex, setActiveIndex] = useState(1);
  const handleActiveClass = (index) =>
    activeIndex === index ? "motif-active" : "";

  const [generalReport, setGeneralReport] = useState({
    namesEYInitiatedActions: [],
    namesOthersInitiatedEYLedActions: [],
    namesOthersInitiatedAndLedActions: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const loadData = async () => {
      try {
        const usersData = await getGoldUsers();
        setGoldUsers(usersData || []);

        const projectsData = await getProjects();
        setProjects(projectsData || {});

        const generalReport = await getGeneralReport();
        setGeneralReport({
          namesEYInitiatedActions: generalReport?.namesEYInitiatedActions || [],
          namesOthersInitiatedEYLedActions:
            generalReport?.namesOthersInitiatedEYLedActions || [],
          namesOthersInitiatedAndLedActions:
            generalReport?.namesOthersInitiatedAndLedActions || [],
          directBeneficiaries: generalReport?.directBeneficiaries,
          indirectBeneficiaries: generalReport?.indirectBeneficiaries,
          hours: generalReport?.hours,
          directCost: generalReport?.directCost,
          economicValueActions: generalReport?.economicValueActions,
          economicValueDonations: generalReport?.economicValueDonations,
        });
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, []);
  

  const labels = useContext(LabelsContext);

  const avatarImage =
    "https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745";

  // CHART

  const total =
    projects.actions +
    projects.donations +
    projects.probonus +
    projects.landings +
    projects.organizations;

  const actionsPercentage =
    Math.round((projects.actions / total) * 100 * 10) / 10;
  const donationsPercentage =
    Math.round((projects.donations / total) * 100 * 10) / 10;
  const landingsPercentage =
    Math.round((projects.landings / total) * 100 * 10) / 10;
  const organizationsPercentage =
    Math.round((projects.organizations / total) * 100 * 10) / 10;
  const probonusPercentage =
    Math.round((projects.probonus / total) * 100 * 10) / 10;

  const totalProjectsData = {
    labels: [
      labels.console_actions,
      labels.console_donations,
      labels.console_organizations,
      labels.console_probonos_title,
      labels.console_landing_title,
    ],
    datasets: [
      {
        label: labels.console_home_total,
        data: [
          actionsPercentage,
          donationsPercentage,
          organizationsPercentage,
          probonusPercentage,
          landingsPercentage,
        ],
        backgroundColor: [
          "#FEE600",
          "#373744",
          "#BAF2D8",
          "#E26D5A",
          "#4D96FF",
        ],
      },
    ],
  };

  // TABLE

  const columnDefs = [
    { headerName: "ID", field: "id", flex: 1, suppressMovable: true },
    {
      headerName: labels.console_initiative_reports,
      field: "name",
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: labels.console_created_ey,
      field: "createdByEY",
      cellRendererFramework: ({ value }) =>
        value ? labels.console_yes : labels.console_no,
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: labels.console_led_ey,
      field: "ledByEY",
      cellRendererFramework: ({ value }) =>
        value ? labels.console_yes : labels.console_no,
      flex: 1,
      suppressMovable: true,
    },
  ];

  const filteredData = useMemo(() => {
    const actionsMapping = {
      1: "all",
      2: "createdByEY",
      3: "thirdPartyLedByEY",
      4: "thirdPartyNotLedByEY",
    };

    const allInitiatives = [
      ...generalReport.namesEYInitiatedActions.map((name) => ({
        name,
        createdByEY: true,
        ledByEY: true,
      })),
      ...generalReport.namesOthersInitiatedEYLedActions.map((name) => ({
        name,
        createdByEY: false,
        ledByEY: true,
      })),
      ...generalReport.namesOthersInitiatedAndLedActions.map((name) => ({
        name,
        createdByEY: false,
        ledByEY: false,
      })),
    ];

    const selectedActions =
      actionsMapping[activeIndex] === "all"
        ? allInitiatives
        : allInitiatives.filter((initiative) => {
            if (actionsMapping[activeIndex] === "createdByEY")
              return initiative.createdByEY;
            if (actionsMapping[activeIndex] === "thirdPartyLedByEY")
              return !initiative.createdByEY && initiative.ledByEY;
            if (actionsMapping[activeIndex] === "thirdPartyNotLedByEY")
              return !initiative.ledByEY;
            return false;
          });

    return selectedActions.map((initiative, index) => ({
      id: index + 1,
      name: initiative.name,
      createdByEY: initiative.createdByEY,
      ledByEY: initiative.ledByEY,
    }));
  }, [activeIndex, generalReport]);


  return (
    <>
      <section className="general-reports">
        <header className="header">
          <h1 className="motif-h1">{labels.console_general_reports}</h1>
        </header>
        <section className="grid-small-cards">
          <SmallReportBox
            icon={actionIcHourglassEmpty24px}
            title={labels.console_total_hours}
            content={
              generalReport.hours !== null &&
              generalReport.hours !== undefined ? (
                generalReport.hours.toLocaleString()
              ) : (
                <MotifProgressBar isIndeterminate circle hideLabel />
              )
            }
          />
          <SmallReportBox
            icon={actionIcTrendingUp24px}
            title={labels.console_total_projects}
            content={
              projects.total !== null && projects.total !== undefined ? (
                projects.total.toLocaleString()
              ) : (
                <MotifProgressBar isIndeterminate circle hideLabel />
              )
            }
          />
          <SmallReportBox
            icon={actionIcStars24px}
            title={labels.console_direct_beneficiaries}
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                {generalReport.directBeneficiaries !== null &&
                generalReport.directBeneficiaries !== undefined ? (
                  generalReport.directBeneficiaries.toLocaleString()
                ) : (
                  <MotifProgressBar isIndeterminate circle hideLabel />
                )}
              </div>
            }
          />
          <SmallReportBox
            icon={actionIcStars24px}
            title={labels.console_indirect_beneficiaries}
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                {generalReport.indirectBeneficiaries !== null &&
                generalReport.indirectBeneficiaries !== undefined ? (
                  generalReport.indirectBeneficiaries.toLocaleString()
                ) : (
                  <MotifProgressBar isIndeterminate circle hideLabel />
                )}
              </div>
            }
          />
        </section>
        <section className="grid-small-cards-second-line margin-top">
          <SmallReportBox
            icon={editorIcAttachMoney24px}
            title={labels.console_direct_cost}
            content={
              generalReport.directCost !== null &&
              generalReport.directCost !== undefined ? (
                generalReport.directCost.toLocaleString()
              ) : (
                <MotifProgressBar isIndeterminate circle hideLabel />
              )
            }
          />
          <SmallReportBox
            icon={actionIcEuroSymbol24px}
            title={labels.console_economic_value_actions}
            content={
              generalReport.economicValueActions !== null &&
              generalReport.economicValueActions !== undefined ? (
                generalReport.economicValueActions.toLocaleString()
              ) : (
                <MotifProgressBar isIndeterminate circle hideLabel />
              )
            }
          />
          <SmallReportBox
            icon={actionIcEuroSymbol24px}
            title={labels.console_economic_value_donations}
            content={
              generalReport.economicValueDonations !== null &&
              generalReport.economicValueDonations !== undefined ? (
                generalReport.economicValueDonations.toLocaleString()
              ) : (
                <MotifProgressBar isIndeterminate circle hideLabel />
              )
            }
          />
        </section>
        <section className="grid-medium-cards">
          <MediumReportBox
            icon={communicationIcBusiness24px}
            title={labels.console_total_projects}
            totalProjectsData={totalProjectsData}
            projects={projects}
            isLoading={isLoading}
          />

          <div className="gold-members medium-box">
            <div className="top-content">
              <div className="icon-box">
                <span>
                  <MotifIcon src={socialIcPersonAdd24px} />
                </span>
              </div>
              <div className="title-box">{labels.console_gold_members}</div>
            </div>
            <div className="middle-content list-gold-members">
              {goldUsers.length > 0 ? (
                goldUsers.map((user) => (
                  <div key={user.userId}>
                    <div className="list-members">
                      <MotifAvatar
                        src={user.image || avatarImage}
                        style={{ marginRight: "10px" }}
                      />
                      <div>
                        <div>{`${user.name} ${user.surname}`}</div>
                        <div style={{ fontSize: "0.8rem", color: "#666" }}>
                          {user.jobTitle}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ padding: "20px", textAlign: "center" }}>
                  {labels.console_gold_members_not_found}
                </div>
              )}
            </div>
            <div className="bottom-content">
              <span className=""></span>
              <h4>
                {labels.console_home_total}: {goldUsers.length}
              </h4>
            </div>
          </div>
        </section>
        <section className="initiative-section">
          <h3 className="motif-h3">{labels.console_initiative_reports}</h3>
          <div className="initiative-select">
            <MotifContentSwitcher>
              <MotifButton
                onClick={() => setActiveIndex(1)}
                className={handleActiveClass(1)}
                variant="secondary"
                aria-pressed={setActiveIndex === 1}
              >
                {labels.console_all}
              </MotifButton>
              <MotifButton
                onClick={() => setActiveIndex(2)}
                className={handleActiveClass(2)}
                variant="secondary"
                aria-pressed={setActiveIndex === 2}
              >
                {labels.console_initiative_ey}
              </MotifButton>
              <MotifButton
                onClick={() => setActiveIndex(3)}
                className={handleActiveClass(3)}
                variant="secondary"
                aria-pressed={setActiveIndex === 3}
              >
                {labels.console_third_led_ey}
              </MotifButton>
              <MotifButton
                onClick={() => setActiveIndex(4)}
                className={handleActiveClass(4)}
                variant="secondary"
                aria-pressed={setActiveIndex === 4}
              >
                {labels.console_third_not_led_EY}
              </MotifButton>
            </MotifContentSwitcher>
            <div className="initiative-total">
              {labels.console_home_total}: <span>{filteredData.length}</span>
            </div>
          </div>
          {isLoading ? (
            <SkeletonTable />
          ) : (
            <div>
              <MotifTable
                columnDefs={columnDefs}
                rowData={filteredData}
                suppressNoRowsOverlay={true}
                suppressCellFocus
                suppressCellHoverHighlight={true}
                pagination
                paginationPageSize="10"
                className="reports-table"
                overlayNoRowsTemplate="<span></span>"
              />
              {filteredData.length === 0 && (
                <div
                  className="no-data"
                  style={{ textAlign: "center", marginTop: "100px" }}
                >
                  {labels.console_no_data}
                </div>
              )}
            </div>
          )}
        </section>
      </section>
    </>
  );
};

export default GiveyrsReports;
