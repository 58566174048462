import { useContext, useEffect, useMemo, useState } from "react";

import SearchForUsers from "../../components/layout/SearchForUsers";
import UserGridCard from "../../components/UserGridCard";
import LabelsContext from "../../context/LabelsContext";

import { fetchAdminUsers } from "../../services/api";

import "../../assets/css/pages/all-organization.scss";
import { MotifPagination } from "@ey-xd/motif-react";

const AllUsers = () => {
  const [adminData, setAdminData] = useState([]);
  const [pageSize] = useState("10");

  const labels = useContext(LabelsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "auto";
    const fetchData = async () => {
      try {
        const data = await fetchAdminUsers();
        setAdminData(data.users);
      } catch (error) {
        console.error("Error fetching the events data", error);
      }
    };
    fetchData();
  }, []);
  const usersData = useMemo(() => adminData, [adminData]);
  const [searchValue, setSearchValue] = useState("");
  const filteredUserData = usersData.filter(
    (user) =>
      user.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      user.surname.toLowerCase().includes(searchValue.toLowerCase()) ||
      user.mail.toLowerCase().includes(searchValue.toLowerCase()) ||
      user.jobTitle.toLowerCase().includes(searchValue.toLowerCase()) ||
      user.serviceLine.toLowerCase().includes(searchValue.toLowerCase()) ||
      user.country.toLowerCase().includes(searchValue.toLowerCase())
  );
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = filteredUserData.slice(startIndex, endIndex);
  return (
    <>
      <section className="all-organizations-page motif-col-lg-12">
        <div className="organizations-info">
          <span className="title motif-h2">
            {labels.console_user_directory}
          </span>
        </div>
        <SearchForUsers
          pageType="organizations"
          onSearchChange={(value) => setSearchValue(value)}
          showAddButton={true}
          showSwitchButton={false}
        />
        <section className="organizations-table">
          <UserGridCard rowData={paginatedData} />
          <MotifPagination
            currentPage={currentPage}
            pageSize={pageSize}
            totalItems={filteredUserData.length}
            onPageChange={handlePageChange}
            min={1}
            max={Math.ceil(filteredUserData.length / pageSize)}
            className="pagination-control-wrapper"
          />
        </section>
      </section>
    </>
  );
};
export default AllUsers;
