import '../assets/css/components/ActivityCard.scss'

const ActivityCount = ({ numberActivities, name }) => {
    return (
        <div className="ac">
            <div className='ac__status'>{name}</div>
            <div className='ac__number'>{numberActivities}</div>
        </div>
    )
}

export default ActivityCount;