export const labelsData = {
  labelList: {
    "console_actions": "Actions",
    "console_total_actions": "Total actions",
    "console_create_action": "Create action",
    "console_total_organization": "Total organizations",
    "console_table_title": "Title",
    "console_table_organization": "Organization",
    "console_table_stage": "Status",
    "console_table_last_modified": "Last modified",
    "console_last_actions": "Last actions",
    "console_table_enrolled": "Enrolled",
    "console_home_draft": "Draft",
    "console_home_welcome": "Welcome,",
    "console_home_total": "Total",
    "console_home_actions": "Quick actions",
    "console_add_organization": "Create organization",
    "console_donation_type": "Donation Type",
    "console_donation_location_location": "Donation location",
    "console_donation_collected": "Collected",
    "console_donation_location": "Localization",
    "console_table_reports": "Reports",
    "console_table_cif": "CIF",
    "console_table_organization_name": "Organization name",
    "console_total_donations": "Total donations",
    "console_user_directory": "User directory",
    "console_search_user": "Search user",
    "console_user_superadmin": "Super Admin",
    "console_user_admin": "Admin",
    "console_user_regular": "Regular",
    "console_user_contributor": "Contributor",
    "console_user_profile": "User profile",
    "console_profile": "Profile",
    "console_user_region": "Region",
    "console_user_select_option": "Please select an option",
    "console_user_employee_info": "Employee info",
    "console_email": "Email",
    "console_gui": "GUI",
    "console_user_rank": "Rank",
    "console_sl": "SL",
    "console_user_office": "Office",
    "console_user_personal_info": "Personal Info",
    "console_user_sign_up_date": "Sign up date",
    "console_user_projects_fy": "Projects FY24",
    "console_user_projects": "Projects",
    "console_user_projects_involved": "Projects involved",
    "console_name": "Name",
    "console_enter_name": "Enter name",
    "console_error_message_input_name": "Oops, please enter a name!",
    "console_surname": "Surname",
    "console_enter_surname": "Enter surname",
    "console_error_message_input_surname": "Oops, please enter a surname!",
    "console_enter_rank": "Enter rank",
    "console_error_message_job_title": "Oops, please select a jobTitle!",
    "console_error_message_sl": "Oops, please select a SL!",
    "console_error_message_region": "Oops, please select a region!",
    "console_enter_office": "Enter office",
    "console_enter_email": "Enter email",
    "console_error_message_office": "Oops, please enter a office!",
    "console_error_message_mail": "Oops, please enter a mail!",
    "console_view_inactive_volunteers": "View inactive volunteers",
    "console_search_volunteers": "Search volunteers",
    "console_add_volunteer": "Create volunteer",
    "console_view_my_landings": "View landings",
    "console_landing_title": "Landings",
    "console_total_landings": "Total landings",
    "console_search_landings": "Search landing",
    "console_add_landing": "Create landing",
    "console_view_my_donations": "View donations",
    "console_search_donations": "Search donations",
    "console_create_donations": "Create donations",
    "console_view_my_probonos": "View probonos",
    "console_view_my_organizations": "View organizations",
    "console_search_organizations": "Search organizations",
    "console_probonos_title": "Probonos",
    "console_dateTime": "Date time",
    "console_time_zone": "Time zone",
    "console_date_management_details": "Date / Management details",
    "console_location": "Location",
    "console_pointOfContact": "EY point of contact",
    "console_comments": "Comments",
    "console_cancel": "Cancel",
    "console_confirm": "Confirm",
    "console_save": "Save",
    "console_subtitle": "Subtitle",
    "console_category": "Category",
    "console_charityOrg": "Charity Organization",
    "console_organization_url": "Organization Url",
    "console_list_organization": "List of organizations",
    "console_shortDescription": "Short Description",
    "console_totalVolunteerHours": "Total volunteer hours",
    "console_unsavedChanges": "Unsaved changes",
    "console_leavePage": "Are you sure you want to leave this page?",
    "console_keepEditing": "Keep Editing",
    "console_edit": "Edit",
    "console_accept": "Accept",
    "console_saveDraft": "Save as draft",
    "console_discardChanges": "Discard Changes",
    "console_add_probono": "Create Probono",
    "console_search_probonos": "Search Probonos",
    "console_contactName": "Contact name",
    "console_contactEmail": "Contact email",
    "console_total_probonos": "Total Probonos",
    "console_management_details": "Management details",
    "console_contact": "Contact",
    "console_collective": "Collective",
    "console_skills": "Skills",
    "console_description": "Description",
    "console_giveyrs_points": "GivEYr's points",
    "console_max_participants": "Max participants",
    "console_required_volunteers": "Required volunteers",
    "console_direct_benefit": "Number direct beneficiaries",
    "console_indirect_benefit": "Number indirect beneficiaries",
    "console_direct_spent": "Direct spent EY",
    "console_donation_entity": "Donation entity",
    "console_initiative": "Select the initiative",
    "console_attendance": "Attendance",
    "console_geo_area": "Geographic Area",
    "console_country": "Country",
    "console_street": "Street",
    "console_city": "City",
    "console_address": "Address",
    "console_latitude": "Latitude",
    "console_longitude": "Longitude",
    "console_phone_number": "Phone number",
    "console_changes_saved": "Changes Sucessfully Saved",
    "console_changes_saved_draft": "Changes Sucessfully Saved in Draft",
    "console_confirm_publish": "Are you sure you want to publish new Action?",
    "console_confirm_publish_organization": "Are you sure you want to publish new Organization?",
    "console_confirm_publish_donation": "Are you sure you want to publish new Donation?",
    "console_confirm_publish_probono": "Are you sure you want to publish new Probono?",
    "console_confirm_publish_landing": "Are you sure you want to publish new Landing?",
    "console_confirm_publish_role": "Are you sure you want to change the selected user role?",
    "console_user_comment_saved_successfully": "User comment saved successfully",
    "console_confirm_draft_action": "Your changes have been saved, you can continue filling the action",
    "console_confirm_draft_organization": "Your changes have been saved, you can continue filling the organization",
    "console_confirm_draft_donation": "Your changes have been saved, you can continue filling the donation",
    "console_confirm_draft_probono": "Your changes have been saved, you can continue filling the probono",
    "console_confirm_draft_landing": "Your changes have been saved, you can continue filling the landing",
    "console_publish": "Publish",
    "console_start_date": "Start date",
    "console_creation_date": "Creation date",
    "console_start_hour": "Start hour",
    "console_end_date": "End date",
    "console_end_hour": "End hour",
    "console_error_pick_date": "Oops, please pick a start date!",
    "console_error_pick_end_date": "Oops, please pick an end date!",
    "console_investment_type": "Investment type",
    "console_money_invested": "Money invested",
    "console_engagement": "Engagement associated",
    "console_fiscal_year": "Fiscal Year",
    "console_placeholder_engagement": "Enter engagement associated",
    "console_team_ey": "Equipo EY",
    "console_general_reports": "General reports",
    "console_total_hours": "Total hours",
    "console_gold_members": "Gold members",
    "console_beneficiaries": "Beneficiaries",
    "console_donations": "Donations",
    "console_organizations": "Organizations",
    "console_total_projects": "Total projects",
    "console_total_hours_projects": "Total hours by projects",
    "console_virtual_roles": "Virtual roles available",
    "console_edit_probonos": "EDIT PROBONOS",
    "console_edit_donations": "EDIT DONATIONS",
    "console_edit_actions": "EDIT ACTIONS",
    "console_edit_landing": "EDIT LANDING",
    "console_edit_organization": "EDIT ORGANIZATION",
    "console_create_new_action": "CREATE NEW ACTION",
    "console_create_donation": "CREATE NEW DONATION",
    "console_create_probonos": "CREATE NEW PROBONOS",
    "console_create_questions": "CREATE NEW QUESTIONS",
    "console_create_new_landing": "CREATE NEW LANDING",
    "console_create_new_organization": "CREATE NEW ORGANIZATION",
    "console_hours_dedicated": "Number of hours dedicated",
    "console_impacted_lives": "Impacted lives",
    "console_error_points": "Oops, please enter a number of giveyr's points!",
    "console_error_impacted_lives": "Oops, please enter a number of impacted lives!",
    "console_error_hours_dedicated": "Oops, please enter a number of hours dedicated!",
    "console_new_user": "NEW USERS",
    "console_users": "Users",
    "console_question": "Question",
    "console_icon": "Icon",
    "console_answer": "Answer",
    "console_correct_answer": "Correct answer",
    "console_add_answer": "Add answer",
    "console_event_duration": "Event duration",
    "console_home_details": "Details",
    "console_home_complete": "Complete",
    "console_search_activity": "Search action",
    "console_add_question": "Create question",
    "console_search_questions": "Search questions",
    "console_view_my_questions": "View questions",
    "console_total_questions": "Total questions",
    "console_question_title": "Questions",
    "console_projects_reports": "Projects reports",
    "console_initiative_reports": "Initiative",
    "console_direct_beneficiaries": "Direct beneficiaries",
    "console_indirect_beneficiaries": "Indirect beneficiaries",
    "console_direct_cost": "Direct cost",
    "console_economic_value": "Economic value",
    "console_economic_value_actions": "Economic value actions",
    "console_economic_value_donations": "Economic value donations",
    "console_downloadQRCode": "Download QR Code",
    "console_title_invalidForm": "Invalid form",
    "console_body_invalidForm": "Please fill all the required fields",
    "console_close": "Close",
    "console_projects_ended": "Projects ended",
    "console_dedicated_hours": "Hours dedicated",
    "console_category_reports": "Category reports",
    "console_back_to_activities": "Back to actions list",
    "console_back": "Back",
    "console_back_to_organizations": "Back to organizations list",
    "console_back_to_donations": "Back to donations list",
    "console_back_to_landings": "Back to landings list",
    "console_back_to_volunteers": "Back to volunteers list",
    "console_back_to_probonos": "Back to probonos list",
    "console_back_to_questions": "Back to questions list",
    "console_giveyrs_enrolled": "Giveyrs enrolled",
    "console_percentage_giveyrs": "% Active users",
    "console_active_users": "Active users",
    "console_giveyrs_by_sl": "GivEYrs by SL",
    "console_active_by_sl": "Active users by SL",
    "console_gold_givers_enrolled": "% Gold GivEYrs inscribed",
    "console_percentatge_inscribed": "% Inscribed",
    "console_percentatge_actives": "% Actives",
    "console_reports_sl": "Reports by SL",
    "console_education": "Education",
    "console_culture": "Culture",
    "console_entrepreneurship": "Entrepreneurship",
    "console_environment": "Environment",
    "console_dei": "DE+I",
    "console_others": "Others",
    "console_gold_members_not_found": "There are no Gold members at this time.",
    "console_initiative_ey": "Initiative by EY",
    "console_all": "All",
    "console_third_led_ey": "Third-Party led by EY",
    "console_third_not_led_EY": "Third-Party not led by EY",
    "console_created_ey": "Created by EY",
    "console_led_ey": "Led by EY",
    "console_waitinglist_category": "Waiting list by category",
    "console_vacancies_category": "Vacancies by category",
    "console_total_gold": "Total Gold Giveyrs",
    "console_hours_sl": "Hours by SL",
    "console_economic_value_sl": "Economic value by SL",
    "console_general_assessment": "General Assessment",
    "console_categories": "Categories",
    "console_direct_indirect": "Direct / Indirect beneficiaries",
    "console_entities": "Entities",
    "console_total_patrono": "Total patrono",
    "console_total_no_patrono": "Total no patrono",
    "console_waiting_list": "Waiting list",
    "console_covered_places": "Covered places",
    "console_evaluation": "Evaluation",
    "console_entity": "Entity",
    "console_hours": "Hours",
    "console_contribution": "Contribution",
    "console_active": "Active",
    "console_firm_engagement": "Firm Engagement",
    "console_no_data": "There are no data available",
    "console_vacancies": "Vacancies",
    "console_completed": "Completed",
    "console_open": "Open",
    "console_ongoing": "On going",
    "console_closed": "Closed",
    "console_gallery": "Gallery",
    "console_status_project": "Status by project",
    "console_info_project": "Projects information",
    "console_completed_waintinglist": "Completed with waiting list",
    "console_hide_action": "Hide action",
    "console_hide_donation": "Hide donation",
    "console_show_action": "Show action",
    "console_show_donation": "Show donation",
    "console_enter_title": "Enter title",
    "console_enter_title_error": "Oops, please enter a title!",
    "console_select_donation_error": "Oops, please select a donation!",
    "console_amount_tobe_donated": "Total amount to be donated",
    "console_field_required": "This field is required.",
    "console_minimum_amount": "Minimum amount no money to be recolected",
    "console_enter_minimum_amount": "Enter the minimum amount no money to be recolected",
    "console_expenses_asociated": "Expenses associated with this event",
    "console_enter_expenses_asociated": "Enter the expenses associated with this event",
    "console_select_org_error": "Oops, please select the organization!",
    "console_enter_fy": "Enter fiscal year",
    "console_enter_fy_error": "Oops, please enter a fiscal year!",
    "console_scope": "Scope",
    "console_bridge_approved": "Bridge Approved",
    "console_deadline": "Deadline",
    "console_select_attendance": "Select attendance",
    "console_led_action": "Who led the action?",
    "console_engagement_code": "Engagement code",
    "console_enter_engagement_code": "Enter engagementCode",
    "console_title_landing": "Title of the landing",
    "console_logo": "Logo",
    "console_image_logo": "Horizontal image cover or logo",
    "console_image": "Horizontal image cover",
    "console_cost": "Valor económico/coste de la actividad",
    "console_patrono": "Patrono",
    "console_strategic_axis": 'Strategic Axis',
    "console_total_givers": "Total GIVERS",
    "console_individual_givers": "Individual GIVERS",
    "console_yes": "Yes",
    "console_no": "No",
    "console_video": "Video",
    "console_link": "Link",
    "console_reminder": "Reminder",
    "console_volunteers_reports": "Volunteers Reports",
    "console_title_delete_user": "Are you sure you want to remove this user from the activity?",
    "console_delete_button": "Delete",
    "console_invalid_modal_user_title": "Please, select a valid user",
    "console_invalid_modal_user_body": "Please, provide a valid user to be removed from the action",
    "console_total_volunteers": "Total volunteers",
    "console_total_volunteers_hours": "Total volunteer hours",
    "console_joined_users": "Joined users",
    "console_description_org": "Description/Organization purpose",
    "console_service_line": "Service line",
    "console_office_location": "Office location",
    "console_location_description": "Location description",
    "console_job_title": "Job title",
    "console_projects_subscribed": "Projects subscribed",
    "console_projects_completed": "Projects completed",
    "console_home_title": "Home",
    "console_profile_title": "Profile",
    "console_all_users": "All users",
    "console_volunteers_title": "Volunteers",
    "console_all_volunteers": "All volunteers",
    "console_ranking": "Ranking",
    "console_actions_title": "Actions",
    "console_all_actions": "All actions",
    "console_create_new_action_menu": "Create new action",
    "console_all_probonos": "All probonos",
    "console_create_new_probonos_menu": "Create new probonos",
    "console_donations_title": "Donations",
    "console_all_donations": "All donations",
    "console_create_new_donation_menu": "Create new donation",
    "console_organizations_title": "Organizations",
    "console_all_organizations": "All organizations",
    "console_create_new_organization_menu": "Create new organization",
    "console_all_landings": "All landings",
    "console_create_new_landing_menu": "Create new landing",
    "console_sustainability": "Sustainability",
    "console_all_questions": "All questions",
    "console_create_new_question_menu": "Create new question",
    "console_reports_title": "Reports",
    "console_reports_general": "General",
    "console_sl_reports": "SL Reports",
    "console_reports_projects": "Projects",
    "console_reports_categories": "Categories",
    "console_reports_organizations": "Organizations",
    "console_notifications": "Notifications",
    "console_push_notifications": "Push notifications"

  }
}
export default labelsData;
