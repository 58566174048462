import { useState, useContext, useEffect } from "react";
import {
  MotifTable,
  MotifTableHeaderRenderer,
  MotifTableCellRenderer,
  MotifButton,
  MotifIcon,
} from "@ey-xd/motif-react";
import LabelsContext from "../../context/LabelsContext.js";

import SearchForTables from "../../components/layout/SearchForTables.jsx";

import SkeletonTable from "../../components/layout/SkeletonTable";

import "../../assets/css/pages/all-volunteers.scss";
import {
  actionIcDeleteForever24px,
  navigationIcChevronLeft24px,
} from "@ey-xd/motif-react/assets/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  fetchVolunteersActionList,
  postDeclineUserAction,
} from "../../services/api.js";
import DeleteUserModal from "../../components/DeleteUserModal.jsx";
import InvalidModal from "../../components/InvalidModal.jsx";

let gridApi;

const ListActivityUsers = () => {
  // eslint-disable-next-line no-unused-vars
  const labels = useContext(LabelsContext);
  const location = useLocation();
  const listVolunteers = location.state?.usersData || null;
  const actionData = location.state?.actionData || null;
  const [volunteersData, setVolunteersData] = useState(listVolunteers);
  const [checked, setChecked] = useState(false);
  const [pageSize] = useState("10");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showInvalidModal, setShowInvalidModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (volunteersData) {
      setLoading(false);
    }
  }, [volunteersData]);
  useEffect(() => {
    document.body.style.overflow = "auto";
    const fetchData = async () => {
      try {
        const data = await fetchVolunteersActionList(actionData.actionId);
        setVolunteersData(data);
      } catch (error) {
        console.error("Error fetching the actions data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [actionData.actionId]);
  const handleGridReady = (params) => {
    ({ api: gridApi } = params);
  };
  const handleButtonClick = async (userId) => {
    try {
      await postDeclineUserAction(userId, actionData.actionId);

      setVolunteersData(
        volunteersData.filter((volunteer) => volunteer.userId !== userId)
      );
      setSelectedUser(null);
    } catch (error) {
      setShowInvalidModal(true);
      console.error("Error occurred while performing action:", error);
    }
  };
  const handleDeleteUser = async () => {
    if (selectedUser) {
      await handleButtonClick(selectedUser.userId, actionData.actionId);
      setShowModal(false);
    }
  };
  const closeWarningModal = () => {
    setShowModal(false);
  };
  const closeInvalidModal = () => {
    setShowInvalidModal(false);
  };
  const columnDefs = [
    {
      headerName: "",
      cellRenderer: "motifTableCellRenderer",
      field: "image",
      sortable: true,
      width: 60,
      minWidth: 60,
      filter: true,
      pinned: "left",
      flex: 1,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Surname",
      field: "surname",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Office",
      field: "location",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Rank",
      field: "rank",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "State",
      field: "state",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Mail",
      field: "mail",
      sortable: true,
      filter: true,
      hide: true,
      flex: 1,
    },
    {
      headerName: "Profile Image",
      field: "profileImage",
      sortable: true,
      filter: true,
      hide: true,
      flex: 1,
    },
    {
      headerName: "Delete",
      cellRendererFramework: function (params) {
        const { state } = params.data;
        return state === "ACCEPTED" || state === "WAITLIST" ? (
          <MotifButton
            onClick={() => {
              setSelectedUser(params.data);
              setShowModal(true);
            }}
            className="activity-buttons-primary--cancel users-list"
          >
            <MotifIcon
              src={actionIcDeleteForever24px}
              className="delete-icon"
            />
          </MotifButton>
        ) : null;
      },
      field: "iconButton",
      flex: 1,
    },
  ];
  const rowData = volunteersData.map((voluntee) => ({
    image: (
      <>
        <img
          width={50}
          height={50}
          src={
            voluntee.image?.url ||
            "https://www.freeiconspng.com/thumbs/profile-icon-png/profile-icon-9.png"
          }
          alt=""
        />
      </>
    ),
    name: voluntee.name,
    surname: voluntee.surname,
    country: voluntee.country,
    location: voluntee.location,
    rank: voluntee.rank,
    state: voluntee.state,
    mail: voluntee.mail,
    profileImage: voluntee.image,
    serviceLine: voluntee.serviceLine,
    userId: voluntee.userId,
  }));

  const filteredLandingsData = rowData.filter((voluntee) => {
    const includesSearchValue =
      voluntee.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      voluntee.surname.toLowerCase().includes(searchValue.toLowerCase()) ||
      voluntee.location.toLowerCase().includes(searchValue.toLowerCase()) ||
      voluntee.rank.toLowerCase().includes(searchValue.toLowerCase());

    if (checked) {
      return includesSearchValue && voluntee.status === "INACTIVE";
    } else {
      return includesSearchValue;
    }
  });

  const rowClassRules = {
    "inactive-user": function (params) {
      return params.data.status === "INACTIVE";
    },
  };
  const onToggleSwitchChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <section className="all-organizations-page motif-col-lg-12">
        <div className="left-side">
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault();

              navigate("/actions/all-actions");
            }}
          >
            <div className="backIcon">
              <MotifIcon src={navigationIcChevronLeft24px} />
            </div>
            <p className="backText">{labels.console_back_to_activities}</p>
          </Link>
          <span className="title motif-h2 title-page">
            {labels.console_joined_users}{" "}
            <span style={{ fontWeight: "bold" }}>{actionData.title}</span>
          </span>
        </div>
        <div>
          <span className="number-total-organizations">
            {volunteersData.length}
          </span>
          <span className="total-organizations">
            {labels.console_total_volunteers}
          </span>
        </div>
        <SearchForTables
          onSearchChange={(value) => setSearchValue(value)}
          pageType="volunteers"
          showAddButton={false}
          checked={checked}
          onToggleSwitchChange={onToggleSwitchChange}
          showSwitchButton={false}
        />
        {isLoading ? (
          <SkeletonTable />
        ) : (
          <section className="organizations-table">
            <MotifTable
              defaultColDef={{
                headerCheckboxSelection: false,
                checkboxSelection: false,
              }}
              firstColumnBorderRight
              columnDefs={columnDefs}
              rowData={filteredLandingsData}
              onGridReady={handleGridReady}
              suppressCellFocus
              onSortChanged={() => gridApi.redrawRows()}
              components={{
                agColumnHeader: MotifTableHeaderRenderer,
                motifTableCellRenderer: MotifTableCellRenderer,
              }}
              paginationPageSize={pageSize}
              pagination
              rowSelection={"single"}
              suppressRowClickSelection={true}
              rowClassRules={rowClassRules}
              suppressNoRowsOverlay={true}
            />
          </section>
        )}
        <DeleteUserModal
          show={showModal}
          onClose={closeWarningModal}
          title={labels.console_title_delete_user}
          body={`You are going to remove ${selectedUser?.name} from ${actionData?.title}, are you sure?`}
          primaryButtonText={labels.console_delete_button}
          onPrimaryButtonClick={handleDeleteUser}
          secondaryButtonText={labels.console_cancel}
          onSecondaryButtonClick={closeWarningModal}
        />
        <InvalidModal
          show={showInvalidModal}
          onClose={closeInvalidModal}
          title={labels.console_invalid_modal_user_title}
          body={labels.console_invalid_modal_user_body}
          primaryButtonText={labels.console_close}
          onPrimaryButtonClick={closeInvalidModal}
        />
      </section>
    </>
  );
};
export default ListActivityUsers;
