import { useState, useEffect, useContext } from "react";

import PropTypes from "prop-types";

import {
  MotifTable,
  MotifTableHeaderRenderer,
  MotifTableCellRenderer,
  MotifIcon,
} from "@ey-xd/motif-react";

import {
  socialIcPages24px,
  toggleIcStarBorder24px,
  toggleIcStar24px,
  toggleIcStarHalf24px,
  actionIcList24px,
  actionIcPermIdentity24px,
} from "@ey-xd/motif-react/assets/icons";

import { Bar } from "react-chartjs-2";
import "chart.js/auto";

import LabelsContext from "../../context/LabelsContext.js";
import {
  getGoldUsers,
  getCategoriesReport,
  getOrganizationsReport,
} from "../../services/api/apiReports.js";
import SkeletonTable from "../../components/layout/SkeletonTable";

import "../../assets/css/pages/category-reports.scss";

const UsersReports = () => {
  const [isLoading, setLoading] = useState(true);
  const labels = useContext(LabelsContext);
  const [goldUsersData, setGoldUsersData] = useState([]);
  const [categoriesReport, setCategoryReport] = useState([]);
  const [organizationsData, setOrganizationsData] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [bigVerticalChartData, setBigVerticalChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [waitListChartData, setWaitListChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [vacanciesChartData, setVacanciesChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [activeUsersChart, setActiveUsersChart] = useState({
    labels: [],
    datasets: [],
  });

  function renderStarsIcons(value, type) {
    let starsIcons = [];
    const emptyStar = toggleIcStarBorder24px;
    const fullStar = toggleIcStar24px;
    const numberEmptyStars = type === "empty" ? 5 - value : 0;
    const times =
      type === "empty" ? parseInt(numberEmptyStars) : parseInt(value);
    for (let i = 0; i < times; i++) {
      starsIcons.push(
        <MotifIcon
          key={i}
          src={type === "full" ? fullStar : emptyStar}
          style={{ color: type === "full" ? "#ffbf1e" : "#D8D8D8" }}
        ></MotifIcon>
      );
    }
    return starsIcons;
  }

  const StarsCellRenderer = ({ value }) => (
    <div style={{ alignSelf: "center", display: "flex", alignItems: "center" }}>
      {value !== 0 && renderStarsIcons(value, "full")}

      {!Number.isInteger(value) && (
        <MotifIcon
          src={toggleIcStarHalf24px}
          style={{ color: "#ffbf1e" }}
        ></MotifIcon>
      )}

      {renderStarsIcons(value, "empty")}
    </div>
  );
  StarsCellRenderer.propTypes = {
    value: PropTypes.number.isRequired,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const goldUsersDataResponse = await getGoldUsers();
        setGoldUsersData(goldUsersDataResponse || []);

        const dataCategoriesResponse = await getCategoriesReport();
        const dataCategories = dataCategoriesResponse.categoryReports || [];
        setCategoryReport(dataCategories);

        const organizationsResponse = await getOrganizationsReport();
        const organizationsData =
          organizationsResponse.organizationReports || [];
        setOrganizationsData(organizationsData);

        const hoursByCategory =
          organizationsResponse.organizationReports.reduce((acc, org) => {
            org.strategicAxis.forEach((axis) => {
              const normalizedAxis = axis.toLowerCase();
              if (!acc[normalizedAxis]) acc[normalizedAxis] = 0;
              acc[normalizedAxis] += org.totalHours;
            });
            return acc;
          }, {});

        const economicValueByCategory = organizationsData.reduce((acc, org) => {
          org.strategicAxis.forEach((axis) => {
            const normalizedAxis = axis.toLowerCase();
            if (!acc[normalizedAxis]) acc[normalizedAxis] = 0;
            acc[normalizedAxis] += org.cost;
          });
          return acc;
        }, {});

        console.log(hoursByCategory, "hoursByCategory");
        console.log(economicValueByCategory, "hoursByCategory");

        const tableData = dataCategories.map((category) => {
          return {
            category: category.categoryName,
            totalProyectos: category.countProjects,
            valorEconomico: category.cost.toLocaleString(navigator.language),
            horasProyecto: category.totalHours.toLocaleString(
              navigator.language
            ),
            beneficiarios: `${category.directBeneficiaries} / ${category.indirectBeneficiaries}`,
            generalAssessment: category.generalAssessment,
            firmEngagement: category.firmEngagement,
          };
        });

        const categoryLabels = dataCategories.map(
          (category) => category.categoryName
        );
        const economicValues = dataCategories.map((category) => category.cost);
        const hoursByCategoryValues = dataCategories.map(
          (category) => category.totalHours
        );
        const waitListData = dataCategories.map(
          (category) => category.waitList
        );
        const vacanciesData = dataCategories.map(
          (category) => category.vacancies
        );
        const directBeneficiariesData = dataCategories.map(
          (category) => category.directBeneficiaries
        );
        const indirectBeneficiariesData = dataCategories.map(
          (category) => category.indirectBeneficiaries
        );
        const generalAssessmentData = dataCategories.map(
          (category) => category.generalAssessment
        );
        const firmEngagement = dataCategories.map(
          (category) => category.firmEngagement
        );
        const activeUsers = dataCategories.map(
          (category) => category.countParticipants
        );

        const updatedWaitListChartData = {
          labels: categoryLabels,
          datasets: [
            {
              label: labels.console_waitinglist_category,
              data: waitListData,
              backgroundColor: [
                "#FEE600",
                "#373744",
                "#BAF2D8",
                "#E26D5A",
                "#4D96FF",
                "#82CA9D",
              ],
            },
          ],
        };

        const updatedVacanciesChartData = {
          labels: categoryLabels,
          datasets: [
            {
              label: labels.console_vacancies_category,
              data: vacanciesData,
              backgroundColor: [
                "#FEE600",
                "#373744",
                "#BAF2D8",
                "#E26D5A",
                "#4D96FF",
                "#82CA9D",
              ],
            },
          ],
        };

        console.log("economicValues", economicValues);
        console.log("hoursByCategoryValues", hoursByCategoryValues);
        console.log("directBeneficiariesData", directBeneficiariesData);
        console.log("indirectBeneficiariesData", indirectBeneficiariesData);
        console.log("generalAssessmentData", generalAssessmentData);

        const bigChartData = {
          labels: categoryLabels,
          datasets: [
            {
              label: labels.console_economic_value,
              data: economicValues,
              backgroundColor: "#FEE600",
            },
            {
              label: labels.console_total_hours,
              data: hoursByCategoryValues,
              backgroundColor: "#373744",
            },
            {
              label: labels.console_direct_beneficiaries,
              data: directBeneficiariesData,
              backgroundColor: "#BAF2D8",
            },
            {
              label: labels.console_indirect_beneficiaries,
              data: indirectBeneficiariesData,
              backgroundColor: "#E26D5A",
            },
            {
              label: labels.console_general_assessment,
              data: generalAssessmentData,
              backgroundColor: "#4D96FF",
            },
            {
              label: labels.console_firm_engagement,
              data: firmEngagement,
              backgroundColor: "#82CA9D",
            },
          ],
        };

        const activeUsersChart = {
          labels: categoryLabels,
          datasets: [
            {
              label: labels.console_active_users,
              data: activeUsers,
              backgroundColor: [
                "#FEE600",
                "#373744",
                "#BAF2D8",
                "#E26D5A",
                "#4D96FF",
                "#82CA9D",
              ],
            },
          ],
        };

        setActiveUsersChart(activeUsersChart);
        setVacanciesChartData(updatedVacanciesChartData);
        setWaitListChartData(updatedWaitListChartData);
        setBigVerticalChartData(bigChartData);
        setDataTable(tableData);
      } catch (error) {
        console.error("Error fetching the events data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [labels]);

  console.log(categoriesReport, "categoriesReport");
  console.log(goldUsersData, "goldUsersData");
  console.log(organizationsData, "organizationsData");

  // TABLE

  const columnDefs = [
    {
      headerName: labels.console_categories,
      field: "category",
      suppressMovable: true,
    },
    {
      headerName: labels.console_total_projects,
      field: "totalProyectos",
      suppressMovable: true,
    },
    {
      headerName: labels.console_economic_value,
      field: "valorEconomico",
      suppressMovable: true,
      flex: 1,
    },
    {
      headerName: labels.console_total_hours,
      field: "horasProyecto",
      suppressMovable: true,
    },
    {
      headerName: labels.console_direct_indirect,
      field: "beneficiarios",
      suppressMovable: true,
      flex: 1,
    },
    {
      headerName: labels.console_general_assessment,
      field: "generalAssessment",
      cellRendererFramework: StarsCellRenderer,
      suppressMovable: true,
      flex: 1,
    },
    {
      headerName: labels.console_firm_engagement,
      field: "firmEngagement",
      cellRendererFramework: StarsCellRenderer,
      suppressMovable: true,
      flex: 1,
    },
  ];

  const options = {
    aspectRatio: 1,
    maintainAspectRatio: false,
    layout: {},
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        barThickness: 1,
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <>
      <section className="category-reports">
        <header className="header">
          <h1 className="motif-h1">{labels.console_category_reports}</h1>
        </header>
        <section className="grid-table-charts">
          <div className="medium-box">
            <div className="top-content">
              <div className="icon-box">
                <span>
                  <MotifIcon src={socialIcPages24px} />
                </span>
              </div>
              <div>
                <div className="title-box">
                  <span></span>
                </div>
              </div>
            </div>
            {isLoading ? (
              <SkeletonTable />
            ) : (
              <div className="middle-content-chart">
                <MotifTable
                  className="category-table reports-table"
                  columnDefs={columnDefs}
                  rowData={dataTable}
                  onGridReady={(params) => params.api.sizeColumnsToFit()}
                  components={{
                    starsCellRenderer: StarsCellRenderer,
                  }}
                  defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    filter: true,
                    sortable: true,
                    resizable: true,
                  }}
                  frameworkComponents={{
                    agColumnHeader: MotifTableHeaderRenderer,
                    motifTableCellRenderer: MotifTableCellRenderer,
                  }}
                />
              </div>
            )}
            <div className="bottom-content">
              <div className="right-side">
                <div className="legend-section"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="grid-medium-cards vertical-big-bar">
          <Bar data={bigVerticalChartData} height={300} options={options} />
        </section>
        <section className="grid-medium-cards vertical-big-bar">
          <div className="medium-box-vertical">
            <div className="top-content">
              <div className="icon-box">
                <span>
                  <MotifIcon src={actionIcList24px} />
                </span>
              </div>
              <div>
                <div className="title-box">
                  <span>{labels.console_waitinglist_category}</span>
                </div>
              </div>
            </div>
            <div className="middle-content-chart">
              <Bar data={waitListChartData} height={300} options={options} />
            </div>
            <div className="bottom-content">
              <div className="right-side">
                <div className="legend-section"></div>
              </div>
            </div>
          </div>
          <div className="medium-box-vertical">
            <div className="top-content">
              <div className="icon-box">
                <span>
                  <MotifIcon src={actionIcPermIdentity24px} />
                </span>
              </div>
              <div>
                <div className="title-box">
                  <span>{labels.console_vacancies_category}</span>
                </div>
              </div>
            </div>
            <div className="middle-content-chart">
              <Bar data={vacanciesChartData} height={300} options={options} />
            </div>
            <div className="bottom-content">
              <div className="right-side">
                <div className="legend-section"></div>
              </div>
            </div>
          </div>
          <div className="medium-box-vertical">
            <div className="top-content">
              <div className="icon-box">
                <span>
                  <MotifIcon src={actionIcPermIdentity24px} />
                </span>
              </div>
              <div>
                <div className="title-box">
                  <span>{labels.console_active_users}</span>
                </div>
              </div>
            </div>
            <div className="middle-content-chart">
              <Bar data={activeUsersChart} height={300} options={options} />
            </div>
            <div className="bottom-content">
              <div className="right-side">
                <div className="legend-section"></div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default UsersReports;
