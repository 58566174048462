import { getToken } from "../../globals";

const API_URL = process.env.REACT_APP_GIVEYRS_API_BASE_URL;


export const getGoldUsers = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/goldUsers`, {
      headers: {
        "Accept-Language": "en",
        "token": await token
      },
    });
    const data = await response.json();
    return data.users;
  } catch (error) {
    console.error("Error fetching the users data", error);
    throw error;
  }
};

export const getProjects = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/count`, {
      headers: {
        "Accept-Language": "en",
        "token": await token

      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the users data", error);
    throw error;
  }
};

export const getGeneralReport = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/generalReport`, {
      headers: {
        "Accept-Language": "en",
        "token": await token

      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the users data", error);
    throw error;
  }
};

export const getActionReport = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/projectReport`, {
      headers: {
        "Accept-Language": "en",
        "token": await token

      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the users data", error);
    throw error;
  }
};

export const getInfoBySL = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/infoBySL`, {
      headers: {
        "Accept-Language": "en",
        "token": await token

      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the users data", error);
    throw error;
  }
};

export const getOrganizationsReport = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/organizationsReport`, {
      headers: {
        "Accept-Language": "en",
        "token": await token

      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the users data", error);
    throw error;
  }
};

export const getCategoriesReport = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/categoriesReport`, {
      headers: {
        "Accept-Language": "en",
        "token": await token

      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the users data", error);
    throw error;
  }
};

export const getVolunteersReport = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/volunteersReport`, {
      headers: {
        "Accept-Language": "en",
        "token": await token

      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the users data", error);
    throw error;
  }
};