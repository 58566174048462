import { useContext, useEffect, useState } from "react";

import PropTypes from 'prop-types';

import SmallReportBox from "../../components/reports/SmallReportBox";
import {
  MotifIcon,
  MotifProgressBar,
  MotifCard,
  MotifCardHeader,
  MotifCardBody,
  MotifOption,
  MotifLabel,
  MotifSelect,
  MotifFormField,
  MotifBadge
} from '@ey-xd/motif-react';

import {
  toggleIcStar24px,
  toggleIcStarBorder24px,
  toggleIcStarHalf24px,
  actionIcAssessment24px,
  socialIcPersonAdd24px,
  actionIcEventSeat24px,
  communicationIcBusiness24px,
  socialIcGroup24px,
  socialIcPerson24px,
  placesIcBusinessCenter24px,
  editorIcAttachMoney24px,
  deviceIcAccessTime24px,
  actionIcCheckCircle24px,
  navigationIcCancel24px
} from '@ey-xd/motif-react/assets/icons';

import LabelsContext from '../../context/LabelsContext';

import { getOrganizationsReport, getActionReport } from "../../services/api/apiReports";
import SkeletonTable from '../../components/layout/SkeletonTable';

import "../../assets/css/pages/organization-reports.scss";

const GiveyrsReports = () => {
  const [isLoading, setLoading] = useState(true)
  const labels = useContext(LabelsContext)
  const [organizationsReport, setOrganizationReport] = useState([]);
  const [actionReport, setActionReport] = useState([]);
  const [entity, setEntity] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    const fetchData = async () => {
      try {
        const dataOrganizations = await getOrganizationsReport();
        setOrganizationReport(dataOrganizations.organizationReports || []);
        const actionReport = await getActionReport()
        setActionReport(actionReport || {})
      } catch (error) {
        console.error("Error fetching the events data", error);
      } finally {
        setLoading(false)
      }
    };
    fetchData();
  }, []);

  // CELLS RENDERER

  const renderStarsIcons = ((value, type) => {
    let starsIcons = []
    const emptyStar = toggleIcStarBorder24px
    const fullStar = toggleIcStar24px
    const numberEmptyStars = type === 'empty' ? 5 - value : 0
    const times = type === 'empty' ? parseInt(numberEmptyStars) : parseInt(value)
    for (let i = 0; i < times; i++) {
      starsIcons.push(<MotifIcon key={i} src={type === 'full' ? fullStar : emptyStar} style={{ color: type === 'empty' ? '#D8D8D8' : '#ffbf1e' }} ></MotifIcon >)
    }
    return starsIcons
  })

  const StarsCellRenderer = ({ value }) => (
    <div style={{ alignSelf: 'center', display: 'flex', alignItems: 'center' }}>
      {value !== 0 && renderStarsIcons(value, 'full')}

      {!Number.isInteger(value) &&
        <MotifIcon
          src={toggleIcStarHalf24px}
          style={{ color: '#ffbf1e' }} >
        </MotifIcon >}

      {renderStarsIcons(value, 'empty')}
    </div>
  );
  StarsCellRenderer.propTypes = {
    value: PropTypes.number.isRequired
  };

  const sumIndividualsByOrganization = (data, organizationName) => {
    let sum = 0;
    for(let i = 0; i < data?.length; i++) {
        if(data[i].organization === organizationName) {
            sum += data[i].individuals;
        }
    }
    return sum;
  }

  const getEntityInfo = (object, entity, type) => {
    return object.filter(item => item.entity === entity).map(item => item[type])
  }

  // CARD ENTITIES

  const dataEntities = organizationsReport.map(org => {
    const isActive = org.countProjects > 0
    const individualGivers = sumIndividualsByOrganization(actionReport?.individualProjectReports, org.organizationName) || 0
    const totalGivers = actionReport?.totalIndividualsPerEntity?.[org.id] || 0

    return {
      entity: org.organizationName,
      projects: org.countProjects,
      hours: org.totalHours,
      costValue: org.cost.toLocaleString(),
      averageGeneralAssessment: org.generalAssessment,
      averageFirmEngagement: org.firmEngagement,
      active: isActive,
      waitingList: org.waitList,
      patrono: org.inhouse,
      coveredPlace: org.countParticipants,
      strategicAxis: org.strategicAxis,
      directCost: org.directCost.toLocaleString(),
      donationToEntity: org.donationToEntity.toLocaleString(),
      individualGivers: individualGivers,
      totalGivers: totalGivers,
      directBeneficiaries: org.directBeneficiaries,
      indirectBeneficiaries: org.indirectBeneficiaries
    };
  }).sort((a, b) => a.entity.localeCompare(b.entity));

  function calculateTotals(data) {
    let totalPatrono = 0
    let totalNoPatrono = 0
    let totalCoveredPlacePatrono = 0
    let totalWaitingListPatrono = 0
    let totalCoveredPlaceNoPatrono = 0
    let totalWaitingListNoPatrono = 0
    let totalAverageGeneralAssessmentPatrono = 0
    let totalAverageGeneralAssessmentNoPatrono = 0

    data.forEach(entity => {
      if (entity.patrono) {
        totalPatrono++
        totalCoveredPlacePatrono += entity.coveredPlace
        totalWaitingListPatrono += entity.waitingList
        totalAverageGeneralAssessmentPatrono += entity.averageGeneralAssessment
      } else {
        totalNoPatrono++
        totalCoveredPlaceNoPatrono += entity.coveredPlace
        totalWaitingListNoPatrono += entity.waitingList
        totalAverageGeneralAssessmentNoPatrono += entity.averageGeneralAssessment
      }
    })

    totalAverageGeneralAssessmentPatrono = totalAverageGeneralAssessmentPatrono === 0 ? 0 : totalAverageGeneralAssessmentPatrono / totalPatrono
    totalAverageGeneralAssessmentNoPatrono = totalAverageGeneralAssessmentNoPatrono === 0 ? 0 : totalAverageGeneralAssessmentNoPatrono / totalNoPatrono

    // Devolviendo los resultados
    return {
      totalPatrono,
      totalNoPatrono,
      totalCoveredPlacePatrono,
      totalWaitingListPatrono,
      totalCoveredPlaceNoPatrono,
      totalWaitingListNoPatrono,
      totalAverageGeneralAssessmentPatrono,
      totalAverageGeneralAssessmentNoPatrono
    }
  }

  const total = calculateTotals(dataEntities)
  if (entity === null && dataEntities.length !== 0) {
    setEntity(dataEntities[0].entity)
  }


  return (
    <>
      <section className="organization-reports">
        {<header className="header">
          <h1 className="motif-h1">{labels?.console_organizations}</h1>
        </header>}
        <section>
          {isLoading ? <SkeletonTable /> :
          <div style={{justifyContent: 'space-evenly', display: 'grid'}}> 
            <MotifFormField className="select">
              <MotifLabel id="select-label-search-people">Entity:</MotifLabel>
              <MotifSelect
                value={entity}
                onChange={selectedEntity => setEntity(selectedEntity)}
                filter
                ariaLabelledBy="select-label-search-people"
              >
                {[...new Set(dataEntities.map(item => item.entity))].map((entityName, key) =>
                  <MotifOption key={key} value={entityName}>{entityName}</MotifOption>
                )}
              </MotifSelect>
            </MotifFormField>
            <MotifCard>
              <MotifCardHeader>
                <div>
                  <MotifIcon src={communicationIcBusiness24px} /> 
                  <span className="motif-h4" style={{ color: 'white'}}>{getEntityInfo(dataEntities, entity, 'entity')}</span>
                </div>
              </MotifCardHeader>
              <MotifCardBody>
                <div>
                  <div className="entity-patrono">
                    <div>
                      <p className="motif-h5">{labels.console_patrono}</p>
                      <MotifIcon src={getEntityInfo(dataEntities, entity, 'patrono')[0] ? actionIcCheckCircle24px : navigationIcCancel24px} style={{ color: '#ffbf1e' }} />
                    </div>
                    <div>
                      <p className="motif-h5">{labels.console_active}</p>
                      <MotifIcon src={getEntityInfo(dataEntities, entity, 'active')[0] ? actionIcCheckCircle24px : navigationIcCancel24px} style={{ color: '#ffbf1e' }} />
                    </div>
                  </div>
                  <div className="entity-info">
                    <div className="info-left">
                      <p className="motif-h5">{labels.console_user_projects}</p>
                      <span
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                          marginTop: '15px'
                        }}
                      >
                        <MotifIcon src={placesIcBusinessCenter24px} style={{ color: '#ffbf1e' }} />
                        <MotifBadge value={getEntityInfo(dataEntities, entity, 'projects')} overlap={true} />
                      </span>
                    </div>
                    <div className="info-right">
                      <p className="motif-h5">{labels.console_strategic_axis}</p>
                      <span style={{display: 'inline-block', marginTop: '15px'}}>
                        {getEntityInfo(dataEntities, entity, 'strategicAxis').join()}
                      </span>
                    </div>
                  </div>
                  <div className="entity-info">
                    <div className="info-left">
                      <p className="motif-h5">{labels.console_direct_beneficiaries}</p>
                      <span style={{display: 'inline-block', marginTop: '15px'}}>
                        {getEntityInfo(dataEntities, entity, 'directBeneficiaries')}
                      </span>
                    </div>
                    <div className="info-right">
                      <p className="motif-h5">{labels.console_indirect_beneficiaries}</p>
                      <span style={{display: 'inline-block', marginTop: '15px'}}>
                        {getEntityInfo(dataEntities, entity, 'indirectBeneficiaries')}
                      </span>
                    </div>
                  </div>
                  <div className="entity-info">
                    <div className="info-left">
                      <p className="motif-h5">{labels.console_individual_givers}</p>
                      <span
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                          marginTop: '15px'
                        }}
                      >
                        <MotifIcon src={socialIcPerson24px} style={{ color: '#ffbf1e' }} />
                        <MotifBadge value={getEntityInfo(dataEntities, entity, 'totalGivers')} overlap={true} />
                      </span>
                    </div>
                    <div className="info-right">
                      <p className="motif-h5">{labels.console_total_givers}</p>
                      <span
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                          marginTop: '15px'
                        }}
                      >
                        <MotifIcon src={socialIcGroup24px} style={{ color: '#ffbf1e' }} />
                        <MotifBadge value={getEntityInfo(dataEntities, entity, 'individualGivers')} overlap={true} />
                      </span>
                    </div>
                  </div>
                  <div className="entity-info">
                    <div className="info-left">
                      <p className="motif-h5">{labels.console_direct_cost}</p>
                      <span
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                          marginTop: '15px'
                        }}
                      >
                        <MotifIcon src={editorIcAttachMoney24px } style={{ color: '#ffbf1e' }} />
                        {getEntityInfo(dataEntities, entity, 'directCost')}
                      </span>
                    </div>
                    <div className="info-right">
                      <p className="motif-h5">{labels.console_donation_entity}</p>
                      <span
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                          marginTop: '15px'
                        }}
                      >
                        <MotifIcon src={editorIcAttachMoney24px } style={{ color: '#ffbf1e' }} />
                        {getEntityInfo(dataEntities, entity, 'donationToEntity')}
                      </span>
                    </div>
                  </div>
                  <div className="entity-info">
                    <div className="info-left">
                      <p className="motif-h5">{labels.console_cost}</p>
                      <span
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                          marginTop: '15px'
                        }}
                      >
                        <MotifIcon src={editorIcAttachMoney24px} style={{ color: '#ffbf1e' }} />
                        {getEntityInfo(dataEntities, entity, 'costValue')}
                      </span>
                    </div>
                    <div className="info-right">
                      <p className="motif-h5">{labels.console_hours}</p>
                      <span
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                          marginTop: '15px'
                        }}
                      >
                        <MotifIcon src={deviceIcAccessTime24px} style={{ color: '#ffbf1e' }} />
                        {getEntityInfo(dataEntities, entity, 'hours')}
                      </span>
                    </div>
                  </div>
                  <div className="entity-info">
                    <div className="info-left">
                      <p className="motif-h5">{labels.console_general_assessment}</p>
                      <div style={{ marginTop: '15px' }}>
                        {getEntityInfo(dataEntities, entity, 'averageGeneralAssessment')[0] !== 0 && renderStarsIcons(getEntityInfo(dataEntities, entity, 'averageGeneralAssessment')[0], 'full')}

                        {!Number.isInteger(getEntityInfo(dataEntities, entity, 'averageGeneralAssessment')[0]) &&
                          <MotifIcon
                            src={toggleIcStarHalf24px}
                            style={{ color: '#ffbf1e' }} >
                          </MotifIcon >}

                        {renderStarsIcons(getEntityInfo(dataEntities, entity, 'averageGeneralAssessment')[0], 'empty')}
                      </div>
                    </div>
                    <div className="info-right">
                      <p className="motif-h5">{labels.console_firm_engagement}</p>
                      <div style={{ marginTop: '15px' }}>
                        {getEntityInfo(dataEntities, entity, 'averageFirmEngagement')[0] !== 0 && renderStarsIcons(getEntityInfo(dataEntities, entity, 'averageFirmEngagement')[0], 'full')}

                        {!Number.isInteger(getEntityInfo(dataEntities, entity, 'averageFirmEngagement')[0]) &&
                          <MotifIcon
                            src={toggleIcStarHalf24px}
                            style={{ color: '#ffbf1e' }} >
                          </MotifIcon >}

                        {renderStarsIcons(getEntityInfo(dataEntities, entity, 'averageFirmEngagement')[0], 'empty')}
                      </div>
                    </div>
                  </div>
                </div>
              </MotifCardBody>
            </MotifCard>
          </div> 
          }
        </section>
        <section className="summary">
          <div className="top-content">
            <div className="title-box">
              <span className='title-wrap'>
                {labels.console_total_patrono}: <span className="total">{total.totalPatrono}</span>
              </span>
            </div>
            <div className="icon-box-inverted">
              <span>
                <MotifIcon src={communicationIcBusiness24px} />
              </span>
            </div>
          </div>
          <div className="grid-summary">
            <SmallReportBox
              icon={actionIcAssessment24px}
              title={labels.console_evaluation}
              content={isLoading ? <MotifProgressBar isIndeterminate circle hideLabel /> : 
                <div style={{ alignSelf: 'center', display: 'flex', alignItems: 'center' }}>
                  {total.totalAverageGeneralAssessmentPatrono !== 0 && renderStarsIcons(total.totalAverageGeneralAssessmentPatrono, 'full')}

                  {!Number.isInteger(total.totalAverageGeneralAssessmentPatrono) &&
                    <MotifIcon
                      src={toggleIcStarHalf24px}
                      style={{ color: '#ffbf1e' }} >
                    </MotifIcon >}

                  {renderStarsIcons(total.totalAverageGeneralAssessmentPatrono, 'empty')}
                </div>
              }
            />
            <SmallReportBox
              icon={actionIcEventSeat24px}
              title={labels.console_covered_places}
              content={isLoading ? <MotifProgressBar isIndeterminate circle hideLabel /> : total.totalCoveredPlacePatrono}
            />
            <SmallReportBox
              icon={socialIcPersonAdd24px}
              title={labels.console_waiting_list}
              content={isLoading ? <MotifProgressBar isIndeterminate circle hideLabel /> : total.totalWaitingListPatrono}
            />
          </div>
        </section>
        <section className="summary">
          <div className="top-content">
            <div className="title-box">
              <span className='title-wrap'>
                {labels.console_total_no_patrono}: <span className="total">{total.totalNoPatrono}</span>
              </span>
            </div>
            <div className="icon-box-inverted">
              <span>
                <MotifIcon src={communicationIcBusiness24px} />
              </span>
            </div>
          </div>
          <div className="grid-summary">
            <SmallReportBox
              icon={actionIcAssessment24px}
              title={labels.console_evaluation}
              content={isLoading ? <MotifProgressBar isIndeterminate circle hideLabel /> : 
                <div style={{ alignSelf: 'center', display: 'flex', alignItems: 'center' }}>
                  {total.totalAverageGeneralAssessmentNoPatrono !== 0 && renderStarsIcons(total.totalAverageGeneralAssessmentNoPatrono, 'full')}

                  {!Number.isInteger(total.totalAverageGeneralAssessmentNoPatrono) &&
                    <MotifIcon
                      src={toggleIcStarHalf24px}
                      style={{ color: '#ffbf1e' }} >
                    </MotifIcon >}

                  {renderStarsIcons(total.totalAverageGeneralAssessmentNoPatrono, 'empty')}
                </div>
              }
            />
            <SmallReportBox
              icon={actionIcEventSeat24px}
              title={labels.console_covered_places}
              content={isLoading ? <MotifProgressBar isIndeterminate circle hideLabel /> : total.totalCoveredPlaceNoPatrono}
            />
            <SmallReportBox
              icon={socialIcPersonAdd24px}
              title={labels.console_waiting_list}
              content={isLoading ? <MotifProgressBar isIndeterminate circle hideLabel /> : total.totalWaitingListNoPatrono}
            />
          </div>
        </section>
      </section>
    </>
  );
};

export default GiveyrsReports;