import SecondaryMenu from "../../components/layout/SecondaryMenu";
import ProbonosFields from "../../components/form/molecules/ProbonosFields";
import ContactFields from "../../components/form/molecules/ContactFields";
import Modal from "../../components/Modal";

import {
  nanoid,
  isoDate,
  deleteEmptyFields,
  validateEmail,
} from "../../globals";

import { MotifButton } from "@ey-xd/motif-react";

import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Comments from "../../components/form/molecules/Comments";
import { fetchCountriesData, postProbonoData } from "../../services/api";
import LabelsContext from "../../context/LabelsContext";

import "../../assets/css/pages/new-activity.scss";
import InvalidModal from "../../components/InvalidModal";

const NewProBono = () => {
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [showInvalidModal, setShowInvalidModal] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const labels = useContext(LabelsContext);

  const [formData, setFormData] = useState({
    title: "",
    subTitle: "",
    description: "",
    team: "",
    startDate: null,
    endDate: null,
    investmentType: "",
    moneyInvested: null,
    engagement: "",
    contactName: "",
    contactEmail: "",
    fiscalYear: "",
    comments: "",
    country: null,
  });

  const [numericErrors, setNumericErrors] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNumericErrors = (errors) => {
    setNumericErrors(errors);
  };

  const handleFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleFormSubmit = async (drafted) => {
    setisFormSubmited(true);
    if (isFormValid()) {
      setIsDisabledButton(true);
      const probonusId = nanoid();
      const submitData = {
        ...formData,
        startDate: formData.startDate ? isoDate(formData.startDate) : null,
        endDate: formData.endDate ? isoDate(formData.endDate) : null,
        contact: {
          name: formData.contactName,
          email: formData.contactEmail,
        },
        probonusId,
        isDraft: drafted,
        comments: formData.comments === "<p><br></p>" ? "" : formData.comments
      };
      await postProbonoData(deleteEmptyFields(submitData));
      navigate("/actions/all-probonos");
      setIsDisabledButton(false);
    } else {
      setShowInvalidModal(true);
      setIsDisabledButton(false);
    }
  };

  const isFormValid = () => {
    const requiredFields = [
      "title",
      "description",
      "investmentType",
      "contactName",
      "contactEmail",
      "country",
    ];
    const newMissingFields = [];
    requiredFields.forEach((field) => {
      if (!formData[field] || (field === 'description' && formData[field] === '<p><br></p>')) {
        newMissingFields.push(field);
      }
    });
    
    if (!validateEmail(formData.contactEmail)) {
      newMissingFields.push('contactEmail');
      setMissingFields(newMissingFields);
      return false;
    }
    const areDatesValid =
      formData.startDate instanceof Date &&
      !isNaN(formData.startDate) &&
      formData.endDate instanceof Date &&
      !isNaN(formData.endDate);
      if (!areDatesValid) {
        newMissingFields.push('startDate', 'endDate');
      }
    const hasNumericErrors = Object.values(numericErrors).some(
      (error) => error
    );
    setMissingFields(newMissingFields);
    return newMissingFields.length === 0 && areDatesValid && !hasNumericErrors;
  };

  const [isFormSubmited, setisFormSubmited] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchCountriesData().then((data) => {
      const countries = Array.isArray(data)
        ? data.map((country) => ({
            value: country.countryName,
            label: country.countryName,
          }))
        : [];
      setCountryOptions(countries);
    });
  }, []);
  const closeWarningModal = () => {
    setShowModal(false);
  };
  const closeInvalidModal = () => {
    setShowModal(false);
    setShowInvalidModal(false);
  };
  const handleDiscardChanges = () => {
    window.scrollTo(0, 0);
    setShowModal(false);
    navigate("/");
  };
  const openWarningModal = () => {
    setActionType("save");
    setShowModal(true);
  };
  const handleNavigateHome = () => {
    navigate("/actions/all-probonos");
  };

  const handleCancelClick = () => {
    setActionType("cancel");
    setShowModal(true);
  };

  return (
    <>
      <SecondaryMenu
        title={labels.console_create_probonos}
        onNavigate={handleNavigateHome}
        editing={true}
        sendFormDraft={() => handleFormSubmit(true)}
        pageType="probonos"
      />
      <section className="motif-col-lg-12 section-page">
        <ProbonosFields
          formData={formData}
          setFormData={setFormData}
          isFormSubmited={isFormSubmited}
          countryOptions={countryOptions}
          onNumericErrors={handleNumericErrors}
          fieldVisibility={{
            title: true,
            subTitle: true,
            description: true,
            team: true,
            investmentType: true,
            startDate: true,
            endDate: true,
            moneyInvested: true,
            engagement: true,
            fiscalYear: true,
            country: true,
          }}
        />

        <ContactFields
          onFieldChange={handleFieldChange}
          formData={formData}
          setFormData={setFormData}
          isFormSubmited={isFormSubmited}
          labels={labels}
          fieldVisibility={{
            rolOrg: false,
          }}
        />

        <Comments
          onFieldChange={handleFieldChange}
          formData={formData}
          setFormData={setFormData}
          isFormSubmited={isFormSubmited}
          required={false}
        />

        <div className="activity-buttons">
          <MotifButton
            variant="secondary"
            onClick={handleCancelClick}
            className="activity-buttons-secondary"
          >
            {labels.console_cancel}
          </MotifButton>
          <MotifButton
            onClick={openWarningModal}
            className="activity-buttons-primary"
          >
            {labels.console_save}
          </MotifButton>
        </div>
      </section>

      <Modal
        show={showModal}
        onClose={closeWarningModal}
        title={
          actionType === "save"
            ? labels.console_changes_saved
            : labels.console_unsavedChanges
        }
        body={
          actionType === "save"
            ? labels.console_confirm_publish_probono
            : labels.console_leavePage
        }
        primaryButtonText={
          actionType === "save"
            ? labels.console_publish
            : labels.console_keepEditing
        }
        onPrimaryButtonClick={
          actionType === "save"
            ? () => handleFormSubmit(false)
            : closeWarningModal
        }
        secondaryButtonText={
          actionType === "save"
            ? labels.console_cancel
            : labels.console_discardChanges
        }
        onSecondaryButtonClick={handleDiscardChanges}
        disabled={isDisabledButton}
      />
      <InvalidModal
        show={showInvalidModal}
        onClose={closeInvalidModal}
        title={labels.console_title_invalidForm}
        body={labels.console_body_invalidForm}
        primaryButtonText={labels.console_close}
        onPrimaryButtonClick={closeInvalidModal}
        invalidFieldsMessage={missingFields.join(", ")}
      />
    </>
  );
};

export default NewProBono;
