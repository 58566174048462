import {
  MotifFormField,
  MotifLabel,
  MotifInput,
  MotifErrorMessage,
} from "@ey-xd/motif-react";

import "../../../assets/css/components/TextInput.scss";

const TextInput = ({
  label,
  placeholder,
  value,
  onChange,
  required = false,
  error = false,
  errorMessage,
  type = "text",
  disabled,
  correctAnswer,
}) => {
  return (
    <div>
      {label && (
        <label className="input-label">
          {label} {required && <span className="input-label-required">*</span>}
        </label>
      )}
      <MotifFormField>
        <MotifLabel>{placeholder}</MotifLabel>
        <MotifInput
          type={type}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          className={correctAnswer ? "correct-answer" : "input-box"}
          disabled={disabled}
        />
        {error && <MotifErrorMessage>{errorMessage}</MotifErrorMessage>}
      </MotifFormField>
    </div>
  );
};

export default TextInput;
