import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import NumericInput from "./NumericInput";
import RadioButton from "./RadioButton";
import MultipleSelect from "./MultipleSelect";
import LabelsContext from "../../../context/LabelsContext";

import {
  MotifFormField,
  MotifLabel,
  MotifDatePicker,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import { useState, useContext } from "react";

const Details = ({
  formData,
  setFormData,
  isFormSubmited,
  collectiveOptions,
  categoryListOrganizations,
  isDisabled,
  fieldVisibility = {
    points: true,
    title: true,
    fiscalYear: true,
    attendance: true,
    deadLineDate: true,
    collective: true,
    categories: true,
    scope: true,
    bridgeApproved: true,
    inhouse: true,
    initiative: true,
    engagementCode: true,
    cost: true,
    aimedAmount: true,
    directCost: true,
    donationToEntity: true,
    minParticipants: true,
    thirdParty: true,
  },
  organizationId,
  pageType,
  isEditing,
  isPreview,
}) => {
  const [showAdditionalField, setShowAdditionalField] = useState(false);
  const labels = useContext(LabelsContext);
  const handleChange = (fieldName, value) => {
    if (fieldName === "organizationId" && typeof value !== "object") {
      // Find the corresponding organization object based on the name
      const matchedOrganization = organizationId.find(
        (org) => org.name === value
      );
      if (matchedOrganization) {
        value = matchedOrganization.id; // Set the value to the ID of the matched organization
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: Array.isArray(prevData[fieldName])
        ? [...prevData[fieldName], value]
        : value,
    }));
    if (fieldName === "initiative" && value === "OTHER") {
      setShowAdditionalField(true);
    } else if (fieldName === "initiative") {
      setShowAdditionalField(false);
    }
  };
  const showThirdParty = formData.initiative === "OTHER";

  const handleCategoryChange = (selectedCategories) => {
    setFormData((prev) => ({ ...prev, categories: selectedCategories }));
  };
  const handleScopeChange = (selectedScope) => {
    setFormData((prev) => ({ ...prev, scope: selectedScope }));
  };
  const handleCollectiveChange = (selectedCollective) => {
    setFormData((prev) => ({ ...prev, collective: selectedCollective }));
  };

  const isTitleValid = Boolean(formData.title);

  const inhouse = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const scope = [
    { value: "local", label: "Local" },
    { value: "nacional", label: "National" },
    { value: "internacional", label: "International" },
  ];

  const attendance = [
    { value: "ONLINE", label: "Virtual" },
    { value: "INPERSON", label: "Presencial" },
  ];

  const initiative = [
    { value: "OTHER", label: "Terceros" },
    { value: "EY", label: "EY" },
  ];
  const donation = [
    { value: "FOOD", label: "Food" },
    { value: "TOYS", label: "Toys" },
    { value: "MONEY", label: "Money" },
    { value: "OTHERS", label: "Others" },
    { value: "CLOTHES", label: "Clothes" },
    { value: "HEALTH", label: "Health" },
  ];
  return (
    <>
      {fieldVisibility.points && (
        <NumericInput
          label={labels.console_giveyrs_points}
          placeholder={labels.console_giveyrs_points}
          value={formData.totalVolunteerHours * 100}
          onChange={(val) => handleChange("points", val)}
          required={true}
          errorMessage={labels.console_error_points}
          validateNonNegative={true}
          isDisabled={true}
        />
      )}

      {fieldVisibility.title && (
        <TextInput
          label={labels.console_table_title}
          placeholder={labels.console_enter_title}
          value={formData.title}
          onChange={(val) => handleChange("title", val)}
          required={true}
          error={isFormSubmited && !isTitleValid}
          errorMessage={labels.console_enter_title_error}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.donationType && (
        <SelectInput
          label={labels.console_donation_type}
          value={formData.donationType}
          onChange={(val) => handleChange("donationType", val)}
          options={donation}
          required
          error={!formData.donationType && isFormSubmited}
          errorMessage={labels.console_select_donation_error}
          disabled={isDisabled}
          filter
        />
      )}

      {fieldVisibility.aimedAmount && (
        <NumericInput
          label={labels.console_amount_tobe_donated}
          placeholder={labels.console_amount_tobe_donated}
          value={formData.aimedAmount}
          onChange={(val) => handleChange("aimedAmount", val)}
          required={false}
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.miniumAmount && (
        <NumericInput
          label={labels.console_minimum_amount}
          placeholder={labels.console_enter_minimum_amount}
          value={formData.minimumAmount}
          onChange={(val) => handleChange("minimumAmount", val)}
          required={false}
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.cost && (
        <NumericInput
          label={labels.console_expenses_asociated}
          placeholder={labels.console_enter_expenses_asociated}
          value={formData.cost}
          onChange={(val) => handleChange("cost", val)}
          required={false}
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.organizationId && (
        <SelectInput
          label={labels.console_table_organization}
          value={formData.organizationId}
          onChange={(val) => handleChange("organizationId", val)}
          options={
            Array.isArray(organizationId)
              ? organizationId.map((org) => ({
                  value: org.id,
                  label: org.name,
                }))
              : []
          }
          required={true}
          error={isFormSubmited && !formData.organizationId}
          errorMessage={labels.console_select_org_error}
          disabled={isDisabled}
          country={true}
          filter
        />
      )}

      {fieldVisibility.fiscalYear && (
        <NumericInput
          label={labels.console_fiscal_year}
          placeholder={labels.console_enter_fy}
          value={formData.fiscalYear}
          onChange={(val) => handleChange("fiscalYear", val)}
          required={false}
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.engagementCode && (
        <TextInput
          label={labels.console_engagement_code}
          placeholder={labels.console_enter_engagement_code}
          value={formData.engagementCode}
          onChange={(val) => handleChange("engagementCode", val)}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.maxParticipants && (
        <NumericInput
          label={labels.console_max_participants}
          placeholder="Enter maximum participants"
          value={formData.maxParticipants}
          onChange={(val) => handleChange("maxParticipants", val)}
          error={isFormSubmited && !formData.maxParticipants}
          errorMessage="Oops, please enter a fiscal year!"
          required={true}
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.minParticipants && (
        <NumericInput
          label={labels.console_required_volunteers}
          placeholder="Enter a required volunteers"
          value={formData.minParticipants}
          onChange={(val) => handleChange("minParticipants", val)}
          error={isFormSubmited && !formData.minParticipants}
          errorMessage="Oops, please enter a number!"
          required={true}
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.directBeneficiaries && (
        <NumericInput
          label={labels.console_direct_benefit}
          placeholder="Enter a direct beneficiaries"
          value={formData.directBeneficiaries}
          onChange={(val) => handleChange("directBeneficiaries", val)}
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.indirectBeneficiaries && (
        <NumericInput
          label={labels.console_indirect_benefit}
          placeholder="Enter a indirect beneficiaries"
          value={formData.indirectBeneficiaries}
          onChange={(val) => handleChange("indirectBeneficiaries", val)}
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.directCost && (
        <NumericInput
          label={labels.console_direct_spent}
          placeholder="Enter a number"
          value={formData.directCost}
          onChange={(val) => handleChange("directCost", val)}
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.donationToEntity && (
        <NumericInput
          label={labels.console_donation_entity}
          placeholder="Enter a number"
          value={formData.donationToEntity}
          onChange={(val) => handleChange("donationToEntity", val)}
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.initiative && (
        <SelectInput
          label={labels.console_initiative}
          value={formData.initiative}
          onChange={(val) => handleChange("initiative", val)}
          options={initiative}
          disabled={isDisabled}
          filter
        />
      )}

      {(showAdditionalField || showThirdParty) && (
        <TextInput
          label={labels.console_led_action}
          placeholder="Enter additional info"
          value={formData.thirdParty}
          onChange={(val) => handleChange("thirdParty", val)}
          disabled={isDisabled}
          required={true}
          error={isFormSubmited && !formData.thirdParty}
          errorMessage="Oops, please enter a third party!"
        />
      )}

      {fieldVisibility.attendance && (
        <SelectInput
          label={labels.console_select_attendance}
          value={formData.attendance}
          onChange={(val) => handleChange("attendance", val)}
          options={attendance}
          required={true}
          error={isFormSubmited && !formData.attendance}
          errorMessage="Oops, please select a attendance!"
          disabled={isDisabled}
          filter
        />
      )}

      {fieldVisibility.deadLineDate && (
        <MotifFormField className="form-field">
          <MotifLabel className="input-label">
            {labels.console_deadline}:{" "}
            <span className="input-label-required">*</span>
          </MotifLabel>
          <MotifDatePicker
            value={formData.deadLineDate}
            onChange={(val) => handleChange("deadLineDate", val)}
            showLeadingZeros={true}
            format="dd-MM-yyyy"
            yearPlaceholder="YYYY"
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            minDate={
              pageType !== "donations"
                ? isPreview
                  ? new Date(new Date().setHours(0, 0, 0, 0)) >
                      formData?.deadLineDate &&
                    isEditing &&
                    isDisabled
                    ? new Date(new Date().setHours(0, 0, 0, 0))
                    : formData?.deadLineDate
                  : new Date(new Date().setHours(0, 0, 0, 0))
                : formData.startDate
            }
            maxDate={
              pageType !== "donations" ? formData.startDate : formData.endDate
            }
            disabled={
              isDisabled ||
              (new Date(new Date().setHours(0, 0, 0, 0)) >
                formData?.deadLineDate &&
                isEditing)
            }
          />
          {isFormSubmited && !formData.deadLineDate && (
            <MotifErrorMessage>Oops, please pick a deadline!</MotifErrorMessage>
          )}
        </MotifFormField>
      )}

      {fieldVisibility.collective && (
        <MultipleSelect
          label={labels.console_collective}
          value={formData.collective}
          onChange={handleCollectiveChange}
          options={Array.isArray(collectiveOptions) ? collectiveOptions : []}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.categories && (
        <MultipleSelect
          label={labels.console_categories}
          value={formData.categories}
          onChange={handleCategoryChange}
          options={categoryListOrganizations}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.scope && (
        <MultipleSelect
          label={labels.console_scope}
          value={formData.scope}
          onChange={handleScopeChange}
          options={scope}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.bridgeApproved && (
        <RadioButton
          label={labels.console_bridge_approved}
          value={formData.bridgeApproved}
          onChange={(val) => handleChange("bridgeApproved", val)}
          isDisabled={isDisabled}
        />
      )}

      {fieldVisibility.inhouse && (
        <SelectInput
          label="Patronato"
          value={formData.inhouse}
          onChange={(val) => handleChange("inhouse", val)}
          options={inhouse}
          disabled={isDisabled}
          filter
        />
      )}
    </>
  );
};

export default Details;
