
import { useState, useEffect } from 'react';
import LabelsContext from './LabelsContext';
import { getLabels } from '../services/api';
import Cookies from 'js-cookie';

const LabelsProvider = ({ children }) => {
  const [labels, setLabels] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const language = sessionStorage.getItem('language') || 'en'
  const token = Cookies.get('token');
  useEffect(() => {
    const fetchLabels = async () => {
      try {
        if (token) {
          const data = await getLabels(language);
          setLabels(data.labelList);
          setLoading(false);
        } else {
          setLoading(true)
        }
      } catch (error) {
        console.error("Error fetching labels:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchLabels();
  }, [language, token]);

  if (loading) {
    return <span class="loader"></span>
  }

  if (error) {
    return <div> <img src="../../eylogo.ico" alt="ey-logo" className="icon-ey-giveyrs" /> </div>
  }

  return (
    <LabelsContext.Provider value={labels}>
      {children}
    </LabelsContext.Provider>
  );
};


export default LabelsProvider;
