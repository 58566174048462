import { useState, useCallback, useEffect, useContext } from "react";
import {
  MotifTable,
  MotifTableHeaderRenderer,
  MotifTableCellRenderer,
} from "@ey-xd/motif-react";
import LabelsContext from "../context/LabelsContext";

import SearchForTables from "../components/layout/SearchForTables";
import SkeletonTable from "../components/layout/SkeletonTable";

import "../assets/css/pages/all-volunteers.scss";
import UserModalInfo from "../components/form/molecules/UserModalInfo.jsx";
import { fetchAllUsers, findUser, fetchActions } from "../services/api.js";
import { getVolunteersReport } from "../services/api/apiReports";

let gridApi;

const AllVolunteers = () => {
  // eslint-disable-next-line no-unused-vars
  const labels = useContext(LabelsContext);

  const [volunteersData, setVolunteersData] = useState([]);
  const [actions, setActions] = useState([]);
  const [isModalVisible1, setModalVisibility1] = useState(false);
  const [checked, setChecked] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [pageSize] = useState("10");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAllUsers();
        setVolunteersData(data.users);
      } catch (error) {
        console.error("Error fetching the events data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    const fetchActionsData = async () => {
      try {
        const actionsData = await fetchActions(false);
        setActions(actionsData);
      } catch (error) {
        console.error("Error fetching the actions data", error);
      }
    };
    fetchActionsData();
  }, []);

  const handleGridReady = (params) => {
    ({ api: gridApi } = params);
  };

  const columnDefs = [
    {
      headerName: "",
      cellRenderer: "motifTableCellRenderer",
      field: "image",
      sortable: true,
      width: 60,
      minWidth: 60,
      filter: true,
      pinned: "left",
      flex: 1,

    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Surname",
      field: "surname",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Office",
      field: "office",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Rank",
      field: "jobTitle",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: true,
      hide: true,
      flex: 1,
    },
    {
      headerName: "Mail",
      field: "mail",
      sortable: true,
      filter: true,
      hide: true,
      flex: 1,
    },
    {
      headerName: "Profile Image",
      field: "profileImage",
      sortable: true,
      filter: true,
      hide: true,
      flex: 1,
    },
  ];
  const rowData = volunteersData.map((voluntee) => ({
    image: (
      <>
        <img
          width={50}
          height={50}
          src={
            voluntee.image ||
            "https://www.freeiconspng.com/thumbs/profile-icon-png/profile-icon-9.png"
          }
          alt=""
        />
      </>
    ),
    name: voluntee.name,
    surname: voluntee.surname,
    country: voluntee.country,
    office: voluntee.office,
    jobTitle: voluntee.jobTitle,
    status: voluntee.status,
    mail: voluntee.mail,
    profileImage: voluntee.image,
    serviceLine: voluntee.serviceLine,
    userId: voluntee.userId,
  }));

  const filteredLandingsData = rowData.filter((voluntee) => {
    const includesSearchValue =
      voluntee.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      voluntee.surname.toLowerCase().includes(searchValue.toLowerCase()) ||
      voluntee.office.toLowerCase().includes(searchValue.toLowerCase()) ||
      voluntee.jobTitle.toLowerCase().includes(searchValue.toLowerCase());

    if (checked) {
      return includesSearchValue && voluntee.status === "INACTIVE";
    } else {
      return includesSearchValue;
    }
  });
  const onRowSelected = useCallback(async (user) => {
    const userEmail = user.node.data.mail;
    const encodedEmail = decodeURIComponent(userEmail);
    const additionalUserInfo = await findUser(encodedEmail);

    const report = await getVolunteersReport();
    const userReport = report.volunteerReports.find(
      (report) => report.email.toLowerCase() === userEmail.toLowerCase()
    );

    setUserInfo({
      ...user.node.data,
      ...additionalUserInfo,
      totalHours: userReport.totalHours.toString(),
      projectsCompleted: userReport.projectsCompleted.toString(),
    });
    setModalVisibility1(true);
  }, []);

  const rowClassRules = {
    "inactive-user": function (params) {
      return params.data.status === "INACTIVE";
    },
  };
  const onToggleSwitchChange = () => {
    setChecked(!checked);
  };
  const closeModal = () => {
    setModalVisibility1(false);
  };
  const inactiveVolunteers = (status) => {
    return filteredLandingsData.filter((voluntee) => voluntee.status === status)
      .length;
  };

  return (
    <>
      <section className="all-organizations-page motif-col-lg-12">
        <div className="organizations-info">
          <span className="title motif-h2">{"Volunteers"}</span>
          <div>
            <span className="number-total-organizations">
              {volunteersData.length}
            </span>
            <span className="total-organizations">
              {labels.console_total_volunteers}
            </span>
          </div>
          <div>
            <span className="number-total-organizations">
              {checked
                ? inactiveVolunteers("INACTIVE")
                : inactiveVolunteers("ACTIVE")}
            </span>
            <span className="total-organizations">
              {checked
                ? "Total inactive volunteers"
                : "Total active volunteers"}
            </span>
          </div>
        </div>
        <SearchForTables
          onSearchChange={(value) => setSearchValue(value)}
          pageType="volunteers"
          showAddButton={false}
          checked={checked}
          onToggleSwitchChange={onToggleSwitchChange}
          showSwitchButton={true}
        />
        {isLoading ? (
          <SkeletonTable />
        ) : (
          <section className="organizations-table">
            <MotifTable
              defaultColDef={{
                headerCheckboxSelection: false,
                checkboxSelection: false,
              }}
              firstColumnBorderRight
              columnDefs={columnDefs}
              rowData={filteredLandingsData}
              onGridReady={handleGridReady}
              suppressCellFocus
              onSortChanged={() => gridApi.redrawRows()}
              components={{
                agColumnHeader: MotifTableHeaderRenderer,
                motifTableCellRenderer: MotifTableCellRenderer,
              }}
              paginationPageSize={pageSize}
              pagination
              rowSelection={"single"}
              onRowSelected={onRowSelected}
              rowClassRules={rowClassRules}
              suppressNoRowsOverlay={true}
            />
          </section>
        )}

        <UserModalInfo
          show={isModalVisible1}
          userInfo={userInfo}
          key={userInfo.mail}
          onClose={closeModal}
          labels={labels}
          actions={actions}
        />
      </section>
    </>
  );
};
export default AllVolunteers;
