import React from 'react';
const SkeletonTable = () => (
  <>
	<div className="skeleton-container">
	  <div className="column motif-skeleton-container">
	    <span className="motif-skeleton-12" />
	    <span className="motif-skeleton-12" />
	    <span className="motif-skeleton-12" />
	    <span className="motif-skeleton-12" />
	    <span className="motif-skeleton-12" />
	    <span className="motif-skeleton-12" />
	  </div>
	</div>
	<style>{`
	  .skeleton-container {
	    width: auto;
	  }
	  .skeleton-container .column {
	    --flex-direction: column;
	    --align-items: flex-start;
	    --justify-content: space-around;
	    height: 283px;
	    padding: 14px;
        background-color: #F3F3F3;
        border-color: #F3F3F3;
	  }
	`}</style>
  </>
);
export default SkeletonTable;