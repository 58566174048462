import "../assets/css/components/QuickActionCard.scss";

import { Link } from "react-router-dom";

const QuickActions = ({ title, path }) => {
  return (
    <div className="quick-action-card">
      <div className="top">
        <img
          src="https://www.abi.org.uk/globalassets/images/content/subjects/voluntary-organisations/voluntaryorganisations620x300.jpg?width=1600&height=1100&mode=max"
          alt=""
        />
      </div>
      <div className="content">
        <Link to={path}>
          <div className="plus-sign">
            <div className="line horizontal"></div>
            <div className="line vertical"></div>
          </div>
        </Link>
        <div className="description">
          <h1 className="motif-h1">{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default QuickActions;
