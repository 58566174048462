import SecondaryMenu from "../../components/layout/SecondaryMenu";
import SectionFields from "../../components/form/molecules/SectionFields";
import DateTimeForm from "../../components/form/molecules/DateTimeForm";
import LocationFields from "../../components/form/molecules/LocationFields";
import ManagementDetailsFields from "../../components/form/molecules/ManagementDetailsFields";
import ContactFields from "../../components/form/molecules/ContactFields";
import Modal from "../../components/Modal";
import ImagePreview from "../../components/form/molecules/ImagePreview";

import ActiveInactiveSwitch from "../../components/form/molecules/ActiveInactiveSwitch";

import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifButton,
} from "@ey-xd/motif-react";

import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "../../assets/css/pages/new-donation.scss";
import Comments from "../../components/form/molecules/Comments";
import {
  formattedDate,
  imageUploadedFilter,
  isoDate,
  deleteEmptyFields,
  validateEmail,
  extractUrlBeforeQueryString,
} from "../../globals";
import { handleFileUpload } from "../../services/UploadImageBlob";
import {
  fetchCountriesData,
  fetchOrganizationsData,
  postDonationData,
} from "../../services/api";
import LabelsContext from "../../context/LabelsContext";
import InvalidModal from "../../components/InvalidModal";
import { fetchCities } from "../../services/api/countriesApi";

const Donation = () => {
  const [showModal, setShowModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [organizationId, setOrganizations] = useState([]);
  const [countryOptionsValid, setCountryOptionsValid] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [showInvalidModal, setShowInvalidModal] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [actionType, setActionType] = useState("");
  const labels = useContext(LabelsContext);
  const location = useLocation();

  const donationsData = location.state?.donationData || null;
  const [formData, setFormData] = useState({
    contactName: donationsData.contact?.name,
    contactEmail: donationsData.contact?.email,
    description: donationsData.description,
    title: donationsData.title,
    subTitle: donationsData.subTitle,
    startDate: formattedDate(donationsData.startDate),
    endDate: formattedDate(donationsData.endDate),
    organizationId: donationsData.organizationId,
    attendance: donationsData.attendance,
    image: donationsData.image,
    aimedAmount: donationsData.aimedAmount,
    minimumAmount: donationsData.minimumAmount,
    cost: donationsData.cost,
    donationType: donationsData.donationType,
    deadLineDate: formattedDate(donationsData.deadLineDate),
    fiscalYear: donationsData.fiscalYear,
    active: donationsData.active,
    donationId: donationsData.donationId,
    comments: donationsData.comments || "",
    isDraft: donationsData.isDraft,
    city: donationsData.location?.address?.city || null,
    country: donationsData.location?.address?.country || null,
    street: donationsData.location?.address?.street || "",
    locationDescription: donationsData.location?.locationDescription,
    latitude: donationsData.location?.coordinates?.latitude,
    longitude: donationsData.location?.coordinates?.longitude,
    countryValid: donationsData.countryValid,
    url: donationsData.url,
    organizationIdCode: donationsData.organizationIdCode,
  });
  const handleFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrganizationsData(false).then((data) => {
      const organizations = Array.isArray(data) ? data : [];
      setOrganizations(organizations);
    });
    fetchCountriesData().then((data) => {
      const countries = Array.isArray(data)
        ? data.map((country) => ({
            value: country.countryName,
            label: country.countryName,
            key: country.countryKey,
          }))
        : [];
      setCountryOptionsValid(countries);
    });
  }, []);
  const navigate = useNavigate();

  const handleFormSubmit = async (drafted) => {
    setisFormSubmited(true);
    if (isFormValid()) {
      setIsDisabledButton(true);
      const donationId = formData.donationId;
      const coordinates =
        !formData.latitude ||
        !formData.longitude ||
        formData.latitude === "" ||
        formData.longitude === ""
          ? null
          : {
              latitude: parseFloat(formData.latitude),
              longitude: parseFloat(formData.longitude),
            };
      const address = {
        city: formData.city,
        country: formData.country,
        street: formData.street || null,
      };
      const contact = {
        name: formData.contactName,
        email: formData.contactEmail,
      };
      let copyFormData = { ...formData };
      delete copyFormData.latitude;
      delete copyFormData.longitude;
      delete copyFormData.contactEmail;
      delete copyFormData.contactName;
      delete copyFormData.city;
      delete copyFormData.country;
      delete copyFormData.street;
      const submitData = {
        ...copyFormData,
        startDate: formData.startDate ? isoDate(formData.startDate) : null,
        endDate: formData.endDate ? isoDate(formData.endDate) : null,
        deadLineDate: formData.deadLineDate
          ? isoDate(formData.deadLineDate)
          : null,
        donationId,
        image:
          fileQueue.length > 0
            ? imageUploadedFilter(fileQueue, donationId, "donation")
            : formData.image
            ? {
                ...formData.image,
                url: extractUrlBeforeQueryString(formData.image, "url"),
              }
            : null,
        isDraft: drafted,
        video: formData.url
          ? { url: formData.url, mimeType: "image/jpeg" }
          : null,
        contact,
        location:
          formData.donationType !== "MONEY"
            ? {
                address,
                coordinates,
                description: formData.locationDescription || null,
              }
            : null,
        organizationId:
          formData.organizationId === donationsData.organizationId
            ? formData.organizationIdCode
            : formData.organizationId,
        comments: formData.comments === "<p><br></p>" ? "" : formData.comments,
      };
      delete submitData.organizationIdCode;
      handleFileUpload(fileQueue, submitData.donationId, "donation");
      await postDonationData(deleteEmptyFields(submitData));
      navigate("/donations/all-donations");
      setIsDisabledButton(false);
    } else {
      setShowInvalidModal(true);
      setIsDisabledButton(false);
    }
  };

  const isFormValid = () => {
    let requiredFields = [
      "title",
      "description",
      "image",
      "countryValid",
      "startDate",
      "endDate",
      "donationType",
      "organizationId",
      "attendance",
      "deadLineDate",
      "contactName",
      "contactEmail",
    ];
    if (formData.donationType === "MONEY") {
      const fieldsToRemove = [
        "country",
        "city",
        "street",
        "latitude",
        "longitude",
      ];

      requiredFields = requiredFields.filter(
        (field) => !fieldsToRemove.includes(field)
      );
    }
    const newMissingFields = [];
    requiredFields.forEach((field) => {
      if (!formData[field] || (field === 'description' && formData[field] === '<p><br></p>')) {
        newMissingFields.push(field);
      }
    });
    
    if (!validateEmail(formData.contactEmail)) {
      newMissingFields.push("contactEmail");
      setMissingFields(newMissingFields);
      return false;
    }
    setMissingFields(newMissingFields);
    return newMissingFields.length === 0;
  };

  const [isFormSubmited, setisFormSubmited] = useState(false);

  const openWarningModal = () => {
    setActionType("save");
    setShowModal(true);
  };

  const closeWarningModal = () => {
    setShowModal(false);
  };
  const closeInvalidModal = () => {
    setShowModal(false);
    setShowInvalidModal(false);
  };
  const handleDiscardChanges = () => {
    window.scrollTo(0, 0);
    setShowModal(false);
    navigate("/");
  };
  const handleCancelClick = () => {
    setActionType("cancel");
    setShowModal(true);
  };
  const handleNavigateHome = () => {
    navigate("/donations/all-donations");
  };
  const handleActiveChange = (isActive) => {
    setFormData({ ...formData, active: isActive });
  };
  const handleCountryChange = (countryCode) => {
    fetchCities(countryCode)
      .then((data) => {
        setCityOptions(
          data.geonames.map((city) => ({ value: city.name, label: city.name }))
        );
      })
      .catch((error) =>
        console.error(
          `Error al obtener ciudades para el país ${countryCode}:`,
          error
        )
      );
  };
  const [fileQueue, setFileQueue] = useState([]);

  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
    setIsEditing(!isEditing);
  };

  const switchLabels = {
    active: labels.console_show_donation,
    inactive: labels.console_hide_donation,
  };

  const formFieldConfig = {
    latitude: { required: true },
    longitude: { required: true },
    city: { required: true },
    country: { required: true },
    street: { required: true },
    image: { required: true },
  };

  return (
    <>
      <SecondaryMenu
        title={labels.console_edit_donations}
        onNavigate={handleNavigateHome}
        editing={isEditing}
        onEditClick={handleEditClick}
        sendFormDraft={() => handleFormSubmit(true)}
        pageType="donations"
      />
      <section className="motif-col-lg-12 section-page">
        <SectionFields
          formData={formData}
          setFormData={setFormData}
          isFormSubmited={isFormSubmited}
          setFileQueue={setFileQueue}
          isDisabled={isDisabled}
          descriptionLabel={labels.console_description}
          imageLabel={labels.console_image}
          countryOptions={countryOptionsValid}
          formFieldConfig={formFieldConfig}
          fieldVisibility={{
            category: false,
            charity: false,
            donationType: false,
            organizationId: false,
            description: true,
            title: true,
            subtitle: true,
            image: true,
            countryValid: true,
            url: true,
          }}
        />
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_dateTime}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <DateTimeForm
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              isDisabled={isDisabled}
              fieldVisibility={{
                startDate: true,
                endDate: true,
                totalVolunteerHours: false,
                startHour: false,
                endHour: false,
                timeZone: false,
              }}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_management_details}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <ManagementDetailsFields
              onFieldChange={handleFieldChange}
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              isDisabled={isDisabled}
              organizationId={organizationId}
              pageType="donations"
              fieldVisibility={{
                recurrence: false,
                attendance: true,
                aimedAmount: true,
                miniumAmount: true,
                cost: true,
                organizationId: true,
                donationType: true,
                deadLineDate: true,
                fiscalYear: true,
              }}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        {formData.donationType !== "MONEY" ? (
          <MotifAccordion alignIconRight useChevronIcon className="accordion">
            <MotifAccordionTrigger className="accordion-title">
              {labels.console_location}
            </MotifAccordionTrigger>
            <MotifAccordionContent className="accordion-box">
              <LocationFields
                formFieldConfig={formFieldConfig}
                onFieldChange={handleFieldChange}
                formData={formData}
                setFormData={setFormData}
                isFormSubmited={isFormSubmited}
                countryOptions={countryOptionsValid}
                cityOptions={cityOptions}
                onCountryChange={handleCountryChange}
                setCityOptions={setCityOptions}
                isDisabled={isDisabled}
                fieldVisibility={{
                  city: true,
                  country: true,
                  latitude: true,
                  longitude: true,
                  street: true,
                }}
              />
            </MotifAccordionContent>
          </MotifAccordion>
        ) : null}
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_pointOfContact}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <ContactFields
              onFieldChange={handleFieldChange}
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              labels={labels}
              isDisabled={isDisabled}
              fieldVisibility={{
                rolOrg: false,
                phoneNumber: false,
              }}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_comments}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <Comments
              onFieldChange={handleFieldChange}
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              isDisabled={isDisabled}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <ActiveInactiveSwitch
          labels={switchLabels}
          isActive={formData.active}
          onChange={handleActiveChange}
          className={"inactive-switch"}
        />
        <div className="activity-buttons">
          <MotifButton
            variant="secondary"
            onClick={handleCancelClick}
            className="activity-buttons-secondary"
          >
            {labels.console_cancel}
          </MotifButton>
          <MotifButton
            onClick={openWarningModal}
            className="activity-buttons-primary"
            disabled={isDisabled}
          >
            {labels.console_save}
          </MotifButton>
        </div>
      </section>
      <section className="motif-col-lg-5">
        <ImagePreview imageQueue={fileQueue} />
      </section>

      <Modal
        show={showModal}
        onClose={closeWarningModal}
        title={
          actionType === "save"
            ? labels.console_changes_saved
            : labels.console_unsavedChanges
        }
        body={
          actionType === "save"
            ? labels.console_confirm_publish_donation
            : labels.console_leavePage
        }
        primaryButtonText={
          actionType === "save"
            ? labels.console_publish
            : labels.console_keepEditing
        }
        onPrimaryButtonClick={
          actionType === "save"
            ? () => handleFormSubmit(false)
            : closeWarningModal
        }
        secondaryButtonText={
          actionType === "save"
            ? labels.console_cancel
            : labels.console_discardChanges
        }
        onSecondaryButtonClick={handleDiscardChanges}
        disabled={isDisabledButton}
      />
      <InvalidModal
        show={showInvalidModal}
        onClose={closeInvalidModal}
        title={labels.console_title_invalidForm}
        body={labels.console_body_invalidForm}
        primaryButtonText={labels.console_close}
        onPrimaryButtonClick={closeInvalidModal}
        invalidFieldsMessage={missingFields.join(", ")}
      />
    </>
  );
};

export default Donation;
