import {
  MotifFormField,
  MotifLabel,
  MotifInput,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import { useState } from "react";

const NumericInput = ({
  label,
  placeholder,
  value,
  onChange,
  required = false,
  error = false,
  errorMessage,
  isDisabled,
  validateNonNegative,
}) => {
  const [isValueNumeric, setIsValueNumeric] = useState(true);
  const handleInputChange = (event) => {
    const newValue = event.target.value;

    const numberRegex = /^-?\d*\.?\d+(?:e\d+)?$/;

    if (newValue === "" || numberRegex.test(newValue)) {
      let sanitizedValue;

      if (
        newValue === "" ||
        !validateNonNegative ||
        parseFloat(newValue) >= 0
      ) {
        sanitizedValue = newValue === "" ? "" : parseFloat(newValue);
      } else {
        sanitizedValue = 0;
      }

      onChange(sanitizedValue);
      setIsValueNumeric(true);
    } else {
      setIsValueNumeric(false);
    }
  };
  return (
    <div>
      {label && (
        <label className="input-label">
          {label} {required && <span className="input-label-required">*</span>}
        </label>
      )}
      <MotifFormField>
        <MotifLabel>{placeholder}</MotifLabel>
        <MotifInput
          type="number"
          className="input-box"
          value={value ?? ""}
          onChange={handleInputChange}
          disabled={isDisabled}
          onWheel={(e) => e.target.blur()}
        />
        {!isValueNumeric && (
          <MotifErrorMessage>
            Oops, please enter a non-negative number!
          </MotifErrorMessage>
        )}
        {error && isValueNumeric && (
          <MotifErrorMessage>{errorMessage}</MotifErrorMessage>
        )}
      </MotifFormField>
    </div>
  );
};

export default NumericInput;
