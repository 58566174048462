import { useState } from "react";
import EventInfoContext from "./EventInfoContext";

const EventInfoProvider = ({ children }) => {
  const [EventInfo, setEventInfo] = useState({
    title: "",
    subtitle: "",
    category: null,
    collective: null,
    shortDescription: "",
    description: "",
    startHour: new Date(),
    dateRange: {
      start: null,
      end: null
    },
    endDate: new Date(),
    timeZone: null,
    totalVolunteerHours: "",
    recurrence: null,
    points: "",
    fiscalYear: "",
    format: null,
    deadLineDate: new Date(),
    geographicArea: null,
    region: null,
    country: null,
    city: null,
    address: "",
    mapCoordinates: "",
    contactName: "",
    contactEmail: "",
    comments: "",
  });

  const updateEventInfo = (newState) => {
    setEventInfo(newState);
  };

  return (
    <EventInfoContext.Provider value={{ EventInfo, updateEventInfo }}>
      {children}
    </EventInfoContext.Provider>
  );
};

export { EventInfoContext, EventInfoProvider };
