import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import EmailInput from "./EmaiIput";

const UserFields = ({ formData, setFormData, isFormSubmited, labels }) => {
  const handleChange = (fieldName, value) => {
    setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
  };

  const options = [
    { value: "apple", label: "Apple" },
    { value: "orange", label: "Orange" },
    { value: "grape", label: "Grape" },
  ];

  return (
    <>
      <TextInput
        label={labels.console_name}
        placeholder={labels.console_enter_name}
        value={formData.name}
        onChange={(val) => handleChange("name", val)}
        required={true}
        error={isFormSubmited && !formData.name}
        errorMessage={labels.console_error_message_input_name}
        disabled={true}
      />
      <TextInput
        label={labels.console_surname}
        placeholder={labels.console_enter_surname}
        value={formData.surname}
        onChange={(val) => handleChange("surname", val)}
        required={true}
        error={isFormSubmited && !formData.surname}
        errorMessage={labels.console_error_message_input_surname}
        disabled={true}
      />
      <TextInput
        label={labels.console_user_rank}
        placeholder={labels.console_enter_rank}
        value={formData.jobTitle}
        onChange={(val) => handleChange("jobTitle", val)}
        required={true}
        error={isFormSubmited && !formData.jobTitle}
        errorMessage={labels.console_error_message_job_title}
        disabled={true}
      />
      <SelectInput
        label={labels.console_sl}
        value={formData.serviceLine}
        onChange={(val) => handleChange("serviceLine", val)}
        options={options}
        required={true}
        error={isFormSubmited && !formData.serviceLine}
        errorMessage={labels.console_error_message_sl}
        disabled={true}
      />
      <SelectInput
        label={labels.console_user_region}
        value={formData.country}
        onChange={(val) => handleChange("country", val)}
        options={options}
        required={true}
        error={isFormSubmited && !formData.country}
        errorMessage={labels.console_error_message_region}
        disabled={true}
      />
      <TextInput
        label={labels.console_user_office}
        placeholder={labels.console_enter_office}
        value={formData.office}
        onChange={(val) => handleChange("office", val)}
        required={false}
        error={isFormSubmited && !formData.office}
        errorMessage={labels.console_error_message_office}
        disabled={true}
      />
      <EmailInput
        label={labels.console_email}
        placeholder={labels.console_enter_email}
        value={formData.mail}
        onChange={(val) => handleChange("mail", val)}
        required={false}
        error={isFormSubmited && !formData.mail}
        errorMessage={labels.console_error_message_mail}
        disabled={true}
      />
    </>
  );
};

export default UserFields;
