import { useState, useEffect, useContext, useMemo } from "react";

import PropTypes from "prop-types";

import {
  MotifTable,
  MotifTableHeaderRenderer,
  MotifIcon,
  MotifChip,
  MotifProgressBar,
  MotifContentSwitcher,
  MotifButton,
  MotifSelect,
  MotifOption,
  MotifLabel,
  MotifFormField,
} from "@ey-xd/motif-react";

import {
  toggleIcStar24px,
  toggleIcStarBorder24px,
  toggleIcStarHalf24px,
} from "@ey-xd/motif-react/assets/icons";

import LabelsContext from "../../context/LabelsContext";
import { getActionReport } from "../../services/api/apiReports";
import { fetchOrganizationsData, fetchActions } from "../../services/api.js";
import SkeletonTable from "../../components/layout/SkeletonTable";

import "../../assets/css/pages/projects-reports.scss";

const GiveyrsReports = () => {
  const [isLoading, setLoading] = useState(true);
  const [actionReport, setActionReport] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [actions, setActions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const handleActiveClass = (index) =>
    activeIndex === index ? "motif-active" : "";

  const [activeIndexProject, setActiveIndexProject] = useState(1);
  const handleActiveProjectClass = (index) =>
    activeIndexProject === index ? "motif-active" : "";

  const [entity, setEntity] = useState("");
  const labels = useContext(LabelsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    const loadData = async () => {
      try {
        const actionReport = await getActionReport();
        setActionReport(actionReport || {});
        const organizations = await fetchOrganizationsData(false);
        setOrganizations(organizations || {});
        const actions = await fetchActions(false);
        setActions(actions || {});
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const getOrganizationName = (id) => {
    return organizations.find((org) => org.id === id)?.name;
  };

  function transformData(data) {
    var allActions = [];

    if (
      data?.closedProjects?.actions &&
      Array.isArray(data?.closedProjects?.actions)
    )
      allActions.push(
        ...data.closedProjects.actions.map((action) => ({
          ...action,
          status: "closed",
        }))
      );

    if (
      data?.ongoingProjects?.actions &&
      Array.isArray(data?.ongoingProjects?.actions)
    )
      allActions.push(
        ...data.ongoingProjects.actions.map((action) => ({
          ...action,
          status: "ongoing",
        }))
      );

    if (
      data?.openProjects?.actions &&
      Array.isArray(data?.openProjects?.actions)
    )
      allActions.push(
        ...data.openProjects.actions.map((action) => ({
          ...action,
          status: "open",
        }))
      );

    if (
      data?.vacantProjects?.actions &&
      Array.isArray(data?.vacantProjects?.actions)
    )
      allActions.push(
        ...data.vacantProjects.actions.map((action) => ({
          ...action,
          status: "vacant",
        }))
      );

    if (
      data?.fullProjects?.actions &&
      Array.isArray(data?.fullProjects?.actions)
    )
      allActions.push(
        ...data.fullProjects.actions.map((action) => ({
          ...action,
          status: "full",
        }))
      );

    if (
      data?.waitlistedProjects?.actions &&
      Array.isArray(data?.waitlistedProjects?.actions)
    )
      allActions.push(
        ...data.waitlistedProjects.actions.map((action) => ({
          ...action,
          status: "waitlisted",
        }))
      );

    allActions = allActions.map((action) => {
      const evaluation =
        data?.projectEvaluations?.find((e) => e.actionId === action.actionId) ||
        {};
      const individual =
        data?.individualProjectReports?.find(
          (e) => e.actionId === action.actionId
        ) || {};
      const inhouse =
        organizations
          ?.filter((org) => org.id === action.organizationId)
          .find((org) => org.inhouse) || false;
      const directCost =
        actions?.find((act) => act.actionId === action.actionId)?.directCost ||
        0;
      const donationToEntity =
        actions?.find((act) => act.actionId === action.actionId)
          ?.donationToEntity || 0;
      const totalGivers =
        data?.totalIndividualsPerEntity?.[action.organizationId] || 0;
      return {
        actionId: action.actionId,
        title: action.title,
        minParticipants: action.minParticipants,
        maxParticipants: action.maxParticipants,
        averageGeneralAssessment: evaluation.averageGeneralAssessment || 0,
        averageFirmEngagement: evaluation.averageFirmEngagement || 0,
        status: action.status,
        vacant: individual.vacants,
        waitlisted: individual.waitlisted,
        organization: action.organizationId,
        category: action.categoryId,
        hours: action.totalVolunteerHours,
        inhouse: inhouse,
        directCost: directCost,
        donationToEntity: donationToEntity,
        individualGivers: individual.individuals || 0,
        totalGivers: totalGivers,
        costValue: action.cost,
      };
    });

    return allActions;
  }

  const newActionReportData = transformData(actionReport);

  // CELLS RENDERER

  const DotCellRenderer = ({ value }) => (
    <div style={{ alignSelf: "center", display: "flex", alignItems: "center" }}>
      <MotifChip
        variant={
          value === "open"
            ? "success"
            : value === "ongoing"
            ? "warning"
            : "error"
        }
        type="dot"
      >
        {value}
      </MotifChip>
    </div>
  );
  DotCellRenderer.propTypes = {
    value: PropTypes.string.isRequired,
  };

  const ProgressCellRenderer = ({ value }) => (
    <div style={{ alignSelf: "center", display: "flex", alignItems: "center" }}>
      <MotifProgressBar value={value} showLabel></MotifProgressBar>
    </div>
  );
  ProgressCellRenderer.propTypes = {
    value: PropTypes.number.isRequired,
  };

  function renderStarsIcons(value, type) {
    let starsIcons = [];
    const emptyStar = toggleIcStarBorder24px;
    const fullStar = toggleIcStar24px;
    const numberEmptyStars = type === "empty" ? 5 - value : 0;
    const times =
      type === "empty" ? parseInt(numberEmptyStars) : parseInt(value);
    for (let i = 0; i < times; i++) {
      starsIcons.push(
        <MotifIcon
          key={i}
          src={type === "full" ? fullStar : emptyStar}
          style={{ color: type === "full" ? "#ffbf1e" : "#D8D8D8" }}
        ></MotifIcon>
      );
    }
    return starsIcons;
  }

  const StarsCellRenderer = ({ value }) => (
    <div style={{ alignSelf: "center", display: "flex", alignItems: "center" }}>
      {value !== 0 && renderStarsIcons(value, "full")}

      {!Number.isInteger(value) && (
        <MotifIcon
          src={toggleIcStarHalf24px}
          style={{ color: "#ffbf1e" }}
        ></MotifIcon>
      )}

      {renderStarsIcons(value, "empty")}
    </div>
  );
  StarsCellRenderer.propTypes = {
    value: PropTypes.number.isRequired,
  };

  const defaultColDef = useMemo(() => {
    return {
      initialWidth: 200,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  // TABLE STATUS
  const columnDefsStatus = [
    {
      headerName: "Project",
      field: "title",
      cellRenderer: "",
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: "dotCellRenderer",
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: labels.console_general_assessment,
      field: "averageGeneralAssessment",
      cellRenderer: "starsCellRenderer",
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: labels.console_firm_engagement,
      field: "averageFirmEngagement",
      cellRenderer: "starsCellRenderer",
      flex: 1,
      suppressMovable: true,
    },
  ];

  const filteredDataStatus = useMemo(() => {
    let data = [];
    if (activeIndex === 2) {
      data = newActionReportData.filter((value) => value.status === "open");
    } else if (activeIndex === 3) {
      data = newActionReportData.filter((value) => value.status === "ongoing");
    } else if (activeIndex === 4) {
      data = newActionReportData.filter((value) => value.status === "closed");
    } else {
      data = newActionReportData.filter(
        (value) =>
          value.status === "open" ||
          value.status === "ongoing" ||
          value.status === "closed"
      );
    }
    return data;
  }, [activeIndex, newActionReportData]);

  // TABLE PROJECTS
  const columnDefsProjects = [
    {
      headerName: "Project",
      field: "title",
      cellRenderer: "",
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: labels.console_vacancies,
      field: "vacant",
      cellRenderer: "",
      flex: 1,
      hide: activeIndexProject === 2 || activeIndexProject === 4,
      suppressMovable: true,
    },
    {
      headerName: labels.console_waiting_list,
      field: "waitlisted",
      cellRenderer: "",
      flex: 1,
      hide: activeIndexProject === 2 || activeIndexProject === 3,
      suppressMovable: true,
    },
  ];

  const filteredDataProjects = useMemo(() => {
    let data = [];
    if (activeIndexProject === 2) {
      data = newActionReportData.filter((value) => value.status === "full");
    } else if (activeIndexProject === 3) {
      data = newActionReportData.filter((value) => value.status === "vacant");
    } else if (activeIndexProject === 4) {
      data = newActionReportData.filter(
        (value) => value.status === "waitlisted"
      );
    } else {
      data = newActionReportData.filter(
        (value) =>
          value.status === "full" ||
          value.status === "vacant" ||
          value.status === "waitlisted"
      );
    }
    return data;
  }, [activeIndexProject, newActionReportData]);

  // TABLE ENTITIES
  const columnDefsEntities = [
    {
      headerName: "Project",
      field: "title",
      cellRenderer: "",
      flex: 1,
      suppressMovable: true,
      headerTooltip: "Project",
    },
    {
      headerName: labels.console_individual_givers,
      field: "individualGivers",
      cellRenderer: "",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_individual_givers,
    },
    {
      headerName: labels.console_total_givers,
      field: "totalGivers",
      cellRenderer: "",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_total_givers,
    },
    {
      headerName: labels.console_patrono,
      field: "inhouse",
      cellRendererFramework: ({ value }) =>
        value ? labels.console_yes : labels.console_no,
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_patrono,
    },
    {
      headerName: labels.console_strategic_axis,
      field: "category",
      cellRenderer: "",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_strategic_axis,
    },
    {
      headerName: labels.console_direct_cost,
      field: "directCost",
      cellRenderer: "",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_direct_cost,
    },
    {
      headerName: labels.console_donation_entity,
      field: "donationToEntity",
      cellRenderer: "",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_donation_entity,
    },
    {
      headerName: labels.console_cost,
      field: "costValue",
      cellRenderer: "",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_cost,
    },
    {
      headerName: labels.console_hours,
      field: "hours",
      cellRenderer: "",
      flex: 1,
      suppressMovable: true,
      headerTooltip: labels.console_hours,
    },
  ];

  const filteredDataEntities = useMemo(() => {
    const status = ["open", "ongoing", "closed"];
    let data = [];
    if (entity === "") {
      data = [
        ...new Set(
          newActionReportData.filter((value) => status.includes(value.status))
        ),
      ];
    } else {
      data = [
        ...new Set(
          newActionReportData.filter(
            (value) =>
              value.organization === entity && status.includes(value.status)
          )
        ),
      ];
    }
    return data;
  }, [entity, newActionReportData]);

  return (
    <>
      <section className="projects-reports">
        <header className="header">
          <h1 className="motif-h1">{labels?.console_projects_reports}</h1>
        </header>
        <section className="status-section">
          <div>
            <h3 className="motif-h3">{labels.console_status_project}</h3>
            <MotifContentSwitcher>
              <MotifButton
                onClick={() => setActiveIndex(1)}
                className={handleActiveClass(1)}
                variant="secondary"
                aria-label="purpose 1"
                aria-pressed={activeIndex === 1}
              >
                {labels.console_all}
              </MotifButton>
              <MotifButton
                onClick={() => setActiveIndex(2)}
                className={handleActiveClass(2)}
                variant="secondary"
                aria-label="purpose 2"
                aria-pressed={activeIndex === 2}
                disabled={actionReport.countOpen === 0}
              >
                {labels.console_open} ({actionReport.countOpen})
              </MotifButton>
              <MotifButton
                onClick={() => setActiveIndex(3)}
                className={handleActiveClass(3)}
                variant="secondary"
                aria-label="purpose 3"
                aria-pressed={activeIndex === 3}
                disabled={actionReport.countOngoing === 0}
              >
                {labels.console_ongoing} ({actionReport.countOngoing})
              </MotifButton>
              <MotifButton
                onClick={() => setActiveIndex(4)}
                className={handleActiveClass(4)}
                variant="secondary"
                aria-label="purpose 4"
                aria-pressed={activeIndex === 4}
                disabled={actionReport.countClosed === 0}
              >
                {labels.console_closed} ({actionReport.countClosed})
              </MotifButton>
            </MotifContentSwitcher>
          </div>
          {isLoading ? (
            <SkeletonTable />
          ) : (
            <div>
              <MotifTable
                columnDefs={columnDefsStatus}
                rowData={filteredDataStatus}
                components={{
                  agColumnHeader: MotifTableHeaderRenderer,
                  dotCellRenderer: DotCellRenderer,
                  starsCellRenderer: StarsCellRenderer,
                }}
                suppressRowClickSelection
                suppressCellFocus
                pagination
                className="reports-table"
                paginationPageSize="10"
                suppressNoRowsOverlay={true}
              />
              {filteredDataStatus.length === 0 && (
                <div
                  className="no-data"
                  style={{ textAlign: "center", marginTop: "100px" }}
                >
                  {labels.console_no_data}
                </div>
              )}
            </div>
          )}
        </section>
        <section className="info-section">
          <div>
            <h3 className="motif-h3">{labels.console_info_project}</h3>
            <MotifContentSwitcher>
              <MotifButton
                onClick={() => setActiveIndexProject(1)}
                className={handleActiveProjectClass(1)}
                variant="secondary"
                aria-label="purpose 1"
                aria-pressed={activeIndex === 1}
              >
                {labels.console_all}
              </MotifButton>
              <MotifButton
                onClick={() => setActiveIndexProject(2)}
                className={handleActiveProjectClass(2)}
                variant="secondary"
                aria-label="purpose 2"
                aria-pressed={activeIndex === 2}
                disabled={actionReport.countFull === 0}
              >
                {labels.console_completed} ({actionReport.countFull})
              </MotifButton>
              <MotifButton
                onClick={() => setActiveIndexProject(3)}
                className={handleActiveProjectClass(3)}
                variant="secondary"
                aria-label="purpose 3"
                aria-pressed={activeIndex === 3}
                disabled={actionReport.countVacant === 0}
              >
                {labels.console_vacancies} ({actionReport.countVacant})
              </MotifButton>
              <MotifButton
                onClick={() => setActiveIndexProject(4)}
                className={handleActiveProjectClass(4)}
                variant="secondary"
                aria-label="purpose 4"
                aria-pressed={activeIndex === 4}
                disabled={actionReport.countWaitlisted === 0}
              >
                {labels.console_completed_waintinglist} (
                {actionReport.countWaitlisted})
              </MotifButton>
            </MotifContentSwitcher>
          </div>
          {isLoading ? (
            <SkeletonTable />
          ) : (
            <div>
              <MotifTable
                columnDefs={columnDefsProjects}
                className="reports-table"
                rowData={filteredDataProjects}
                components={{
                  agColumnHeader: MotifTableHeaderRenderer,
                  dotCellRenderer: DotCellRenderer,
                }}
                suppressRowClickSelection
                suppressCellFocus
                pagination
                paginationPageSize="10"
                suppressNoRowsOverlay={true}
              />
              {filteredDataProjects.length === 0 && (
                <div
                  className="no-data"
                  style={{ textAlign: "center", marginTop: "100px" }}
                >
                  {labels.console_no_data}
                </div>
              )}
            </div>
          )}
        </section>
        <section className="projects-section">
          <h3 className="motif-h3">Proyectos</h3>
          <div className="projects-select">
            <MotifFormField className="select">
              <MotifLabel id="select-label-search-people">Entity:</MotifLabel>
              <MotifSelect
                value={entity}
                onChange={(selectedEntity) => setEntity(selectedEntity)}
                filter
                ariaLabelledBy="select-label-search-people"
              >
                {[
                  ...new Set(
                    newActionReportData.map((item) => item.organization)
                  ),
                ].map((entityName, key) => (
                  <MotifOption key={key} value={entityName}>
                    {getOrganizationName(entityName)}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
            <div className="projects-total">
              Total: <span>{filteredDataEntities.length}</span>
            </div>
          </div>
          {isLoading ? (
            <SkeletonTable />
          ) : (
            <div>
              <MotifTable
                columnDefs={columnDefsEntities}
                rowData={filteredDataEntities}
                defaultColDef={defaultColDef}
                components={{
                  agColumnHeader: MotifTableHeaderRenderer,
                }}
                suppressRowClickSelection
                suppressCellFocus
                pagination
                className="reports-table"
                paginationPageSize="10"
                suppressNoRowsOverlay={true}
              />
              {filteredDataEntities.length === 0 && (
                <div
                  className="no-data"
                  style={{ textAlign: "center", marginTop: "100px" }}
                >
                  {labels.console_no_data}
                </div>
              )}
            </div>
          )}
        </section>
      </section>
    </>
  );
};

export default GiveyrsReports;
