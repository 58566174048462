import "../assets/css/components/UserGridCard.scss";

import UserInfoCard from "./UserInfoCard";

const UserGridCard = ({ rowData }) => {
  return (
    <div className="card-grid">
      {rowData.map((user, i) => (
        <UserInfoCard {...user} key={i} />
      ))}
    </div>
  );
};

export default UserGridCard;
