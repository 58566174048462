import "../../assets/css/components/secondary-menu.scss";

import { useContext, useState } from "react";
import { MotifButton, MotifIcon } from "@ey-xd/motif-react";
import { Link } from "react-router-dom";
import Modal from "../Modal";

import { navigationIcChevronLeft24px } from "@ey-xd/motif-react/assets/icons";
import LabelsContext from "../../context/LabelsContext";

const SecondaryMenu = ({
  title,
  onNavigate = () => {},
  editing,
  onEditClick,
  sendFormDraft,
  pageType,
  hideEditButton,
}) => {
  const labels = useContext(LabelsContext);

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  let viewCheckboxLabel;
  let bodyDraftLabel;
  switch (pageType) {
    case "activities":
      viewCheckboxLabel = labels.console_back_to_activities;
      bodyDraftLabel = labels.console_confirm_draft_action;
      break;
    case "organizations":
      viewCheckboxLabel = labels.console_back_to_organizations;
      bodyDraftLabel = labels.console_confirm_draft_organization;
      break;
    case "donations":
      viewCheckboxLabel = labels.console_back_to_donations;
      bodyDraftLabel = labels.console_confirm_draft_donation;
      break;
    case "landings":
      viewCheckboxLabel = labels.console_back_to_landings;
      bodyDraftLabel = labels.console_confirm_draft_landing;
      break;
    case "volunteers":
      viewCheckboxLabel = labels.console_back_to_volunteers;
      break;
    case "probonos":
      viewCheckboxLabel = labels.console_back_to_probonos;
      bodyDraftLabel = labels.console_confirm_draft_probono;
      break;
    case "questions":
      viewCheckboxLabel = labels.console_back_to_questions;
      break;
    default:
      viewCheckboxLabel = labels.console_view_my_activities;
      bodyDraftLabel = labels.console_confirm_draft_action;
  }
  if (!labels) {
    return null;
  }
  const openModal = (config) => {
    setModalContent(config);
    setShowModal(true);
  };
  const closeModal = () => {
    document.body.style.overflow = "auto";
    setShowModal(false);
  };

  const handleButtonClick = () => {
    if (!editing) {
      onEditClick();
      return;
    } else {
      openModal({
        title: labels.console_changes_saved_draft,
        body: bodyDraftLabel,
        primaryButtonText: labels.console_accept,
        primaryButtonVariant: "primary",
        onPrimaryButtonClick: () => {
          sendFormDraft();
          closeModal();
        },
      });
    }
  };
  return (
    <>
      <header className="new-activity-header motif-col-lg-12">
        <div className="left-side">
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault();
              if (editing) {
                openModal({
                  title: labels.console_unsavedChanges,
                  body: labels.console_leavePage,
                  primaryButtonText: labels.console_keepEditing,
                  secondaryButtonText: labels.console_discardChanges,
                  primaryButtonVariant: "primary",
                  secondaryButtonVariant: "secondary",
                  onPrimaryButtonClick: () => {
                    closeModal();
                  },
                  onSecondaryButtonClick: () => {
                    closeModal();
                    onNavigate("/");
                  },
                });
              } else {
                onNavigate("/");
              }
            }}
          >
            <div className="backIcon">
              <MotifIcon src={navigationIcChevronLeft24px} />
            </div>
            <p className="backText">{viewCheckboxLabel}</p>
          </Link>
          <span className="motif-h1 title-page">{title}</span>
        </div>

        {!hideEditButton && (
          <MotifButton
            type="button"
            variant="secondary"
            onClick={handleButtonClick}
          >
            {editing ? labels.console_saveDraft : labels.console_edit}
          </MotifButton>
        )}

        <Modal show={showModal} onClose={closeModal} {...modalContent} />
      </header>
    </>
  );
};

export default SecondaryMenu;
