import { BlobServiceClient } from "@azure/storage-blob";

export const handleFileUpload = async (selectedFile, eventId, page) => {
  const connectionString =
    process.env.REACT_APP_AZURE_STORAGE_CONNECTION_STRING;
  const blobServiceClient =
    BlobServiceClient.fromConnectionString(connectionString);

  const containerName = "giveyrs";
  const containerClient = blobServiceClient.getContainerClient(containerName);
  if (selectedFile) {
    try {
      selectedFile.forEach(async (file) => {
        const blobName = `${page}/${eventId}/${file.uploaded}/` + file.name;
        const blockBlobNameDelete = `${page}/${eventId}/${file.uploaded}/`
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        const blobOptions = {
          blobHTTPHeaders: { blobContentType: "image/*" },
        };
        const data = await fetch(file.previewUrl).then((response) =>
          response.blob()
        );
        const blobList = containerClient.listBlobsFlat({ prefix: blockBlobNameDelete });
        for await (const blob of blobList) {
          const blobClient = containerClient.getBlockBlobClient(blob.name);
          await blobClient.deleteIfExists();
        }
        await blockBlobClient.uploadData(data, blobOptions);
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  } else {
    console.log("Please select a file to upload.");
  }
};
