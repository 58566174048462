import TextInput from "./TextInput";

const UserProfileFields = ({
  formData,
  setFormData,
  isFormSubmited,
  labels,
}) => {
  const handleChange = (fieldName, value) => {
    setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
  };
  return (
    <>
      <TextInput
        label={labels.console_hours_dedicated}
        placeholder="Number of hours"
        value={formData.hoursDedicated}
        onChange={(val) => handleChange("hoursDedicated", val)}
        required={false}
        error={isFormSubmited && !formData.hoursDedicated}
        errorMessage={labels.console_error_hours_dedicated}
        disabled={true}
      />
      <TextInput
        label={labels.console_impacted_lives}
        placeholder={labels.console_impacted_lives}
        value={formData.impactLives}
        onChange={(val) => handleChange("impactLives", val)}
        required={false}
        error={isFormSubmited && !formData.impactLives}
        errorMessage={labels.console_error_impacted_lives}
        disabled={true}
      />
      <TextInput
        label={labels.console_giveyrs_points}
        placeholder={labels.console_giveyrs_points}
        value={formData.giveyrsPoints}
        onChange={(val) => handleChange("giveyrsPoints", val)}
        required={false}
        error={isFormSubmited && !formData.giveyrsPoints}
        errorMessage={labels.console_error_points}
        disabled={true}
      />
    </>
  );
};

export default UserProfileFields;
