import TextInput from "./TextInput";
import NumericInput from "./NumericInput";
import RichTextEditorField from "./RichTextEditorField";

import {
  MotifFormField,
  MotifLabel,
  MotifErrorMessage,
  MotifDatePicker,
} from "@ey-xd/motif-react";
import { useState } from "react";
import { useEffect, useContext } from "react";
import LabelsContext from "../../../context/LabelsContext";
import SelectInput from "./SelectInput";

const ProbonosField = ({
  formData,
  setFormData,
  isFormSubmited,
  onNumericErrors,
  isDisabled,
  countryOptions,
  fieldVisibility = {
    title: true,
    subTitle: true,
    description: true,
    investmentType: true,
    moneyInvested: true,
    engagement: true,
    fiscalYear: true,
    startDate: true,
    endDate: true,
    team: true,
  },
}) => {
  const labels = useContext(LabelsContext);
  const [numericErrors, setNumericErrors] = useState({
    fiscalYear: false,
    moneyInvested: false,
  });

  useEffect(() => {
    onNumericErrors(numericErrors);
  }, [numericErrors, onNumericErrors]);

  const handleChange = (fieldName, value) => {
    if (["fiscalYear", "moneyInvested"].includes(fieldName)) {
      const isNumeric = !isNaN(value) && value.trim() !== "";
      setNumericErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: !isNumeric,
      }));
    }
    setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
  };

  return (
    <>
      {fieldVisibility.title && (
        <TextInput
          label={labels.console_table_title}
          placeholder={isDisabled ? false : `${labels.console_table_title}`}
          value={formData.title}
          onChange={(val) => handleChange("title", val)}
          error={!formData.title && isFormSubmited}
          errorMessage="Oops, please enter a title!"
          required={true}
          disabled={isDisabled}
        />
      )}
      {fieldVisibility.subTitle && (
        <TextInput
          label={labels.console_subtitle}
          placeholder={isDisabled ? false : `${labels.console_subtitle}`}
          value={formData.subTitle}
          onChange={(val) => handleChange("subTitle", val)}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.description && (
        <RichTextEditorField
          label={labels.console_description}
          value={formData.description}
          onChange={(val) => handleChange("description", val)}
          required={true}
          error={!formData.description && isFormSubmited}
          errorMessage="Oops, description is required!"
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.team && (
        <TextInput
          label={labels.console_team_ey}
          placeholder={isDisabled ? false : `${labels.console_team_ey}`}
          value={formData.team}
          onChange={(val) => handleChange("team", val)}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.startDate && (
        <MotifFormField className="form-field">
          <MotifLabel className="input-label">
            {labels.console_start_date}{" "}
            <span className="input-label-required">*</span>
          </MotifLabel>
          <MotifDatePicker
            value={formData.startDate}
            onChange={(value) => handleChange("startDate", value)}
            format="dd-MM-yyyy"
            yearPlaceholder="YYYY"
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            minDate={
              formData.startDate &&
              new Date(formData.startDate.setHours(0, 0, 0, 0)) <
                new Date(new Date().setHours(0, 0, 0, 0))
                ? formData.startDate
                : new Date()
            }
            disabled={isDisabled}
          />
          {isFormSubmited && !formData.startDate && (
            <MotifErrorMessage>
              {labels.console_error_pick_date}
            </MotifErrorMessage>
          )}
        </MotifFormField>
      )}

      {fieldVisibility.endDate && (
        <MotifFormField className="form-field">
          <MotifLabel className="input-label">
            {labels.console_end_date}{" "}
            <span className="input-label-required">*</span>
          </MotifLabel>
          <MotifDatePicker
            value={formData.endDate}
            onChange={(value) => handleChange("endDate", value)}
            format="dd-MM-yyyy"
            yearPlaceholder="YYYY"
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            todayMark={true}
            minDate={formData.startDate}
            disabled={isDisabled}
          />
          {isFormSubmited && !formData.endDate && (
            <MotifErrorMessage>
              {labels.console_error_pick_end_date}
            </MotifErrorMessage>
          )}
        </MotifFormField>
      )}

      {fieldVisibility.investmentType && (
        <TextInput
          label={labels.console_investment_type}
          value={formData.investmentType}
          onChange={(val) => handleChange("investmentType", val)}
          required={true}
          error={!formData.investmentType && isFormSubmited}
          errorMessage="Oops, this field is required!"
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.moneyInvested && (
        <NumericInput
          label={labels.console_money_invested}
          placeholder={isDisabled ? false : `${labels.console_money_invested}`}
          value={formData.moneyInvested}
          onChange={(val) => handleChange("moneyInvested", val)}
          error={numericErrors.moneyInvested}
          errorMessage="Oops, please enter a number!"
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.engagement && (
        <TextInput
          label={labels.console_engagement}
          placeholder={isDisabled ? false : `${labels.console_engagement}`}
          value={formData.engagement}
          onChange={(val) => handleChange("engagement", val)}
          // error={!formData.engagement && isFormSubmited}
          // errorMessage="Oops, this field is required!"
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.fiscalYear && (
        <NumericInput
          label={labels.console_fiscal_year}
          placeholder={isDisabled ? false : `${labels.console_fiscal_year}`}
          value={formData.fiscalYear}
          onChange={(val) => handleChange("fiscalYear", val)}
          error={numericErrors.fiscalYear}
          errorMessage="Oops, please enter a number!"
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}
      {fieldVisibility.country && (
        <SelectInput
          label="Country"
          value={formData.country}
          onChange={(val) => handleChange("country", val)}
          options={countryOptions}
          required
          error={!formData.country && isFormSubmited}
          errorMessage="Oops, please select a country!"
          disabled={isDisabled}
          filter
        />
      )}
    </>
  );
};

export default ProbonosField;
