import {
  MotifFormField,
  MotifErrorMessage,
  MotifRadioButton,
  MotifRadioButtonGroup,
} from "@ey-xd/motif-react";

import "../../../assets/css/components/RadioButton.scss";
import { useContext } from "react";
import LabelsContext from "../../../context/LabelsContext";

const RadioButton = ({
  label,
  isDisabled,
  required = false,
  error = false,
  errorMessage,
  value,
  onChange,
}) => {
  const labels = useContext(LabelsContext);
  return (
    <div>
      {label && (
        <label className="input-label">
          {label} {required && <span className="input-label-required">*</span>}
        </label>
      )}
      <MotifFormField>
        <MotifRadioButtonGroup>
          <MotifRadioButton
            name="polar"
            id="polarChoice1"
            value={true}
            checked={value}
            onChange={(event) => onChange(event.target.value === "true")}
            disabled={isDisabled}
          >
            {labels.console_yes}
          </MotifRadioButton>
          <MotifRadioButton
            name="polar"
            id="polarChoice2"
            value={false}
            checked={!value}
            onChange={(event) => onChange(event.target.value === "true")}
            disabled={isDisabled}
          >
            {labels.console_no}
          </MotifRadioButton>
        </MotifRadioButtonGroup>
        {error && <MotifErrorMessage>{errorMessage}</MotifErrorMessage>}
      </MotifFormField>
    </div>
  );
};

export default RadioButton;
