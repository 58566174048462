import { useContext } from "react";
import LabelsContext from "../context/LabelsContext";
import QuickActionCard from "./QuickActionCard";

import "../assets/css/components/QuickActions.scss";

const QuickActions = () => {
  const labels = useContext(LabelsContext);

  return (
    <div className="quick-actions">
      <h2 className=" motif-h2">{labels.console_home_actions}</h2>
      <div className="quick-actions-container">
        <QuickActionCard
          title={labels.console_create_action}
          path="/actions/new-action"
        />
        <QuickActionCard
          title={labels.console_create_donations}
          path="/donations/new-donation"
        />
      </div>
    </div>
  );
};

export default QuickActions;
