import React from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "../../assets/css/components/PieChart.scss";
import { MotifProgressBar } from "@ey-xd/motif-react";

const DonutSL = ({ donutHoursData, isLoading, donutEconomicData }) => {
  if (isLoading || (!donutHoursData && !donutEconomicData)) {
    return <MotifProgressBar isIndeterminate circle hideLabel />;
  }

  const total =
    donutHoursData?.datasets[0]?.data.reduce((acc, value) => acc + value, 0) ||
    donutEconomicData?.datasets[0]?.data.reduce((acc, value) => acc + value, 0);

  if (!total) return null;

  const dataToUse = donutHoursData || donutEconomicData;

  const percentages = dataToUse.datasets[0].data.map((value) => {
    if (value !== 0) {
      return (value / total) * 100;
    }
    return undefined;
  });

  const updatedData = {
    ...dataToUse,
    datasets: [
      {
        ...dataToUse.datasets[0],
        data: percentages,
      },
    ],
  };
  const options = {
    layout: {
      padding: {
        top: 20,
        right: 50,
        bottom: 15,
        left: 40,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            let label = dataToUse.labels[tooltipItem.dataIndex] || "";
            if (label) {
              label += ": ";
            }
            const value = dataToUse.datasets[0].data[tooltipItem.dataIndex];
            const formattedValue = new Intl.NumberFormat("es-ES").format(value);
            return label + formattedValue;
          },
        },
      },
      datalabels: {
        align: "end",
        anchor: "end",
        offset: 1,
        color: "#555",
        font: {
          weight: "bold",
        },
        formatter: (value) => (value ? `${Math.round(value)}%` : null),
      },
    },
  };

  return (
    <div className="chart-container margin-top">
      <Doughnut
        className="donut-chart-sl"
        data={updatedData}
        options={options}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
};

export default DonutSL;
