import React from 'react';

import { MotifIcon } from '@ey-xd/motif-react';
import PieChartProjectsComponent from "./PieChartProjectsComponent";


import "../../assets/css/components/MediumReportBox.scss";

const MediumBox = ({ icon, title, totalProjectsData, projects, isLoading }) => {
  
  return (
    <div className="medium-box">
      <div className="top-content">
        <div className="icon-box">
          <span>
            <MotifIcon src={icon} />
          </span>
        </div>
        <div>
          <div className="title-box">
            <span>{title}</span>
          </div>
        </div>
      </div>
      <div className="middle-content-chart">
        <PieChartProjectsComponent isLoading={isLoading} totalProjectsData={totalProjectsData} projects={projects} />
      </div>
      <div className="bottom-content">
        <div className="right-side">
          <div className="legend-section">

          </div>
        </div>
      </div>
    </div>
  );
};

export default MediumBox;