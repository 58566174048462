import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
// import ChartDataLabels from 'chartjs-plugin-datalabels';

const VerticalVar = ({ verticalBarActiveUserData, verticalBarGiveyrsData }) => {
  const dataToUse = verticalBarActiveUserData || verticalBarGiveyrsData;

  const options = {
    aspectRatio: 1,
    plugins: {
      title: {
        display: false,
        text: "Text text",
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        barThickness: 10,
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };
  return (
    <div className="">
      <Bar data={dataToUse} height={null} width={null} options={options} />
    </div>
  );
};

export default VerticalVar;
