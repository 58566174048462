import {
  MotifFormField,
  MotifLabel,
  MotifSelect,
  MotifOption,
  MotifTimeInput,
  MotifErrorMessage,
  MotifDatePicker,
} from "@ey-xd/motif-react";

import "../../../assets/css/components/DateTimeForm.scss";
import NumericInput from "./NumericInput";
import { useContext } from "react";
import LabelsContext from "../../../context/LabelsContext";

const DateTime = ({
  formData,
  setFormData,
  isFormSubmited,
  fieldVisibility = {},
  isDisabled,
  isEditing,
}) => {
  const handleChange = (fieldName, value) => {
    if (fieldName === "startDate" && formData.endDate < value) {
      setFormData((prevData) => ({ ...prevData, endDate: value }));
    }
    setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
  };
  const labels = useContext(LabelsContext);

  const dateValid =
    Boolean(formData.startDate) &&
    Boolean(formData.endDate) &&
    formData.endDate >= formData.startDate;
  const oldDateSelected =
    Boolean(formData.startDate) &&
    Boolean(formData.endDate) &&
    new Date(formData.startDate.setHours(0, 0, 0, 0)) <
      new Date(new Date().setHours(0, 0, 0, 0));
  const timeValid =
    Boolean(formData.startHour) &&
    Boolean(formData.endHour) &&
    formData.endHour > formData.startHour;

  return (
    <>
      {fieldVisibility.startDate && (
        <MotifFormField className="form-field">
          <MotifLabel className="input-label">
            {labels.console_start_date}:{" "}
            <span className="input-label-required">*</span>
          </MotifLabel>
          <MotifDatePicker
            id="startDate"
            value={formData.startDate}
            onChange={(value) => handleChange("startDate", value)}
            format="dd-MM-yyyy"
            yearPlaceholder="YYYY"
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            minDate={
              formData.startDate &&
              new Date(formData.startDate.setHours(0, 0, 0, 0)) <
                new Date(new Date().setHours(0, 0, 0, 0))
                ? formData.startDate
                : new Date()
            }
            disabled={
              isDisabled ||
              (new Date(new Date().setHours(0, 0, 0, 0)) >
                formData?.deadLineDate &&
                isEditing)
            }
          />
          {isFormSubmited && !formData.startDate && (
            <MotifErrorMessage>
              Oops, please pick a start date!
            </MotifErrorMessage>
          )}
          {isFormSubmited && formData.startDate && !dateValid && (
            <MotifErrorMessage>
              Oops, please pick a start date less or equal than end date!
            </MotifErrorMessage>
          )}
          {isFormSubmited && oldDateSelected && (
            <MotifErrorMessage>
              Oops, please pick a start date equal or greater than today!
            </MotifErrorMessage>
          )}
        </MotifFormField>
      )}

      {fieldVisibility.endDate && (
        <MotifFormField className="form-field">
          <MotifLabel className="input-label">
            {labels.console_end_date}:{" "}
            <span className="input-label-required">*</span>
          </MotifLabel>
          <MotifDatePicker
            id="endDate"
            value={formData.endDate}
            onChange={(value) => handleChange("endDate", value)}
            format="dd-MM-yyyy"
            yearPlaceholder="YYYY"
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            todayMark={true}
            minDate={formData.startDate}
            disabled={
              isDisabled ||
              (new Date(new Date().setHours(0, 0, 0, 0)) >
                formData?.deadLineDate &&
                isEditing)
            }
            isOpen={!dateValid}
          />
          {isFormSubmited && !formData.endDate && (
            <MotifErrorMessage>
              Oops, please pick an end date!
            </MotifErrorMessage>
          )}
          {isFormSubmited && formData.endDate && !dateValid && (
            <MotifErrorMessage>
              Oops, please pick an end date greater or equal than start date!
            </MotifErrorMessage>
          )}
        </MotifFormField>
      )}

      {fieldVisibility.timeZone && (
        <MotifFormField className="form-field">
          <MotifLabel className="input-label" id="select-label">
            {labels.console_time_zone}
          </MotifLabel>
          <MotifSelect
            value={formData.timeZone}
            onChange={(value) => handleChange("timeZone", value)}
            ariaLabelledBy="select-label"
            className="input-dropdown"
            disabled={isDisabled}
          >
            <MotifOption value="UTC-5" className="dropdown">
              UTC-5
            </MotifOption>
            <MotifOption value="UTC-4" className="dropdown">
              UTC-4
            </MotifOption>
            <MotifOption value="UTC-3" className="dropdown">
              UTC-3
            </MotifOption>
          </MotifSelect>
        </MotifFormField>
      )}

      {fieldVisibility.startHour && (
        <MotifFormField className="form-field">
          <MotifLabel className="input-label">
            {labels.console_start_hour}:{" "}
            <span className="input-label-required">*</span>
          </MotifLabel>
          <MotifTimeInput
            value={formData.startHour}
            onChange={(newValue) => handleChange("startHour", newValue)}
            disabled={isDisabled}
          />
          {isFormSubmited && !timeValid && (
            <MotifErrorMessage>
              Oops, please pick a start hour less than end hour!
            </MotifErrorMessage>
          )}
        </MotifFormField>
      )}

      {fieldVisibility.endHour && (
        <MotifFormField className="form-field">
          <MotifLabel className="input-label">
            {labels.console_end_hour}:{" "}
            <span className="input-label-required">*</span>
          </MotifLabel>
          <MotifTimeInput
            value={formData.endHour}
            onChange={(newValue) => handleChange("endHour", newValue)}
            disabled={isDisabled}
          />
          {isFormSubmited && !timeValid && (
            <MotifErrorMessage>
              Oops, please pick an end hour greater than start hour!
            </MotifErrorMessage>
          )}
        </MotifFormField>
      )}

      {fieldVisibility.totalVolunteerHours && (
        <NumericInput
          label={labels.console_total_volunteers_hours}
          placeholder="Enter hours"
          value={formData.totalVolunteerHours}
          onChange={(val) => handleChange("totalVolunteerHours", val)}
          error={isFormSubmited && !formData.totalVolunteerHours}
          errorMessage="Oops, please enter hours!"
          required={true}
          disabled={isDisabled}
          validateNonNegative={true}
        />
      )}
    </>
  );
};

export default DateTime;
