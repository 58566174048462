const USERNAME_GEONAMES = 'giveyrs'

export const fetchCountries = () => {
  const url = `https://secure.geonames.org/countryInfoJSON?username=${USERNAME_GEONAMES}`;
  return fetch(url)
    .then(response => response.json())
    .catch(error => console.error('Error al obtener los países:', error));
};

export const fetchCities = (countryCode) => {
  const url = `https://secure.geonames.org/searchJSON?country=${countryCode}&username=${USERNAME_GEONAMES}`;
  return fetch(url)
    .then(response => response.json())
    .catch(error => console.error(`Error al obtener ciudades para el país ${countryCode}:`, error));
};