import { useState, useEffect, useContext, useCallback } from "react";
import {
  MotifTable,
  MotifTableHeaderRenderer,
  MotifTableCellRenderer,
} from "@ey-xd/motif-react";

import SearchForTables from "../../components/layout/SearchForTables.jsx";
import SkeletonTable from '../../components/layout/SkeletonTable';
import LabelsContext from "../../context/LabelsContext.js";
import { fetchProbonosData } from "../../services/api.js";
import { status } from "../../globals.js";
import { useNavigate } from "react-router-dom";

import "../../assets/css/pages/all-activities.scss";

const AllProBonos = () => {
  const [probonos, setProbonos] = useState([]);
  const [filteredProbonosData, setFilteredProbonosData] = useState([]);
  const [pageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setLoading] = useState(true)
  const labels = useContext(LabelsContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.style.overflow = "auto";
    const getProbonosData = async () => {
      try {
        const data = await fetchProbonosData(true);
        setProbonos(data);
      } catch (error) {
        console.error("Error fetching the probonos data", error);
      } finally {
        setLoading(false)
      }
    };

    getProbonosData();
  }, []);

  const columnDefs = [
    {
      headerName: labels.title,
      field: "title",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.table_stage,
      field: "status",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: labels.contactName,
      field: "contactName",
      sortable: true,
      filter: true,
      flex: 1,
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      const resolvedData = await Promise.all(
        probonos.map((probono) => ({
          title: probono.title,
          status: status(probono),
          contactName: probono.contact.name,
          subTitle: probono.subTitle || "",
          description: probono.description,
          startDate: probono.startDate,
          endDate: probono.endDate,
          investmentType: probono.investmentType,
          moneyInvested: probono.moneyInvested,
          engagement: probono.engagement || "",
          contactEmail: probono.contact.email,
          fiscalYear: probono.fiscalYear,
          country: probono.country,
          team: probono.team || "",
          comments: probono.comments || "",
          probonusId: probono.probonusId,
        }))
      );
      const sortedActionsData = [...resolvedData].sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0;
      });
      const filteredProbonosData = sortedActionsData.filter((probono) =>
        probono.title.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredProbonosData(filteredProbonosData);
    };
    fetchData();
  }, [probonos, searchValue]);
  const onRowSelected = useCallback(
    (probono) => {
      navigate("/probono/edit-probono/", {
        state: { probonoData: probono.node.data },
      });
      return;
    },
    [navigate]
  );

  return (
    <section className="all-activities-page motif-col-lg-12">
      <div className="activities-info">
        <span className="title motif-h2">{labels.console_probonos_title}</span>
        <div>
          <span className="number-total-activities">{probonos.length}</span>
          <span className="total-activities">
            {labels.console_total_probonos}
          </span>
        </div>
      </div>
      <SearchForTables
        onSearchChange={(value) => setSearchValue(value)}
        pageType="probonos"
        showAddButton={true}
        showSwitchButton={false}
      />
      {isLoading ? <SkeletonTable /> :
        <section className="activities-table">
          <MotifTable
            defaultColDef={{
              headerCheckboxSelection: false,
              checkboxSelection: false,
            }}
            firstColumnBorderRight
            columnDefs={columnDefs}
            rowData={filteredProbonosData}
            suppressCellFocus
            paginationPageSize={pageSize}
            pagination
            onRowSelected={onRowSelected}
            rowSelection={"single"}
            components={{
              agColumnHeader: MotifTableHeaderRenderer,
              motifTableCellRenderer: MotifTableCellRenderer,
            }}
            suppressNoRowsOverlay={true}
          />
        </section>
      }
    </section>
  );
};

export default AllProBonos;
