import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "../../assets/css/components/PieChart.scss";
import { MotifProgressBar } from '@ey-xd/motif-react';


const PieChartProjectsComponent = ({ totalProjectsData, projects, isLoading }) => {


  if (isLoading) {
    return <MotifProgressBar isIndeterminate circle hideLabel />;
  }

  const options = {
    layout: {
      padding: {
        top: 20,
        right: 50,
        bottom: 20,
        left: 40,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const labels = totalProjectsData.labels;
            const label = labels[tooltipItem.dataIndex];
            const projectTypes = ['actions', 'donations', 'organizations', 'probonus', 'landings'];
            const projectType = projectTypes[tooltipItem.dataIndex];
            const value = projects[projectType];

            return `${label}: ${value} (Total)`;
          }
        }
      },
      datalabels: {
        align: 'end',
        anchor: 'end',
        offset: 1,
        color: '#555',
        font: {
          weight: 'bold'
        },
        formatter: (value) => `${value}%`
      }
    },
  };
  

  return (
    <div className="chart-container margin-top">
      <Doughnut className='donut-chart' data={totalProjectsData} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default PieChartProjectsComponent;
