import "../assets/css/components/LastActivity.scss";

import { MotifIcon, MotifButton } from "@ey-xd/motif-react";
import { actionIcPermContactCalendar24px } from "@ey-xd/motif-react/assets/icons";
import { communicationIcLocationOn24px } from "@ey-xd/motif-react/assets/icons";
import { hardwareIcLaptop24px } from "@ey-xd/motif-react/assets/icons";
import { actionIcWatchLater24px } from "@ey-xd/motif-react/assets/icons";

import LabelsContext from "../context/LabelsContext";

import { useContext } from "react";

const SkeletonActivity = () => {
  const labels = useContext(LabelsContext);

  if (!labels) {
    return null;
  }

  return (
    <div className="last-activity">
      <div className="top">
        <img
          src={
            "https://www.abi.org.uk/globalassets/images/content/subjects/voluntary-organisations/voluntaryorganisations620x300.jpg"
          }
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              "https://www.abi.org.uk/globalassets/images/content/subjects/voluntary-organisations/voluntaryorganisations620x300.jpg";
          }}
          // src="https://www.abi.org.uk/globalassets/images/content/subjects/voluntary-organisations/voluntaryorganisations620x300.jpg?width=1600&height=1100&mode=max"
        />
      </div>
      <div className="content">
        <div className="title motif-h3">{}</div>
        <div className="activity-info">
          <ul>
            <li className="event">
              <MotifIcon className="icon" src={actionIcWatchLater24px} />
              <p className="">{labels.console_event_duration}:</p>
              <p>{}h</p>
            </li>
            <li className="date">
              <MotifIcon
                className="icon"
                src={actionIcPermContactCalendar24px}
              />
              <p></p>
            </li>
            <li className="location">
              <MotifIcon className="icon" src={communicationIcLocationOn24px} />
              <p>{}</p>
            </li>
            <li className="roles">
              <MotifIcon className="icon" src={hardwareIcLaptop24px} />
              <p>{labels.console_virtual_roles}</p>
            </li>
          </ul>
        </div>
        <p className="description">{} </p>
        <MotifButton
          size="medium"
          type="button"
          className="details-button"
          onClick={() => {}}
        >
          {labels.console_home_details}
        </MotifButton>
      </div>
    </div>
  );
};

export default SkeletonActivity;
