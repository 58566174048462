import "../../assets/css/components/table-search.scss";

import { useState, useContext } from "react";
import { MotifFormField, MotifSearch } from "@ey-xd/motif-react";

import LabelsContext from "../../context/LabelsContext";

const SearchForUsers = ({ pageType, onSearchChange }) => {
  const labels = useContext(LabelsContext);

  const [searchInputValue, setSearchInputValue] = useState("");

  if (!labels) {
    return null;
  }

  return (
    <div className="table-search-component">
      <div className="table-search-interactions">
        <MotifFormField className="motif-form-search">
          <MotifSearch
            value={searchInputValue}
            id="states-search"
            onChange={(event) => {
              setSearchInputValue(event.target.value);
              onSearchChange(event.target.value);
            }}
            onSelect={(val) => setSearchInputValue(val)}
            aria-label="Search"
            placeholder={labels.console_search_user}
            className="motif-form-search__input"
          />
        </MotifFormField>
      </div>
    </div>
  );
};

export default SearchForUsers;
