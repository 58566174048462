import TextInput from "./TextInput";
import NumericInput from "./NumericInput";

import { useContext, useState } from "react";
import LabelsContext from "../../../context/LabelsContext";

const ContactField = ({
  formData,
  setFormData,
  isFormSubmited,
  isDisabled,
  fieldVisibility = {
    rolOrg: true,
    phoneNumber: true,
  },
}) => {
  const [isEmailValid, setIsEmailValid] = useState(true);

  const labels = useContext(LabelsContext);

  const handleChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: Array.isArray(prevData[fieldName])
        ? [...prevData[fieldName], value]
        : value,
    }));
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setIsEmailValid(re.test(String(email).toLowerCase()));
  };

  return (
    <>
      <TextInput
        label={labels.console_contactName}
        placeholder={isDisabled ? false : `${labels.console_contactName}`}
        value={formData.contactName}
        onChange={(val) => handleChange("contactName", val)}
        error={isFormSubmited && !formData.contactName}
        errorMessage="Oops, please enter a contact name!"
        required={true}
        disabled={isDisabled}
      />

      <TextInput
        label={labels.console_contactEmail}
        placeholder={isDisabled ? false : `${labels.console_contactEmail}`}
        value={formData.contactEmail}
        onChange={(val) => {
          handleChange("contactEmail", val);
          validateEmail(val);
        }}
        error={isFormSubmited && (!formData.contactEmail || !isEmailValid)}
        errorMessage={
          isEmailValid
            ? "Oops, please enter an email!"
            : "Oops, please enter a valid email!"
        }
        required={true}
        disabled={isDisabled}
      />

      {fieldVisibility.rolOrg && (
        <TextInput
          label="Contact organization role"
          placeholder="Enter organization role"
          value={formData.rolOrg}
          onChange={(val) => handleChange("rolOrg", val)}
          error={isFormSubmited && !formData.rolOrg}
          errorMessage="Oops, please enter a organization role!"
          required={true}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.phoneNumber && (
        <NumericInput
          label={labels.console_phone_number}
          placeholder="Enter phone number"
          value={formData.phoneNumber}
          onChange={(val) => handleChange("phoneNumber", val)}
          error={!formData.phoneNumber && isFormSubmited}
          errorMessage="This field is required."
          required={true}
          isDisabled={isDisabled}
          validateNonNegative={true}
        />
      )}
    </>
  );
};

export default ContactField;
