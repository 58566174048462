import {
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifButton,
  MotifIcon,
} from "@ey-xd/motif-react";
import { alertIcWarning24px } from "@ey-xd/motif-react/assets/icons";

import "../assets/css/components/Modal.scss";

const InvalidModal = ({
  show,
  onClose,
  title,
  body,
  primaryButtonText = "Close",
  onPrimaryButtonClick,
  invalidFieldsMessage,
}) => {
  const closeButtonProps = {
    title: "Close modal",
    "aria-label": "Close modal",
  };
  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );
  return (
    <>
      <MotifModal show={show} onClose={onClose} size="medium">
        <MotifModalHeader closeButtonProps={closeButtonProps}>
          <HeaderWrapper>
            <MotifIcon
              style={{ color: "#FF9831", lineHeight: 1, marginRight: "10px" }}
              src={alertIcWarning24px}
            />
            {title}
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>{body}{"; "}{invalidFieldsMessage}</MotifModalBody>
        <MotifModalFooter className="custom-footer">
          <MotifButton
            size="medium"
            type="button"
            onClick={onPrimaryButtonClick || onClose}
          >
            {primaryButtonText}
          </MotifButton>
        </MotifModalFooter>
      </MotifModal>
    </>
  );
};

export default InvalidModal;
