import { customAlphabet } from "nanoid";
import { parse, format } from "date-fns";
import {
  fetchOrganizationIdData,
  fetchVolunteersActionList,
} from "./services/api";
import { useMsal } from "@azure/msal-react";
import Cookies from "js-cookie";

const imageUrlEnv = process.env.REACT_APP_IMAGE_URL;
export const imageUploadedFilter = (imageQueue, fileId, page) => {
  const foundImage = imageQueue.find((image) => image.uploaded === "image");
  return (
    (foundImage && {
      url: `${imageUrlEnv}/giveyrs/${page}/${fileId}/${foundImage.uploaded}/${foundImage.name}`,
      mimeType: "image/jpeg",
    }) ??
    null
  );
};
export const cleanUrl = (url) => {
  return url.split("?")[0];
};
export const extractUrlBeforeQueryString = (urlImage, key) => {
  if (!urlImage || !urlImage[key]) return "";

  const url = urlImage[key];
  const endIndex = url.indexOf("?");

  if (endIndex !== -1) {
    return url.substring(0, endIndex);
  } else {
    return url;
  }
};
export const galleryUploadedFilter = (imageQueue, fileId) => {
  const resultUrls = [];
  imageQueue.forEach((image) => {
    if (image.uploaded === "gallery") {
      const imageUrl = {
        url: `${imageUrlEnv}/giveyrs/landing/${fileId}/${image.uploaded}/${image.name}`,
        mimeType: "image/jpeg",
      };
      resultUrls.push(imageUrl);
    } else {
      resultUrls.push({
        ...image,
        url: cleanUrl(image.url),
      });
    }
  });
  return resultUrls;
};

export const nanoid = customAlphabet(
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
  22
);

export const REQUIRED_FIELDS = {
  activities: [
    "title",
    "image",
    "organizationId",
    "categoryId",
    "description",
    "startDate",
    "endDate",
    "startHour",
    "endHour",
    "totalVolunteerHours",
    "deadLineDate",
    "minParticipants",
    "maxParticipants",
    "attendance",
    "geographicArea",
    "region",
    "country",
    "city",
    "latitude",
    "longitude",
    "street",
    "contactName",
    "contactEmail",
  ],
};

export const formattedDate = (date) => {
  const dateFormat = parse(date, "dd/MM/yyyy HH:mm:ss", new Date());
  return dateFormat;
};

export const formatHours = (hour) => {
  let date = new Date();
  date.setHours(parseInt(hour.slice(0, 2)));
  date.setMinutes(parseInt(hour.slice(3, 5)));
  return new Date(
    convertTime(date.getTime() - date.getTimezoneOffset() * 60000)
  );
};

export const formattedHourISO = (time) => {
  return time.length === 5 ? formatHours(time) : new Date(convertTime(time));
};

export const isoDate = (date, timeString, startDate, deadLineDate) => {
  if (!timeString) {
    return new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
  } else {
    const [hours, minutes] = timeString.split(":").map(Number);
    const newDate = date;
    if (startDate === deadLineDate) {
      newDate.setHours(hours, minutes);
    } else {
      newDate.setHours(24, 59);
    }

    return newDate.toISOString();
  }
};

export const convertTime = (inputTime) => {
  // Parse the input time
  const formattedTimeString = format(
    new Date(inputTime),
    "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx '('zzz')'"
  );
  return formattedTimeString;
};

export const organizationToId = async (organizationId) => {
  const organizationName = await fetchOrganizationIdData(organizationId);
  return organizationName;
};

export const getUsersJoined = async (action) => {
  const users = await fetchVolunteersActionList(action);
  return users.length;
};

export const status = (action) => {
  const currentDate = new Date();
  const [hoursStart, minutesStart] = action.startHour
    ? action.startHour.split(":").map(Number)
    : [0, 0];
  const [hoursEnd, minutesEnd] = action.endHour
    ? action.endHour.split(":").map(Number)
    : [24, 59];

  let startDate = formattedDate(action.startDate);
  let endDate = formattedDate(action.endDate);
  startDate.setHours(hoursStart, minutesStart);
  endDate.setHours(hoursEnd, minutesEnd);
  if (action.isDraft) {
    return "Draft";
  } else if (currentDate < startDate) {
    return "To start";
  } else if (currentDate >= startDate && currentDate <= endDate) {
    return "On going";
  } else {
    return "Complete";
  }
};
export const statusCreation = (action) => {
  if (action.isDraft) {
    return "Draft";
  } else {
    return "Complete";
  }
};

export const useGetToken = async () => {
  const { instance } = useMsal();
  const userTest = instance.getAllAccounts()[0];
  const userRequest = await instance.acquireTokenSilent({
    scopes: ["User.Read"],
    account: userTest,
  });
  return userRequest.accessToken;
};

export const deleteEmptyFields = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (
      typeof obj[key] === "object" &&
      !Array.isArray(obj[key]) &&
      obj[key] !== null
    ) {
      deleteEmptyFields(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    } else if (obj[key] == null || obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
};

export const getToken = () => {
  return new Promise((resolve, reject) => {
    const token = Cookies.get("token");
    if (token) {
      resolve(token);
    } else {
      reject(new Error("Token not found in cookies"));
    }
  });
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
