import { MotifFormField, MotifToggleSwitch } from "@ey-xd/motif-react";
import "../../../assets/css/components/ActiveInactiveSwitch.scss";

const ActiveInActiveSwitch = ({ isActive, onChange, className, labels }) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <div className={className}>
      <MotifFormField>
        <MotifToggleSwitch
          onLabel={labels.active}
          offLabel={labels.inactive}
          checked={isActive}
          onChange={handleChange}
          id="activeInactiveToggle"
        />
      </MotifFormField>
    </div>
  );
};

export default ActiveInActiveSwitch;
